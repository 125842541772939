// eslint-disable-next-line import/no-extraneous-dependencies
import metadataToInputMetadata from "shared-utils/metadataToInputMetadata";

export default {
    methods: {
        iframeId(deliverable, iframePrefix = "") {
            return `${iframePrefix}iframe-${deliverable.idHash}`;
        },

        previewValues(deliverable) {
            const computedOverwrites = deliverable.computedValues.map(editableValue => ({
                newValue: editableValue.value,
                name: editableValue.name,
                type: editableValue.type,
                layer: editableValue.layer,
                settings: editableValue.settings,
                htmlTag: "manifest"
            }));

            const metadata = [
                {
                    fieldName: "language",
                    value: deliverable.language
                },
                ...metadataToInputMetadata(deliverable.metadata || [])
            ];

            return JSON.stringify({
                computedOverwrites,
                metadata,
                masterTemplate: {
                    _id: deliverable.masterTemplate._id,
                    persistentKey: deliverable.masterTemplate.persistentKey,
                    width: deliverable.masterTemplate.width,
                    height: deliverable.masterTemplate.height
                },
                previewUrl: deliverable.masterTemplate.preview,
                campaignId: this.campaignId
            });
        },

        previewBannerAdapter(deliverable) {
            return {
                ...deliverable.masterTemplate,
                id: this.iframeId(deliverable),
                _id: deliverable.idHash
            };
        }
    }
};
