<template>
    <div class="dark-scrollbar intelligent-delivery__header">
        <section-nav />

        <div class="intelligent-delivery__header-action-wrapper left">
            <Button
                v-if="showPlatformAccountForm"
                class="intelligent-delivery__header-action-btn"
                data-testid="editor-header__add-language-button"
                @click="addPlatformAccount"
            >
                Add Platform Account
            </Button>
            <Button
                v-if="showManageSignals"
                class="intelligent-delivery__header-action-btn"
                data-testid="editor-header__manage-signals-button"
                @click="manageSignals"
            >
                Manage Signals
            </Button>
            <labelled-switch v-model="showDeletedAccounts" type="info">
                <div class="notification-label">Show deleted accounts and rules</div>
            </labelled-switch>
        </div>
        <div v-if="showSaveChanges" class="intelligent-delivery__header-action-wrapper right">
            <div class="save-changes">
                <Button
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__cancel-button"
                    @click="openCancelChangesModal"
                >
                    Reset
                </Button>
                <Button
                    class="intelligent-delivery__header-action-btn"
                    data-testid="editor-header__save-button"
                    :disabled="isLoading"
                    @click="openSaveChangesModal"
                >
                    Save
                </Button>
                <Button
                    class="intelligent-delivery__header-action-btn--primary"
                    type="primary"
                    data-testid="editor-header__create-button"
                    :disabled="isLoading"
                    @click="openPublishChangesModal"
                >
                    Publish
                </Button>
            </div>
        </div>
        <alert-modal
            v-model="cancelChangesModal"
            header="Reset changes"
            title="Are you sure?"
            content="Unsaved changes will be lost. This cannot be undone."
            ok-text="Reset"
            :on-ok="cancelChanges"
        />
        <alert-modal
            v-model="saveChangesModal"
            header="Save changes"
            title="Are you sure?"
            content="You will save the changes made during the current session."
            ok-text="Save"
            :warning="true"
            :on-ok="saveChanges"
        />
        <alert-modal
            v-model="publishChangesModal"
            header="Publish changes"
            title="Are you sure?"
            content="Your unsaved changes will be saved and published."
            ok-text="Publish"
            :warning="true"
            :on-ok="publishChanges"
        />
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import SectionNav from "@/components/SectionNav";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";

export default {
    name: "DashboardHeader",
    components: { AlertModal, SectionNav, LabelledSwitch },

    props: {
        showPlatformAccountForm: {
            type: Boolean,
            default: true
        },
        showManageSignals: {
            type: Boolean,
            default: false
        },
        showSaveChanges: {
            type: Boolean,
            default: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            cancelChangesModal: false,
            saveChangesModal: false,
            publishChangesModal: false,
            showDeletedAccounts: false
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        }
    },

    watch: {
        showDeletedAccounts() {
            this.$emit("showDeletedAccounts", this.showDeletedAccounts);
        }
    },

    methods: {
        openCancelChangesModal() {
            this.cancelChangesModal = true;
        },

        openPublishChangesModal() {
            this.publishChangesModal = true;
        },

        openSaveChangesModal() {
            this.saveChangesModal = true;
        },

        cancelChanges() {
            this.$emit("cancelChanges", true);
        },

        saveChanges() {
            this.$emit("saveChanges", true);
        },

        publishChanges() {
            this.$emit("saveChanges");
        },

        addPlatformAccount() {
            this.$emit("showPlatformAccountFormModal");
        },

        manageSignals() {
            this.$emit("showManageSignalsModal");
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.intelligent-delivery__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: -20px 0 0 0;
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 73px;
    overflow: visible;
    padding: 0 20px;
    background: #fff;
    border-bottom: 1px solid #c1c7cd;

    &-action-wrapper {
        flex: 1;
        display: flex;

        &.right {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            white-space: nowrap;
        }
    }

    &-action-select {
        max-width: 175px;
    }

    .notification-label {
        width: auto;
        margin-left: 20px;
        padding-right: 10px;
    }

    .save-changes {
        button {
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
}
</style>
