export const NotificationStatus = {
    Completed: "completed",
    Failed: "failed",
    InProgress: "in-progress",
    Pending: "pending"
};

export const NotificationTypes = {
    BackgroundUpdate: "background-update",
    Download: "download",
    DownloadJob: "download-job",
    GenerateReport: "generate-report",
    Publish: "publish",
    Unpublish: "unpublish"
};
