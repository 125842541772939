<template>
    <FormItem ref="accountType" label="Account type" prop="accountType">
        <Select v-model="updatedAccountType" class="rule-edit-modal__platform" @input="onChangeAccountType">
            <Option key="FACEBOOK" value="FACEBOOK">
                <icon-facebook />
                Facebook
            </Option>
        </Select>
    </FormItem>
</template>
<script>
import iconFacebook from "@/assets/intelligent-delivery/facebook_social media_social_icon.svg";
import { AccountTypesList } from "@/enums/activate";

export default {
    name: "AccountTypeSelect",
    components: {
        iconFacebook
    },
    props: {
        accountType: {
            type: String
        }
    },
    data() {
        return {
            AccountTypesList,
            updatedAccountType: this.accountType
        };
    },
    methods: {
        onChangeAccountType() {
            this.$emit("changeAccountType", this.updatedAccountType);
        }
    }
};
</script>
