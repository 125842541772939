import Vue from "vue";
import { deepClone } from "@/utils";
import { BannerState } from "@/enums/banners";

const storeNamespace = "banners";

export const BannersAction = {
    Destroy: `${storeNamespace}/destroy`,
    Init: `${storeNamespace}/init`,
    SetCurrentTime: `${storeNamespace}/setCurrentTime`,
    SetState: `${storeNamespace}/setState`,
    Update: `${storeNamespace}/update`
};

export const BannersMutation = {
    Destroy: "destroy",
    Init: "init",
    SetCurrentTime: "setCurrentTime",
    SetDuration: "setDuration",
    SetHasTimeline: "setHasTimeline",
    SetState: "setState"
};

export const BannersGetters = {
    isLoading: `${storeNamespace}/isLoading`,
    isPlaying: `${storeNamespace}/isPlaying`,
    isPlaybackComplete: `${storeNamespace}/isPlaybackComplete`
};

const instanceState = {
    currentTime: 0,
    duration: 0,
    hasTimeline: false,
    state: BannerState.Loading
};

export default {
    namespaced: true,
    state: {
        instanceStates: {
            /* [instanceName: string]: InstanceState */
        }
    },

    getters: {
        isLoading: state => instance => state.instanceStates[instance].state === BannerState.Loading,
        isPlaying: state => instance => state.instanceStates[instance].state === BannerState.Play,
        isPlaybackComplete: state => instance =>
            state.instanceStates[instance].currentTime === state.instanceStates[instance].duration &&
            state.instanceStates[instance].duration !== 0
    },

    mutations: {
        destroy(state, instance) {
            Vue.delete(state.instanceStates, instance);
        },

        init(state, instance) {
            if (!(instance in state.instanceStates)) {
                Vue.set(state.instanceStates, instance, deepClone(instanceState));
            }
        },

        setCurrentTime(state, { instance, currentTime }) {
            Vue.set(state.instanceStates, instance, {
                ...state.instanceStates[instance],
                currentTime
            });
        },

        setDuration(state, { instance, duration }) {
            Vue.set(state.instanceStates, instance, {
                ...state.instanceStates[instance],
                duration
            });
        },

        setHasTimeline(state, { instance, hasTimeline }) {
            Vue.set(state.instanceStates, instance, {
                ...state.instanceStates[instance],
                hasTimeline
            });
        },

        setState(state, data) {
            Vue.set(state.instanceStates, data.instance, {
                ...state.instanceStates[data.instance],
                state: data.state
            });
        }
    },

    actions: {
        destroy({ commit }, instance) {
            commit(BannersMutation.Destroy, instance);
        },

        init({ commit }, data) {
            commit(BannersMutation.Init, data);
        },

        setCurrentTime({ commit }, data) {
            commit(BannersMutation.SetCurrentTime, data);
        },

        setState({ commit }, data) {
            commit(BannersMutation.SetState, data);
        },

        update({ commit }, update) {
            if (!("instance" in update)) {
                throw new Error("Instance name required");
            }

            if ("currentTime" in update) {
                commit(BannersMutation.SetCurrentTime, update);
            }

            if ("duration" in update) {
                commit(BannersMutation.SetDuration, update);
            }

            if ("hasTemplate" in update) {
                commit(BannersMutation.SetHasTimeline, update);
            }

            if ("state" in update) {
                commit(BannersMutation.SetState, update);
            }
        }
    }
};
