<template>
    <div v-observe-visibility="{ callback: visibilityChanged, once: true }" class="lazy-banner">
        <banner
            v-if="isVisible"
            :id="id"
            class="lazy-banner__banner"
            :debug="debug"
            :width="width"
            :height="height"
            :preview-values="previewValues"
            :scaling-factor="scalingFactor"
            :is-psd="isPsd"
            :is-resizable="isResizable"
            @BannerIFrameLoaded="onBannerLoaded"
        ></banner>
        <div v-if="!isVisible" class="lazy-banner__placeholder" @click="onClick">
            <slot name="placeholder"></slot>
        </div>
    </div>
</template>

<script>
import { ObserveVisibility } from "vue-observe-visibility";
import Banner from "@/components/Banner";

export default {
    name: "LazyLoadBanner",
    components: { Banner },
    directives: { ObserveVisibility },
    props: {
        width: {
            required: true,
            type: [String, Number]
        },
        height: {
            required: true,
            type: [String, Number]
        },
        id: {
            required: true,
            type: String
        },
        previewValues: {
            required: true,
            type: String
        },

        loadInView: {
            type: Boolean,
            default: true
        },
        debug: {
            type: Boolean,
            default: false
        },
        scalingFactor: {
            type: Number
        },
        isPsd: {
            default: false,
            type: Boolean
        },
        isResizable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isVisible: false
        };
    },

    methods: {
        onBannerLoaded(evt) {
            this.$emit("BannerIFrameLoaded", evt);
        },

        visibilityChanged(isVisible) {
            if (!this.loadInView) {
                return;
            }

            if (isVisible) {
                this.isVisible = true;
            }
        },

        onClick() {
            this.isVisible = true;
        }
    }
};
</script>
