<template>
    <div class="template-upload">
        <Upload
            ref="upload"
            :action="uploadAction"
            :data="formData"
            :show-upload-list="false"
            :on-success="onTemplateUploadSuccess"
            :on-error="onTemplateUploadError"
            :on-progress="showUploadPercentage"
            :multiple="uploadType === UploadType.Create"
            :before-upload="onBeforeUpload"
            :accept="acceptedFileMimes"
        >
            <div class="template-upload__button" @click="updatePostData">
                <Button ref="remote" class="hidden">Add template</Button>
                <slot>
                    <Button type="primary">Add template</Button>
                </slot>
            </div>
        </Upload>
        <hox-modal v-if="showUpdateTemplatePrompt" @close="updateTemplatePromptCancel">
            <template #header>Are you sure?</template>
            <template>
                <div class="template-upload-modal">
                    <p class="small-margin">This template is used in the following campaigns:</p>

                    <ul>
                        <li v-for="campaign in templateUsedInCampaigns" :key="campaign._id">
                            {{ campaign.name }}
                        </li>
                    </ul>

                    <p class="small-margin">{{ updateTemplateModalAdsText }}</p>

                    <hox-alert v-if="hasGroupConflicts" margin-bottom="none" type="danger" size="small">
                        <template #title>The template you are uploading contains different groups.</template>
                        <template #content>
                            <p class="template-upload-modal__alert-text">
                                Any deliverables which are affected by these changes will no longer be accessible. You
                                will lose access to any comments, annotations and status set on these deliverables, and
                                you should rebuild any feeds that have been published.
                            </p>
                            <template v-if="editableGroupConflicts.added.length">
                                <span class="template-upload-modal__label">
                                    This template contains these new groups:
                                </span>
                                <ul>
                                    <li
                                        v-for="added in editableGroupConflicts.added"
                                        :key="added"
                                        class="capitalize-text"
                                    >
                                        {{ added }}
                                    </li>
                                </ul>
                            </template>
                            <div v-if="editableGroupConflicts.removed.length" class="template-upload-modal__removed">
                                <span class="template-upload-modal__label">
                                    This template has removed following groups:
                                </span>
                                <ul>
                                    <li
                                        v-for="removed in editableGroupConflicts.removed"
                                        :key="removed"
                                        class="capitalize-text"
                                    >
                                        {{ removed }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </hox-alert>

                    <hox-alert
                        v-if="templateFamily.length && templateFamilyMaster"
                        margin-bottom="none"
                        type="danger"
                        size="small"
                    >
                        <template #title>Template family detected</template>
                        <template #content>
                            <p v-if="familyUpdateInProgress" class="template-upload-modal__alert-text">
                                Updating family members:
                            </p>
                            <p v-else class="template-upload-modal__alert-text">
                                This template belongs to the "{{ templateFamilyMaster.name }}" family. The Master size
                                will be updated. Select which additional sizes to update below.
                            </p>

                            <ul v-if="familyUpdateInProgress">
                                <div v-for="tplId in familyMembersToUpdate" :key="tplId">
                                    <family-update-progress-row
                                        :is-master="!templateFamilyById[tplId].parentId"
                                        :is-loading="familyMembersUpdated[tplId] === undefined"
                                        :has-failed="familyMembersUpdated[tplId] === false"
                                    >
                                        {{ templateFamilyById[tplId].width }}x{{ templateFamilyById[tplId].height }}
                                    </family-update-progress-row>
                                </div>
                            </ul>
                            <div v-else>
                                <Checkbox :value="allMembersSelected" @on-change="toggleSelection">All</Checkbox>
                                <CheckboxGroup v-model="familyMembersToUpdate">
                                    <Checkbox :label="templateFamilyMaster._id" :disabled="forceSelectFamilyMaster">
                                        {{ templateFamilyMaster.width }}x{{ templateFamilyMaster.height }} (Master)
                                    </Checkbox>
                                    <div v-for="tpl in templateFamily" :key="tpl._id">
                                        <Checkbox v-if="tpl.parentId" :label="tpl._id">
                                            {{ tpl.width }}x{{ tpl.height }}
                                        </Checkbox>
                                    </div>
                                </CheckboxGroup>
                            </div>
                        </template>
                    </hox-alert>
                </div>
            </template>
            <template #footer>
                <Button
                    v-if="familyUpdateInProgress"
                    class="template-upload-modal__button"
                    @click="showUpdateTemplatePrompt = false"
                >
                    Close
                </Button>
                <template v-else>
                    <Button class="template-upload-modal__button" @click="updateTemplatePromptCancel">
                        Cancel
                    </Button>
                    <Button class="template-upload-modal__button" type="primary" @click="updateTemplatePromptOk">
                        <template v-if="familyMembersToUpdate.length > 1">Update family</template>
                        <template v-else>Go ahead</template>
                    </Button>
                </template>
            </template>
        </hox-modal>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import bus from "@/bus";
import { uploadConfigExpired } from "@/utils";
import MasterTemplates from "@/services/MasterTemplates";
import clientMasterTemplatesUploadConfigQuery from "@/apollo/queries/v2/ClientMasterTemplatesUploadConfig.gql";
import { MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import FamilyUpdateProgressRow from "@/components/Campaign/MasterTemplates/FamilyUpdateProgressRow";

const UploadType = {
    Create: "create",
    Update: "update"
};

const PSDMimeTypes = ["image/vnd.adobe.photoshop", "application/x-photoshop"];

export default {
    name: "MasterTemplateUploadButton",
    components: { FamilyUpdateProgressRow },
    props: {
        masterTemplateId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            editableGroupConflicts: { added: [], removed: [] },
            familyMembersToUpdate: [],
            familyMembersUpdated: {},
            familyUpdateInProgress: false,
            templateFamily: [],
            templateFamilyById: {},
            templateUsedPromptResolve: () => {},
            templateUsedToProduceAds: {},
            UploadType,
            fileTypeMap: {},
            formData: {},
            showUpdateTemplatePrompt: false,
            templateUsedInCampaigns: []
        };
    },

    computed: {
        acceptedFileMimes() {
            return ["application/zip", ...PSDMimeTypes].join(", ");
        },

        allMembersSelected() {
            return this.familyMembersToUpdate.length === this.templateFamily.length;
        },

        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId }, false);
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        forceSelectFamilyMaster() {
            return true;
        },

        hasGroupConflicts() {
            return this.editableGroupConflicts.added.length || this.editableGroupConflicts.removed.length;
        },

        isPermissionGranted() {
            return this.canManageMasterTemplates;
        },

        templateFamilyMaster() {
            return this.templateFamily.find(({ parentId }) => parentId === null);
        },

        updateTemplateModalAdsText() {
            const { published = 0, unpublished = 0 } = this.templateUsedProducedAds;
            const xAds = published + unpublished === 1 ? " ad" : " ads";
            const publishedText = published > 0 ? `${published} published` : "";
            const unpublishedText = unpublished > 0 ? `${unpublished} unpublished` : "";
            const adsText = [publishedText, unpublishedText].filter(text => text).join(" and ");
            return `This action will affect ${adsText} ${xAds}.`;
        },

        uploadAction() {
            return (this.uploadConfig && this.uploadConfig.action) || "";
        },
        uploadConfig() {
            return this.uploadConfigObject.config;
        },

        uploadConfigObject() {
            return this.$store.state.masterTemplateLibrary.uploadConfig;
        },

        uploadType() {
            return this.masterTemplateId ? UploadType.Update : UploadType.Create;
        },

        userHasScope() {
            return this.$auth.hasScope({ clientId: this.clientId }, false);
        }
    },

    watch: {
        templateFamilyMaster(val) {
            if (val && val._id && !this.familyMembersToUpdate.includes(val._id)) {
                this.familyMembersToUpdate.push(this.templateFamilyMaster._id);
            }
        }
    },

    created() {
        /* Trigger the refetching of the upload config in case it has expired. The skip() check
           in the query definition will stop it from being executed if its not needed. */
        this.$apollo.queries.masterTemplateUploadConfig.refetch();
        this.masterTemplateService = new MasterTemplates(this.$apollo, this.clientId);
    },

    methods: {
        async createTemplate(zipUrl, templateType) {
            try {
                await this.masterTemplateService.add(zipUrl, templateType);
                this.$emit("added");
                this.$snackbar.templateAdded();
            } catch (e) {
                this.$snackbar.error("Upload Failed", e.message, {
                    duration: 0,
                    closable: true
                });
            }

            bus.$emit("percentLoadEvent", 100);
        },

        async updateTemplate(zipUrl, templateType) {
            this.familyMembersToUpdate = [this.masterTemplateId];
            try {
                const { data } = await this.masterTemplateService.update(
                    this.masterTemplateId,
                    zipUrl,
                    templateType,
                    false
                );
                const { updateMasterTemplate: { usedInCampaigns = false } = {} } = data;
                if (usedInCampaigns) {
                    const doUpdate = await this.updateTemplatePrompt(data);
                    if (doUpdate) {
                        this.$emit("updateStarted");
                        if (this.familyMembersToUpdate.length > 1) {
                            await this.updateTemplateFamily(zipUrl, templateType);
                            return;
                        }
                        // hide the modal
                        this.showUpdateTemplatePrompt = false;
                        await this.masterTemplateService.update(this.masterTemplateId, zipUrl, templateType, true);
                    } else {
                        bus.$emit("percentLoadEvent", 100);
                        return;
                    }
                }

                this.$emit("updated");
                this.$snackbar.success("Template Updated");
            } catch (e) {
                this.$snackbar.error("Upload Failed", e.message, {
                    duration: 0,
                    closable: true
                });
            }

            bus.$emit("percentLoadEvent", 100);
        },

        async updateTemplateFamily(zipUrl, templateType) {
            this.familyMembersUpdated = {};
            this.familyUpdateInProgress = true;
            // first we need to update parent to use it as a resize source later
            try {
                await this.masterTemplateService.update(this.templateFamilyMaster._id, zipUrl, templateType, true);
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [this.templateFamilyMaster._id]: true
                };
            } catch (e) {
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [this.templateFamilyMaster._id]: false
                };
                this.$snackbar.error("Failed to update family's parent template.", e.message);
                return;
            }

            await Promise.all(
                this.familyMembersToUpdate
                    .filter(id => id !== this.templateFamilyMaster._id)
                    .map(id => this.updateResize(id, this.templateFamilyMaster._id))
            );

            this.$emit("familyUpdated", this.familyMembersToUpdate);
            this.$snackbar.success("Template family Updated");
            this.familyUpdateInProgress = false;
            this.showUpdateTemplatePrompt = false;
        },

        async updateResize(templateId, parentId) {
            try {
                const result = await this.masterTemplateService.updateResize(templateId, parentId);
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [templateId]: true
                };
                return result;
            } catch (err) {
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [templateId]: false
                };
                console.log(err);
            }
            return Promise.resolve();
        },

        onBeforeUpload(e) {
            // Store a reference to the file type so that once it is uploaded we know what params to send to the API
            // The event has different types in different browsers
            this.fileTypeMap[e.name] = PSDMimeTypes.includes(e.type) ? TemplateType.PSD : TemplateType.HTML5;
        },

        onTemplateUploadError(err) {
            bus.$emit("apolloErrorEvent", err);
        },

        onTemplateUploadSuccess(response, file) {
            // Unfortunately the file type does not come through here so we need to store it in onBeforeUpload
            const templateType = this.fileTypeMap[file.name];
            const zipUrl = `${this.uploadConfig.filePrefix}/${file.name}`;

            if (this.uploadType === this.UploadType.Create) {
                this.createTemplate(zipUrl, templateType);
            } else if (this.uploadType === this.UploadType.Update) {
                this.updateTemplate(zipUrl, templateType);
            }
        },

        remoteClick() {
            this.updatePostData();
            this.$refs.remote.$el.click();
        },

        showUploadPercentage(e) {
            bus.$emit("percentLoadEvent", e.percent);
            bus.$emit("percentLoadEvent", 50);
        },

        toggleSelection(val) {
            if (val) {
                this.familyMembersToUpdate = this.templateFamily.map(({ _id }) => _id);
            } else {
                this.familyMembersToUpdate = [this.templateFamilyMaster._id];
            }
        },

        updatePostData() {
            // Required to manually add in the Key for some reason.
            if (!this.uploadConfig || !this.uploadConfig.postData) {
                return;
            }

            const data = JSON.parse(this.uploadConfig.postData);
            data.key = `${this.uploadConfig.filePrefix}/\${filename}`;
            this.formData = data;
        },

        updateTemplatePrompt(data) {
            const {
                updateMasterTemplate: {
                    usedInCampaigns = false,
                    producedAds,
                    editableGroupConflicts,
                    templateFamily
                } = {}
            } = data;
            this.templateUsedInCampaigns = usedInCampaigns;
            this.templateUsedProducedAds = producedAds;
            this.editableGroupConflicts = editableGroupConflicts;
            this.familyUpdateInProgress = false;
            this.templateFamily = templateFamily || [];
            this.templateFamilyById = this.templateFamily.reduce((acc, curr) => {
                acc[curr._id] = curr;
                return acc;
            }, {});
            this.showUpdateTemplatePrompt = true;

            return new Promise(resolve => {
                this.templateUsedPromptResolve = result => {
                    // reset back to base state
                    this.templateUsedPromptResolve = () => {};
                    this.templateUsedInCampaigns = [];
                    this.templateUsedProducedAds = {};
                    // resolve the promise with the passed in result
                    resolve(result);
                };
            });
        },

        updateTemplatePromptCancel() {
            this.showUpdateTemplatePrompt = false;
            this.templateUsedPromptResolve(false);
        },

        updateTemplatePromptOk() {
            if (this.familyMembersToUpdate.length > 1) {
                this.familyUpdateInProgress = true;
            }
            this.templateUsedPromptResolve(true);
        }
    },

    apollo: {
        masterTemplateUploadConfig: {
            query: clientMasterTemplatesUploadConfigQuery,

            variables() {
                return {
                    clientId: this.clientId
                };
            },

            skip() {
                if (!this.isPermissionGranted || !this.clientId) {
                    return true;
                }

                if (this.uploadConfigObject && this.uploadConfigObject.clientId === this.clientId) {
                    // Check if the one we have is expired or not
                    if (uploadConfigExpired(this.uploadConfigObject.config)) {
                        return false;
                    }
                    this.isLoading = false;
                    return true;
                }

                return false;
            },

            manual: true,

            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetUploadConfig, {
                    clientId: this.clientId,
                    config: data.masterTemplateUploadConfig
                });

                this.isLoading = false;
            },

            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/../sass/_variables.scss";
.template-upload-modal {
    p.small-margin {
        margin-bottom: $spacing-small;
    }
    &__button {
        margin-left: $spacing-small;
    }
    p.template-upload-modal__alert-text {
        font-size: $font-size-small;
    }

    &__label {
        font-size: $font-size-small;
    }

    ul {
        margin-bottom: $spacing-small;
    }

    li {
        font-size: $font-size-small;
        margin-left: $spacing-large;
    }

    &__removed {
        margin-top: $spacing-small;
    }
}
</style>
