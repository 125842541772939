<template>
    <Dropdown class="dropdown-selection--section section-nav">
        <div class="dropdown-selection" href="javascript:void(0)" data-testid="section-nav__selector">
            {{ getCurrentPage }}
            <Icon
                v-if="getDomain() === 'imagine' || (getDomain() === 'other' && campaignId)"
                class="dropdown-selection-icon"
                type="ios-arrow-down"
            ></Icon>
        </div>
        <DropdownMenu
            v-if="getDomain() === 'imagine'"
            slot="list"
            class="imagine-navigation"
            data-testid="section-nav__selector--menu"
        >
            <DropdownItem>
                <router-link :to="{ name: 'Home' }" data-testid="section-nav__imagine">Imagine</router-link>
            </DropdownItem>
            <DropdownItem>
                <router-link :to="{ name: 'ImagineMatrix' }" data-testid="section-nav__matrix">
                    Creative Matrix
                </router-link>
            </DropdownItem>
        </DropdownMenu>
        <DropdownMenu v-else-if="campaignId" slot="list" data-testid="section-nav__selector--menu">
            <DropdownItem v-if="getDomain() === 'other'">
                <router-link
                    :to="{ name: 'CampaignEditor', params: { clientId: clientId, campaignId: campaignId } }"
                    data-testid="section-nav__editor"
                >
                    Assemble
                </router-link>
            </DropdownItem>
            <DropdownItem v-if="getDomain() === 'other'">
                <router-link
                    :to="{ name: 'CampaignLibrary', params: { clientId: clientId, campaignId: campaignId } }"
                    data-testid="section-nav__library"
                >
                    Creative Library
                </router-link>
            </DropdownItem>
            <DropdownItem v-if="getDomain() === 'other' && isSuperAdmin">
                <router-link
                    :to="{ name: 'IntelligentDelivery', params: { clientId: clientId, campaignId: campaignId } }"
                    data-testid="section-nav__intelligent-delivery"
                >
                    Connect
                </router-link>
            </DropdownItem>
            <DropdownItem v-if="getDomain() === 'analytics'">
                <router-link
                    :to="{
                        name: 'CIInsightsDashboard',
                        params: {
                            clientId,
                            campaignId
                        }
                    }"
                    data-testid="section-nav__analytics"
                >
                    Optimize
                </router-link>
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
</template>
<script>
import { getDomain } from "@/utils";
import { AuthGetters } from "@/store/modules/auth";

export default {
    name: "SectionNav",

    props: {
        isAuthorizedContentVisible: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        getCurrentPage() {
            switch (this.$route.name) {
                case "CampaignLibrary":
                    return "Creative Library";
                case "CampaignEditor":
                    return "Assemble";
                case "CIInsightsDashboard":
                    return "Optimize";
                case "ImagineMatrix":
                    return "Creative Matrix";
                case "IntelligentDelivery":
                    return "Connect";
                default:
                    if (getDomain() === "other") {
                        return "Assemble";
                    }
                    if (getDomain() === "imagine") {
                        return "Imagine";
                    }
                    return "Optimize";
            }
        },

        isSuperAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        }
    },

    methods: {
        getDomain
    }
};
</script>
