// Should be in sync with packages/after-effects/src/types.ts#6
export const TemplateType = {
    HTML5: "HTML5",
    PSD: "PSD",
    AE: "AE"
};

export const TemplatesSortBy = {
    DATE_DESC: {
        dir: "desc",
        field: "modified"
    },
    DATE_ASC: {
        dir: "asc",
        field: "modified"
    },
    WIDTH_DESC: {
        dir: "desc",
        field: "width"
    },
    WIDTH_ASC: {
        dir: "asc",
        field: "width"
    }
};

export default { TemplateType };
