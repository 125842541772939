import Vue from "vue";
import { deepClone } from "@/utils";
import { ViewMode } from "@/store/modules/deliverableLibrary";

const storeNamespace = "masterTemplateLibrary";

export const MasterTemplateLibraryAction = {
    SetMasterTemplates: `${storeNamespace}/setMasterTemplates`,
    SetUploadConfig: `${storeNamespace}/setUploadConfig`,
    SetSelectViewMode: `${storeNamespace}/setSelectViewMode`,
    SyncFilters: `${storeNamespace}/syncFilters`,
    UpdateFamilyFilter: `${storeNamespace}/updateFamilyFilter`,
    UpdateResizedFilter: `${storeNamespace}/updateResizedFilter`,
    UpdateSizeFilter: `${storeNamespace}/updateSizeFilter`,
    UpdateTextFilter: `${storeNamespace}/updateTextFilter`,
    UpdateTypeFilter: `${storeNamespace}/updateTypeFilter`
};

export const MasterTemplateLibraryGetters = {
    filteredMasterTemplateIds: `${storeNamespace}/filteredMasterTemplateIds`,
    templateFamiliesByParentId: `${storeNamespace}/templateFamiliesByParentId`
};

export const MasterTemplateLibraryMutation = {
    RemoveMasterTemplates: "removeMasterTemplates",
    SetMasterTemplate: "setMasterTemplate",
    SetUploadConfig: "setUploadConfig",
    SetSelectViewMode: "setSelectViewMode",
    UpdateFamilyFilter: "updateFamilyFilter",
    UpdateResizedFilter: "updateResizedFilter",
    UpdateSizeFilter: "updateSizeFilter",
    UpdateTextFilter: "updateTextFilter",
    UpdateTypeFilter: "updateTypeFilter"
};

export const FilterType = {
    Campaign: "Campaign",
    Family: "Family",
    GroupValue: "GroupValue",
    Language: "Language",
    Resized: "Resized",
    Size: "Size",
    Status: "Status",
    Text: "Text",
    Type: "Type"
};

const updateFilter = (state, filter, filterType) => {
    const filters = deepClone(state.filters);
    const filterIndex = filters.findIndex(fi => fi.type === filterType);

    if (filter.values.length) {
        if (filterIndex === -1) {
            // add new filter
            filters.push(filter);
        } else {
            // update filter
            filters.splice(filterIndex, 1, filter);
        }
    } else {
        // remove filter
        filters.splice(filterIndex, 1);
    }

    return filters;
};

export default {
    namespaced: true,
    state: {
        filters: [],
        /* Master Templates by Client */
        masterTemplates: {
            /* [masterTemplateId: string]: MasterTemplate */
        },
        masterTemplateIds: [
            /* string */
        ],
        uploadConfig: {},
        selectViewMode: ViewMode.Grid
    },

    getters: {
        filterByFamily: state => masterTemplateIds => {
            const familyFilter = deepClone(state.filters).filter(filter => filter.type === FilterType.Family);
            if (!familyFilter.length) {
                return masterTemplateIds;
            }

            const parentIds = familyFilter.pop().values;
            return masterTemplateIds.filter(
                masterTemplateId =>
                    parentIds.includes(masterTemplateId) ||
                    parentIds.includes(state.masterTemplates[masterTemplateId].parentId)
            );
        },

        filteredByResized: state => masterTemplateIds => {
            const resizedFilter = deepClone(state.filters).filter(filter => filter.type === FilterType.Resized);
            if (resizedFilter.length) {
                const hideResized = resizedFilter.pop().values.pop();

                if (hideResized === "hide") {
                    return masterTemplateIds.filter(
                        masterTemplateId => !state.masterTemplates[masterTemplateId].parentId
                    );
                }
            }

            return masterTemplateIds;
        },

        filteredBySize: state => masterTemplateIds => {
            const sizeFilters = deepClone(state.filters).filter(filter => filter.type === FilterType.Size);

            if (!sizeFilters.length) {
                return masterTemplateIds;
            }

            const sizes = sizeFilters.pop().values.map(size => {
                const sizeArray = size.split("x");

                return {
                    width: parseInt(sizeArray[0], 10),
                    height: parseInt(sizeArray[1], 10)
                };
            });

            return masterTemplateIds.filter(masterTemplateId =>
                sizes.some(
                    sizeFilter =>
                        sizeFilter.width === state.masterTemplates[masterTemplateId].width &&
                        sizeFilter.height === state.masterTemplates[masterTemplateId].height
                )
            );
        },

        filteredByText: state => masterTemplateIds => {
            const textFilters = deepClone(state.filters).filter(filter => filter.type === FilterType.Text);
            if (!textFilters.length) {
                return masterTemplateIds;
            }

            const textFilter = textFilters.pop().values;

            return masterTemplateIds.filter(masterTemplateId =>
                state.masterTemplates[masterTemplateId].name.toLowerCase().includes(textFilter.toLowerCase())
            );
        },

        filteredByType: state => masterTemplateIds => {
            const typeFilters = deepClone(state.filters).filter(filter => filter.type === FilterType.Type);

            if (!typeFilters.length) {
                return masterTemplateIds;
            }

            const selectedTemplateTypes = typeFilters.pop().values;
            return masterTemplateIds.filter(masterTemplateId =>
                selectedTemplateTypes.includes(state.masterTemplates[masterTemplateId].adType)
            );
        },

        filteredMasterTemplateIds: (state, getters) => {
            const filteredBySize = getters.filteredBySize(state.masterTemplateIds);
            const filteredByType = getters.filteredByType(filteredBySize);
            const filteredByText = getters.filteredByText(filteredByType);
            const filteredByFamily = getters.filterByFamily(filteredByText);
            const filteredByResized = getters.filteredByResized(filteredByFamily);

            return filteredByResized;
        },

        templateFamiliesByParentId: state => {
            const familiesByParentId = state.masterTemplateIds.reduce((acc, templateId) => {
                const template = state.masterTemplates[templateId];
                const id = template.parentId || template._id;
                acc[id] = acc[id] || { parentId: null, children: [] };
                if (!template.parentId) {
                    acc[template._id].parentId = template._id;
                } else {
                    acc[template.parentId].children.push(template._id);
                }

                return acc;
            }, {});

            return familiesByParentId;
        }
    },

    mutations: {
        removeMasterTemplates(state) {
            state.masterTemplateIds = [];
        },

        setMasterTemplate(state, masterTemplate) {
            Vue.set(state.masterTemplates, masterTemplate._id, masterTemplate);

            if (!state.masterTemplateIds.includes(masterTemplate._id)) {
                state.masterTemplateIds.push(masterTemplate._id);
            }
        },

        setUploadConfig(state, uploadConfig) {
            state.uploadConfig = uploadConfig;
        },

        setSelectViewMode(state, mode) {
            state.selectViewMode = mode;
        },

        updateFamilyFilter(state, filter) {
            state.filters = updateFilter(state, filter, FilterType.Family);
        },

        updateResizedFilter(state, filter) {
            state.filters = updateFilter(state, filter, FilterType.Resized);
        },

        updateSizeFilter(state, filter) {
            state.filters = updateFilter(state, filter, FilterType.Size);
        },

        updateTextFilter(state, filter) {
            state.filters = updateFilter(state, filter, FilterType.Text);
        },

        updateTypeFilter(state, filter) {
            state.filters = updateFilter(state, filter, FilterType.Type);
        }
    },

    actions: {
        setMasterTemplates({ commit }, masterTemplates) {
            commit(MasterTemplateLibraryMutation.RemoveMasterTemplates);

            if (!masterTemplates.length) {
                return;
            }

            masterTemplates.forEach(masterTemplate =>
                commit(MasterTemplateLibraryMutation.SetMasterTemplate, masterTemplate)
            );
        },

        setUploadConfig({ commit }, uploadConfig) {
            commit(MasterTemplateLibraryMutation.SetUploadConfig, uploadConfig);
        },

        setSelectViewMode({ commit }, viewMode) {
            commit(MasterTemplateLibraryMutation.SetSelectViewMode, viewMode);
        },

        syncFilters({ commit, state }) {
            state.filters.forEach(filter => {
                if (filter.type === FilterType.Size) {
                    const masterTemplateSizes = Object.keys(
                        state.masterTemplateIds.reduce(
                            (acc, cur) =>
                                Object.assign(acc, {
                                    [`${state.masterTemplates[cur].width}x${state.masterTemplates[cur].height}`]: true
                                }),
                            []
                        )
                    );

                    if (!filter.values.every(filterLanguage => masterTemplateSizes.includes(filterLanguage))) {
                        const updated = {
                            ...filter,
                            values: filter.values.filter(filterLanguage => masterTemplateSizes.includes(filterLanguage))
                        };

                        commit(MasterTemplateLibraryMutation.UpdateSizeFilter, updated);
                    }
                }
            });
        },

        updateResizedFilter({ commit }, filter) {
            commit(MasterTemplateLibraryMutation.UpdateResizedFilter, {
                ...filter,
                type: FilterType.Resized
            });
        },

        updateFamilyFilter({ commit }, filter) {
            commit(MasterTemplateLibraryMutation.UpdateFamilyFilter, {
                ...filter,
                type: FilterType.Family
            });
        },

        updateSizeFilter({ commit }, filter) {
            commit(MasterTemplateLibraryMutation.UpdateSizeFilter, {
                ...filter,
                type: FilterType.Size
            });
        },

        updateTextFilter({ commit }, filter) {
            commit(MasterTemplateLibraryMutation.UpdateTextFilter, {
                ...filter,
                type: FilterType.Text
            });
        },

        updateTypeFilter({ commit }, filter) {
            commit(MasterTemplateLibraryMutation.UpdateTypeFilter, {
                ...filter,
                type: FilterType.Type
            });
        }
    }
};
