var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"master-template-select"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewMode === _vm.ViewMode.Grid),expression:"viewMode === ViewMode.Grid"}],ref:"body",staticClass:"master-template-select__wrapper"},[_c('CheckboxGroup',{model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},[(_vm.gridItems.length && !_vm.groupByFamily)?_vm._l((_vm.gridItems),function(masterTemplateId){return _c('div',{key:masterTemplateId,staticClass:"campaign-edit__deliverable",class:{
                        'campaign-edit__deliverable--resizable': _vm.resizedBanners.includes(masterTemplateId)
                    }},[_c('master-template-preview',{attrs:{"selectable":_vm.isSelectable,"max-height":_vm.bodyDimensionsPixels.height,"max-width":_vm.bodyDimensionsPixels.width,"scaling-factor":_vm.scalingFactor,"master-template":_vm.masterTemplates[masterTemplateId]},on:{"remove":function($event){return _vm.onRemove(masterTemplateId)},"resized":function($event){return _vm.onResize(masterTemplateId, $event)}}})],1)}):(_vm.gridItems.length && _vm.groupByFamily)?_vm._l((_vm.gridItemFamilies),function({ parentId, children }){return _c('div',{key:parentId,staticClass:"template-family"},[(parentId)?_c('div',{staticClass:"template-family__parent"},[_c('h4',[_vm._v("Parent Template")]),_c('div',{staticClass:"campaign-edit__deliverable",class:{
                                'campaign-edit__deliverable--resizable': _vm.resizedBanners.includes(parentId)
                            }},[_c('master-template-preview',{attrs:{"selectable":_vm.isSelectable,"max-height":_vm.bodyDimensionsPixels.height,"max-width":_vm.bodyDimensionsPixels.width,"scaling-factor":_vm.scalingFactor,"master-template":_vm.masterTemplates[parentId]},on:{"remove":function($event){return _vm.onRemove(parentId)},"resized":function($event){return _vm.onResize(parentId, $event)}}})],1)]):_vm._e(),(children.length)?_c('div',{staticClass:"template-family__children"},[_c('h4',[_vm._v("Resized Templates")]),_vm._l((children),function(masterTemplateId){return _c('div',{key:masterTemplateId,staticClass:"campaign-edit__deliverable",class:{
                                'campaign-edit__deliverable--resizable': _vm.resizedBanners.includes(masterTemplateId)
                            }},[_c('master-template-preview',{attrs:{"selectable":_vm.isSelectable,"max-height":_vm.bodyDimensionsPixels.height,"max-width":_vm.bodyDimensionsPixels.width,"scaling-factor":_vm.scalingFactor,"master-template":_vm.masterTemplates[masterTemplateId]},on:{"remove":function($event){return _vm.onRemove(masterTemplateId)},"resized":function($event){return _vm.onResize(masterTemplateId, $event)}}})],1)})],2):_vm._e()])}):_c('hox-empty-message',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.isSelectable)?[_vm._v("There aren't any new templates in your library")]:[_vm._v("There are no templates in your library")]]},proxy:true},{key:"actionItems",fn:function(){return [(_vm.$auth.userCan(_vm.$auth.Actions.CanManageMasterTemplates, { clientId: _vm.clientId }, false))?_c('master-template-upload-button',[_c('Button',{attrs:{"data-testid":"upload-template__button","type":"primary"}},[_vm._v("Upload a template")])],1):_vm._e()]},proxy:true}])})],2)],1),(_vm.viewMode === _vm.ViewMode.List)?_c('div',{staticClass:"master-template-table-select__wrapper"},[_c('master-template-table',{attrs:{"items":_vm.gridItems,"is-selectable":_vm.isSelectable},on:{"remove":_vm.onRemove},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1):_vm._e(),_c('div',{staticClass:"master-template-select__footer"},[_c('div',{staticClass:"master-templates-select__paging paging-controls"},[(_vm.itemsToPage.length)?_c('Page',{attrs:{"current":_vm.page,"total":_vm.itemsToPage.length,"page-size":_vm.pageSize,"page-size-opts":_vm.pageSizeOpts},on:{"on-change":_vm.updatePageNumber}}):_vm._e(),_c('Select',{staticClass:"hidden",model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}},_vm._l((_vm.pageSizeOpts),function(sizeOpt){return _c('Option',{key:sizeOpt.value,attrs:{"value":sizeOpt.value}},[_vm._v(" "+_vm._s(sizeOpt.label)+" ")])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }