var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dropdown',{staticClass:"dropdown-selection--section section-nav"},[_c('div',{staticClass:"dropdown-selection",attrs:{"href":"javascript:void(0)","data-testid":"section-nav__selector"}},[_vm._v(" "+_vm._s(_vm.getCurrentPage)+" "),(_vm.getDomain() === 'imagine' || (_vm.getDomain() === 'other' && _vm.campaignId))?_c('Icon',{staticClass:"dropdown-selection-icon",attrs:{"type":"ios-arrow-down"}}):_vm._e()],1),(_vm.getDomain() === 'imagine')?_c('DropdownMenu',{staticClass:"imagine-navigation",attrs:{"slot":"list","data-testid":"section-nav__selector--menu"},slot:"list"},[_c('DropdownItem',[_c('router-link',{attrs:{"to":{ name: 'Home' },"data-testid":"section-nav__imagine"}},[_vm._v("Imagine")])],1),_c('DropdownItem',[_c('router-link',{attrs:{"to":{ name: 'ImagineMatrix' },"data-testid":"section-nav__matrix"}},[_vm._v(" Creative Matrix ")])],1)],1):(_vm.campaignId)?_c('DropdownMenu',{attrs:{"slot":"list","data-testid":"section-nav__selector--menu"},slot:"list"},[(_vm.getDomain() === 'other')?_c('DropdownItem',[_c('router-link',{attrs:{"to":{ name: 'CampaignEditor', params: { clientId: _vm.clientId, campaignId: _vm.campaignId } },"data-testid":"section-nav__editor"}},[_vm._v(" Assemble ")])],1):_vm._e(),(_vm.getDomain() === 'other')?_c('DropdownItem',[_c('router-link',{attrs:{"to":{ name: 'CampaignLibrary', params: { clientId: _vm.clientId, campaignId: _vm.campaignId } },"data-testid":"section-nav__library"}},[_vm._v(" Creative Library ")])],1):_vm._e(),(_vm.getDomain() === 'other' && _vm.isSuperAdmin)?_c('DropdownItem',[_c('router-link',{attrs:{"to":{ name: 'IntelligentDelivery', params: { clientId: _vm.clientId, campaignId: _vm.campaignId } },"data-testid":"section-nav__intelligent-delivery"}},[_vm._v(" Connect ")])],1):_vm._e(),(_vm.getDomain() === 'analytics')?_c('DropdownItem',[_c('router-link',{attrs:{"to":{
                    name: 'CIInsightsDashboard',
                    params: {
                        clientId: _vm.clientId,
                        campaignId: _vm.campaignId
                    }
                },"data-testid":"section-nav__analytics"}},[_vm._v(" Optimize ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }