<template>
    <div class="library-banner-action-items-dropdown">
        <Dropdown
            placement="bottom-end"
            transfer
            trigger="custom"
            :visible="dropdownIsVisible"
            @on-clickoutside="toggleDropdown"
        >
            <square-button :is-active="dropdownIsVisible" @click="toggleDropdown">
                <Icon size="22" type="ios-more" />
            </square-button>
            <template #list>
                <DropdownItem v-if="!isPsd" @click.native="resizeMode">
                    <template v-if="isResizable">Exit</template>
                    Resize Mode
                </DropdownItem>
                <DropdownItem @click.native="showDrawer">Review</DropdownItem>
                <DropdownItem divided @click.native="refreshBanner">Refresh banner</DropdownItem>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import SquareButton from "@/components/common/SquareButton";

export default {
    components: {
        SquareButton
    },

    props: {
        isPsd: {
            default: false,
            type: Boolean
        },

        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dropdownIsVisible: false
        };
    },
    methods: {
        resizeMode() {
            this.toggleDropdown();
            this.$emit("resizeMode");
        },
        refreshBanner() {
            this.toggleDropdown();
            this.$emit("refreshBanner");
        },
        showDrawer() {
            this.$emit("showDrawer");
        },
        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-banner-action-items-dropdown {
    display: inline-block;
}
</style>
