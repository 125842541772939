<template>
    <div class="intelligent-delivery__wrapper">
        <dashboard-header
            :show-save-changes="accountsList.length > 0"
            :show-add-plarform-account="!sandboxMode"
            :show-language-select="!sandboxMode"
            :show-add-templates="!sandboxMode"
            :is-loading="isLoading"
            @showPlatformAccountFormModal="addAccount"
            @saveChanges="onSaveChanges"
            @cancelChanges="onCancelChanges"
            @showDeletedAccounts="onShowDeletedAccounts"
        />
        <div class="intelligent-delivery__dashboard">
            <hox-loading-layer v-if="isLoading" :message="statusMessage" />
            <div>
                <draggable
                    v-if="hasActiveAccounts()"
                    v-model="accountsList"
                    class="intelligent-delivery-accounts__draggable-wrapper"
                    handle=".intelligent-delivery__section-drag-handle"
                    @update="updateAccountsOrder"
                >
                    <div
                        v-for="(account, accountIndex) in visibleAccountsList"
                        :key="account.platformAccount.id"
                        :class="['intelligent-delivery__account', accountClasses(account)]"
                    >
                        <div class="intelligent-delivery__account-title">
                            <div class="intelligent-delivery__account-name">
                                <component :is="getAccountTypeIcon(account.platformAccount.platformDetails.type)" />
                                {{ account.platformAccount.name }}
                                <div
                                    v-if="account.platformAccount.publishStatus"
                                    class="intelligent-delivery__account-status"
                                >
                                    {{ PublishStatus[account.platformAccount.publishStatus] }}
                                    <extended-publish-status-details
                                        :platform-account="account.platformAccount"
                                        :campaign-id="campaignId"
                                    />
                                </div>
                            </div>
                            <div class="intelligent-delivery__account-actions">
                                <div v-if="account.platformAccount.status === AccountStatus.Deleted">
                                    <Tooltip placement="left" :transfer="true">
                                        <Button @click="restoreDeletedAccount(accountIndex)">
                                            <icon type="ios-undo" />
                                            Restore account
                                        </Button>
                                        <template #content>
                                            <template>
                                                Restore the account
                                                <br />
                                                and all it's rules.
                                            </template>
                                        </template>
                                    </Tooltip>
                                </div>
                                <div v-else>
                                    <Dropdown trigger="click" class="account__options">
                                        <Icon type="ios-settings" />
                                        <DropdownMenu slot="list">
                                            <DropdownItem @click.native="editAccount(accountIndex)">
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem @click.native="deleteAccount(accountIndex)">
                                                Delete
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Icon
                                        :key="accountIndex"
                                        type="ios-move"
                                        class="intelligent-delivery__section-drag-handle"
                                    ></Icon>
                                </div>
                            </div>
                        </div>
                        <table v-if="hasActiveElements(account.rules)" class="intelligent-delivery__account-rules">
                            <tr class="intelligent-delivery__rule-header">
                                <th class="intelligent-delivery__rule-name">Rule</th>
                                <th class="intelligent-delivery__rule-signals">Signals</th>
                                <th class="intelligent-delivery__rule-status">Status</th>
                                <th class="intelligent-delivery__rule-actions">Actions</th>
                            </tr>
                            <draggable
                                class="intelligent-delivery-rule__draggable-wrapper"
                                handle=".intelligent-delivery__rule-drag-handle"
                                @start="setEditedAccount(accountIndex)"
                                @update="updateRulesOrder"
                            >
                                <tr
                                    v-for="(rule, indexRule) in account.rules"
                                    :key="rule.name + indexRule"
                                    class="intelligent-delivery__rule-row"
                                    :class="{
                                        'intelligent-delivery__rule-disabled': rule.status === RuleStatus.Deleted
                                    }"
                                >
                                    <td class="intelligent-delivery__rule-name">
                                        <div class="intelligent-delivery__signal-name">
                                            <strong>{{ rule.name }}</strong>
                                            <span class="intelligent-delivery__rule-number">#{{ rule.id }}</span>
                                        </div>

                                        <span class="intelligent-delivery__rule-variants">
                                            <a @click="toggleSelectVariantsModal(accountIndex, indexRule)">
                                                {{ selectedNumberOfVariants(accountIndex, indexRule) }}
                                            </a>
                                        </span>
                                    </td>
                                    <td class="intelligent-delivery__rule-signals">
                                        <div v-if="rule.signals.length > 0" class="intelligent-delivery__signals-wrap">
                                            <div
                                                v-for="(signal, indexSignal) in rule.signals"
                                                :key="indexSignal"
                                                class="intelligent-delivery__signal"
                                            >
                                                <div class="intelligent-delivery__signal-name">
                                                    <span>
                                                        <strong>{{ signal.name }}</strong>
                                                    </span>
                                                    <span class="intelligent-delivery__signal-short-desc">
                                                        {{ signal.shortDescription }}
                                                    </span>
                                                </div>
                                                <div class="intelligent-delivery__signal-actions">
                                                    <Icon
                                                        type="md-create"
                                                        @click.native="editSignal(accountIndex, indexRule, indexSignal)"
                                                    />
                                                    <Icon
                                                        type="md-trash"
                                                        @click.native="
                                                            deleteSignal(accountIndex, indexRule, indexSignal)
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>There are no signals set up.</div>
                                        <a @click="addSignal(accountIndex, indexRule)">Add new signal</a>
                                    </td>
                                    <td class="intelligent-delivery__rule-status">{{ getRuleStatus(rule) }}</td>
                                    <td class="intelligent-delivery__rule-actions">
                                        <div class="intelligent-delivery__signal-actions">
                                            <div v-if="rule.status === RuleStatus.Deleted">
                                                <Button @click.native="restoreRule(accountIndex, indexRule)">
                                                    <icon type="ios-undo" />
                                                    Restore rule
                                                </Button>
                                            </div>
                                            <div v-else>
                                                <Icon
                                                    type="md-create"
                                                    @click.native="editRule(accountIndex, indexRule)"
                                                />
                                                <Icon type="md-sun" />
                                                <Icon
                                                    type="md-trash"
                                                    @click.native="deleteRule(accountIndex, indexRule)"
                                                />
                                                <Icon
                                                    type="ios-move"
                                                    class="intelligent-delivery__rule-drag-handle"
                                                ></Icon>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </draggable>

                            <tr class="intelligent-delivery__rule-row">
                                <td class="intelligent-delivery__add-rule">
                                    <a @click="addRule(accountIndex)">Add new rule</a>
                                </td>
                            </tr>
                        </table>

                        <div v-else class="intelligent-delivery__no-data">
                            There are no rules set up.
                            <a @click="addRule(accountIndex)">Add new rule</a>
                        </div>
                    </div>
                </draggable>
                <hox-empty-message v-else-if="!isLoading">
                    <template #title>Here you can add platform accounts to publish ads.</template>
                    <template #actionItems>
                        <Button data-testid="new-platform-account__button" type="primary" @click="addAccount">
                            Add new platform account
                        </Button>
                    </template>
                </hox-empty-message>
            </div>
            <platform-account-form-modal
                :is-visible="isAddAccountModalVisible"
                :account-index="selectedAccount"
                :form-data="formData"
                :account-action="accountAction"
                @close="toggleAddAccountModal"
                @addPlatformAccount="onAddPlatformAccount"
            />
            <account-rule-form-modal
                :is-visible="isAccountRuleModalVisible"
                :account-index="editedAccountIndex"
                :rule-index="editedRuleIndex"
                :form-data="formData"
                :rule-action="ruleAction"
                @close="toggleAccountRuleModal"
                @accountRuleAction="onAccountRuleAction"
            />
            <hox-modal v-if="isSelectVariantsModalVisible" fullscreen @close="toggleSelectVariantsModal">
                <template #header>Select variants</template>
                <div>
                    <select-variants-modal
                        :selected-filters="selectedFilters"
                        @updateVariantsFilters="onUpdateVariantsFilters"
                    />
                </div>
            </hox-modal>
            <account-signal-form-modal
                :key="signalAction + addSignalCounter"
                :is-visible="isManageSignalsModalVisible"
                :account-index="editedAccountIndex"
                :rule-index="editedRuleIndex"
                :signal-index="editedSignalIndex"
                :form-data="formData"
                :signal-action="signalAction"
                @close="toggleAccountSignalModal"
                @accountSignalAction="onAccountSignalAction"
            />
        </div>
        <alert-modal
            v-model="isDiscardChangesModalVisible"
            header="Leaving the page"
            title="Do you really want to leave?"
            content="Unsaved changes will be lost. This cannot be undone."
            :warning="true"
            :on-ok="handleDiscardChanges"
        />
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import Sidebar from "@/components/Sidebar";
import DashboardHeader from "@/components/IntelligentDelivery/DashboardHeader";
import draggable from "vuedraggable";
import iconFacebook from "@/assets/intelligent-delivery/facebook_social media_social_icon.svg";
import iconTikTok from "@/assets/intelligent-delivery/tiktok_social media_icon.svg";
import iconGoogleDoubleClick from "@/assets/intelligent-delivery/google_icon.svg";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import PlatformAccountFormModal from "@/components/IntelligentDelivery/PlatformAccountFormModal";
import AccountRuleFormModal from "@/components/IntelligentDelivery/AccountRuleFormModal";
import AccountSignalFormModal from "@/components/IntelligentDelivery/AccountSignalFormModal";
import PublishingDetailsModal from "@/components/IntelligentDelivery/PublishingDetailsModal";
import createCampaignFile from "@/apollo/mutations/CreateCampaignFile.gql";
import getIntelligentDeliveryApiInfoQuery from "@/apollo/queries/v2/GetIntelligentDeliveryApiInfo.gql";
import computedDeliverableComponentsQuery from "@/apollo/queries/v2/ComputedDeliverableComponents.gql";
import { fetchRequest } from "@/components/Reporting/data/utils";
import SelectVariantsModal from "@/components/IntelligentDelivery/SelectVariantsModal";
import { AccountTypesList, AccountStatus, RuleStatus, PublishStatus } from "@/enums/activate";
import ExtendedPublishStatusDetails from "@/components/IntelligentDelivery/ExtendedPublishStatusDetails";
import { deepClone, formatGroupName, generateTemplateLabel } from "@/utils";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    name: "IntelligentDelivery",
    components: {
        AlertModal,
        Sidebar,
        DashboardHeader,
        draggable,
        iconFacebook,
        iconTikTok,
        iconGoogleDoubleClick,
        NavigationTabs,
        NavigationTabsItem,
        PlatformAccountFormModal,
        ExtendedPublishStatusDetails,
        AccountRuleFormModal,
        AccountSignalFormModal,
        PublishingDetailsModal,
        SelectVariantsModal
    },
    props: {
        sandboxMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            AccountTypesList,
            AccountStatus,
            RuleStatus,
            PublishStatus,
            isLoading: true,
            isAddAccountModalVisible: false,
            isAccountRuleModalVisible: false,
            loadingLibraryFilterOptions: 0,
            isManageSignalsModalVisible: false,
            ruleEditTab: "general",
            statusMessage: "",
            formData: {},
            tempCampaignFileLocation: "",
            accountsList: [],
            latestVersionId: "",
            savedAccountsList: [],
            apiInfo: {},
            selectedAccount: 0,
            selectedRule: 0,
            editedAccountIndex: 0,
            editedRuleIndex: 0,
            editedSignalIndex: 0,
            accountAction: "add",
            ruleAction: "add",
            signalAction: "add",
            isSelectVariantsModalVisible: false,
            showDeletedAccounts: false,
            isPublishingStatusUpdating: false,
            statusInterval: null,
            isDetailedPublishingStatusUpdating: false,
            isDetailedPublishedStatusModalVisible: false,
            detailedPublishDetails: "",
            addSignalCounter: 0,
            nextFunction: () => {},
            isDiscardChangesModalVisible: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        newAccountOrder() {
            let newAccountOrder = 1;
            if (this.accountsList.length) {
                const activeAccounts = this.accountsList.filter(
                    account => account.platformAccount.status === this.AccountStatus.Active
                );
                if (activeAccounts.length > 0) {
                    newAccountOrder =
                        activeAccounts.reduce((maxOrder, obj) => {
                            return obj.platformAccount.order > maxOrder ? obj.platformAccount.order : maxOrder;
                        }, activeAccounts[0].platformAccount.order) + 1;
                }
            }
            return newAccountOrder;
        },

        newRuleOrder() {
            let newRuleOrder = 1;
            const editedAccountRules = this.accountsList[this.editedAccountIndex].rules;
            if (editedAccountRules.length) {
                const activeAccounts = editedAccountRules.filter(rule => rule.status === this.AccountStatus.Active);
                if (activeAccounts.length > 0) {
                    newRuleOrder =
                        activeAccounts.reduce((maxOrder, obj) => {
                            return obj.order > maxOrder ? obj.order : maxOrder;
                        }, activeAccounts[0].order) + 1;
                }
            }
            return newRuleOrder;
        },

        visibleAccountsList() {
            if (!this.showDeletedAccounts) {
                return this.accountsList
                    .map(item => {
                        const filteredRules = item.rules.filter(rule => rule.status === this.RuleStatus.Active);
                        return {
                            ...item,
                            rules: filteredRules
                        };
                    })
                    .filter(item => item.platformAccount.status === this.AccountStatus.Active);
            }
            return this.accountsList;
        },

        selectedFilters() {
            if (
                this.accountsList &&
                this.accountsList[this.selectedAccount] &&
                this.accountsList[this.selectedAccount].rules &&
                this.accountsList[this.selectedAccount].rules[this.selectedRule]
            ) {
                return this.accountsList[this.selectedAccount].rules[this.selectedRule].variantsFilters;
            }
            return [];
        },
        hasInProgressStatus() {
            return this.accountsList.some(account => {
                const status = account.platformAccount?.publishStatus;
                return !status || ["IN_PROGRESS", "IN_PROGRESS_WITH_ERRORS"].includes(status);
            });
        }
    },

    beforeDestroy() {
        clearInterval(this.statusInterval);
    },

    methods: {
        accountClasses(account) {
            if (account.platformAccount.status === this.AccountStatus.Deleted) {
                return "intelligent-delivery__account-deleted";
            }
            return "";
        },

        addPublishStatus(accountPublishedStatus) {
            this.accountsList = this.accountsList.map(account => {
                const { id } = account.platformAccount;
                const matchedObj = accountPublishedStatus.data.find(item => item.accountId === id);
                return matchedObj
                    ? {
                          ...account,
                          platformAccount: {
                              ...account.platformAccount,
                              publishStatus: matchedObj.status,
                              publishSummary: matchedObj.summary
                          }
                      }
                    : account;
            });
        },

        getSignalIcon(type) {
            return `md-${type}`;
        },

        getAccountTypeIcon(accountType) {
            const account = Object.values(this.AccountTypesList).find(acc => acc.id === accountType);
            return account ? account.icon : null;
        },

        getRuleStatus(rule) {
            if (rule.signals.length === 0) {
                return this.RuleStatus.Active;
            }
            if (rule.status === this.RuleStatus.Active) {
                return rule.isRuleActiveBySignals ? this.RuleStatus.Active : this.RuleStatus.Inactive;
            }
            return this.RuleStatus.Deleted;
        },

        addAccount() {
            this.accountAction = "add";
            this.formData = {};
            this.toggleAddAccountModal();
        },

        addRule(index) {
            this.editedAccountIndex = index;
            this.ruleAction = "add";
            this.formData = {};
            this.toggleAccountRuleModal();
        },

        addSignal(accountIndex, ruleIndex) {
            this.addSignalCounter += 1;
            this.signalAction = "add";
            this.editedAccountIndex = accountIndex;
            this.editedRuleIndex = ruleIndex;
            this.formData = {};
            this.toggleAccountSignalModal();
        },

        customSort(a, b) {
            if (
                a.platformAccount.status === this.AccountStatus.Active &&
                b.platformAccount.status === this.AccountStatus.Deleted
            ) {
                return -1;
            }
            if (
                a.platformAccount.status === this.AccountStatus.Deleted &&
                b.platformAccount.status === this.AccountStatus.Active
            ) {
                return 1;
            }
            return a.platformAccount.order - b.platformAccount.order;
        },

        ruleCustomSort(a, b) {
            if (a.status === this.RuleStatus.Active && b.status === this.RuleStatus.Deleted) {
                return -1;
            }
            if (a.status === this.RuleStatus.Deleted && b.status === this.RuleStatus.Active) {
                return 1;
            }
            return a.order - b.order;
        },

        toggleAccountRuleModal() {
            this.isAccountRuleModalVisible = !this.isAccountRuleModalVisible;
        },

        toggleAddAccountModal() {
            this.isAddAccountModalVisible = !this.isAddAccountModalVisible;
        },

        toggleAccountSignalModal() {
            this.isManageSignalsModalVisible = !this.isManageSignalsModalVisible;
        },

        toggleDiscardChangesModal() {
            this.isDiscardChangesModalVisible = !this.isDiscardChangesModalVisible;
        },

        handleDiscardChanges() {
            this.nextFunction();
        },

        onAddPlatformAccount(data) {
            // Some of values for the this object are hardcoded now. In the future they will be populated by the users.
            this.formData = data.formData;
            if (data.accountAction === "add") {
                let platformDetails = {};
                if (this.formData.accountType === this.AccountTypesList.Facebook.id) {
                    platformDetails = {
                        type: this.AccountTypesList.Facebook.id,
                        accountId: this.formData.accountId,
                        instagramActorId: this.formData.instagramActorId,
                        pageId: this.formData.pageId
                    };
                } else {
                    platformDetails = {
                        type: this.AccountTypesList.Google.id,
                        accountId: "123",
                        campaignProfileId: this.formData.campaignProfileId
                    };
                }
                const newPlatformAccount = {
                    platformAccount: {
                        order: this.newAccountOrder,
                        name: this.formData.accountName,
                        platformDetails,
                        lineType: "platformAccount",
                        status: "active"
                    },
                    rules: []
                };
                this.accountsList.push(newPlatformAccount);
                this.accountsList.sort(this.customSort);
                this.formData = {};
            } else if (data.accountAction === "edit") {
                let editedAccount = {};
                if (this.formData.accountType === this.AccountTypesList.Facebook.id) {
                    editedAccount = {
                        type: data.formData.accountType,
                        accountId: data.formData.accountId,
                        instagramActorId: data.formData.instagramActorId,
                        pageId: data.formData.pageId
                    };
                } else {
                    editedAccount = {
                        type: data.formData.accountType,
                        accountId: "123",
                        campaignProfileId: data.formData.campaignProfileId
                    };
                }
                this.accountsList[data.accountIndex].platformAccount.platformDetails = editedAccount;
                this.accountsList[data.accountIndex].platformAccount.name = data.formData.accountName;
            }
            this.toggleAddAccountModal();
        },

        onAccountRuleAction(data) {
            this.formData = data.formData;

            if (data.ruleAction === "add") {
                const newRule = {
                    name: data.formData.ruleName,
                    status: "active",
                    order: this.newRuleOrder,
                    signals: [],
                    targeting: [
                        {
                            name: "Locations",
                            value: "UK, US, AU"
                        },
                        {
                            name: "Audience",
                            value: "Age 20-30"
                        },
                        {
                            name: "Position",
                            value: "News Feed, ..."
                        }
                    ],
                    rulePlatformDetails: {
                        adSetId: data.formData.adSetId
                    },
                    variantsFilters: {
                        language: [],
                        masterTemplateIds: [],
                        editableGroups: []
                    }
                };
                this.accountsList[data.accountIndex].rules.push(newRule);
                this.accountsList[data.accountIndex].rules.sort(this.ruleCustomSort);
            } else if (data.ruleAction === "edit") {
                this.accountsList[data.accountIndex].rules[data.ruleIndex].name = data.formData.ruleName;
                if (!this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails) {
                    this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails = {};
                }

                this.accountsList[data.accountIndex].rules[data.ruleIndex].rulePlatformDetails.adSetId =
                    data.formData.adSetId;
            }
            this.toggleAccountRuleModal();
        },

        onAccountSignalAction(data) {
            this.formData = data.formData;

            if (data.signalAction === "add") {
                const newSignal = {
                    signalType: "DATETIME",
                    name: data.formData.signalName,
                    value: data.formData.signalValue ?? "",
                    shortDescription: data.formData.shortDescription ?? "",
                    longDescription: data.formData.longDescription ?? "",
                    order: 1
                };
                this.accountsList[data.accountIndex].rules[data.ruleIndex].signals.push(newSignal);
            } else if (data.signalAction === "edit") {
                const editedSignal =
                    this.accountsList[data.accountIndex].rules[data.ruleIndex].signals[data.signalIndex];
                editedSignal.name = data.formData.signalName;
                editedSignal.value = data.formData.signalValue;
                editedSignal.shortDescription = data.formData.shortDescription;
                editedSignal.longDescription = data.formData.longDescription;
                this.accountsList[data.accountIndex].rules[data.ruleIndex].signals[data.signalIndex] = editedSignal;
            }

            this.toggleAccountSignalModal();
        },

        onCancelChanges() {
            this.accountsList = deepClone(this.savedAccountsList);
        },

        onSaveChanges(usedForDraft) {
            this.isLoading = true;
            clearInterval(this.statusInterval);
            this.createCampaignFile(usedForDraft);
        },

        onShowDeletedAccounts(value) {
            this.showDeletedAccounts = value;
        },

        onUpdateVariantsFilters(filters) {
            this.isSelectVariantsModalVisible = false;
            this.accountsList[this.selectedAccount].rules[this.selectedRule].variantsFilters = filters;
        },
        async handleErrorResponse(response) {
            const responseValidateJson = await response.json();
            const responseMessage = responseValidateJson.message
                ? responseValidateJson.message
                : "There was an error please try again.";
            this.$snackbar.error(responseMessage);
            this.isLoading = false;
        },

        async getCampaignInfo() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/accounts/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    this.isLoading = false;
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data?.latestVersionId !== null) {
                        this.savedAccountsList = deepClone(
                            responseValidateJson.data.accountsRules.sort(this.customSort)
                        );
                        this.accountsList = deepClone(responseValidateJson.data.accountsRules);
                        this.accountsList.map(acc => {
                            return acc.rules.sort(this.ruleCustomSort);
                        });
                        this.latestVersionId = responseValidateJson.data.latestVersionId;

                        this.removeDeletedGroups(this.accountsList);

                        clearInterval(this.statusInterval);
                        if (this.hasInProgressStatus) {
                            this.statusInterval = setInterval(() => {
                                this.getCampaignPublishStatus();
                            }, 5000);
                        }
                    }
                } else {
                    this.handleErrorResponse(response);
                }
            } catch (e) {
                console.log(e);
            }
        },

        async createCampaignFile(usedForDraft) {
            const { data } = await this.$apollo.mutate({
                mutation: createCampaignFile,
                variables: {
                    campaignId: this.campaignId
                }
            });
            this.tempCampaignFileLocation = data.createCampaignFile;
            if (data.createCampaignFile) {
                this.statusMessage = "Exporting campaign data";
                this.validateCampaignFile(usedForDraft);
            } else {
                this.isLoading = false;
                this.$snackbar.error("There was an error creating the campaign file");
            }
        },

        async validateCampaignFile(usedForDraft) {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/validate/`;
                const fetcherBody = {
                    latestVersionId: this.latestVersionId,
                    accountsRules: this.accountsList,
                    fileUrl: this.tempCampaignFileLocation
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data) {
                        this.statusMessage = "Validating campaign rules";
                        this.saveCampaignFile(responseValidateJson.data, usedForDraft);
                    } else {
                        this.isLoading = false;
                        this.$snackbar.error("There was an error validating the campaign rules");
                    }
                } else {
                    this.handleErrorResponse(response);
                }
            } catch (e) {
                this.getCampaignInfo();
                console.log(e);
            }
        },

        async saveCampaignFile(data, usedForDraft) {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-campaign/${this.campaignId}/save`;
                const fetcherBody = {
                    ...data,
                    latestVersionId: this.latestVersionId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    if (!usedForDraft) {
                        this.statusMessage = "Saving campaign rules";
                        this.generateDesiredState();
                    } else {
                        this.statusMessage = "Saving campaign";
                        this.isLoading = false;
                        this.$snackbar.success("Campaign is saved");
                        this.getCampaignInfo();
                    }
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
                this.getCampaignInfo();
            }
        },

        async generateDesiredState() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/build-desired-states`;
                const fetcherBody = {
                    campaignId: this.campaignId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });

                if (response.ok) {
                    this.statusMessage = "Building desired state file";
                    this.publishCampaign();
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
                this.getCampaignInfo();
            }
        },

        async publishCampaign() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/platforms/publish`;
                const fetcherBody = {
                    campaignId: this.campaignId
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    body: JSON.stringify(fetcherBody),
                    method: "POST",
                    apiKey: this.apiInfo.apiKey
                });

                if (response.ok) {
                    this.statusMessage = "Publishing campaign";
                    this.isLoading = false;
                    this.statusMessage = "";
                    const responsePublishJson = await response.json();
                    const isEmptyCampaign = responsePublishJson?.data.publishStatuses.every(
                        publishStatus => publishStatus.account.status === "deleted"
                    );
                    if (isEmptyCampaign) {
                        this.$snackbar.info("Campaign has no active accounts to publish");
                    } else {
                        this.$snackbar.success("Campaign is publishing");
                    }
                } else {
                    this.handleErrorResponse(response);
                }
                this.getCampaignInfo();
            } catch (e) {
                console.log(e);
            }
        },

        async getCampaignPublishStatus() {
            this.isPublishingStatusUpdating = true;
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/campaigns/${this.campaignId}/status/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (!this.hasInProgressStatus) {
                    clearInterval(this.statusInterval);
                    return;
                }
                if (response.ok) {
                    this.isLoading = false;
                    const responseValidateJson = await response.json();
                    this.isPublishingStatusUpdating = false;
                    this.addPublishStatus(responseValidateJson);
                } else {
                    this.handleErrorResponse(response);
                    this.getCampaignInfo();
                }
            } catch (e) {
                console.log(e);
            }
        },

        deleteAccount(accountIndex) {
            this.accountsList[accountIndex].platformAccount.status = this.AccountStatus.Deleted;
            this.accountsList.sort(this.customSort);
        },

        editRule(accountIndex, ruleIndex) {
            this.ruleAction = "edit";
            this.editedRuleIndex = ruleIndex;
            this.editedAccountIndex = accountIndex;
            const editedRule = this.accountsList[accountIndex].rules[ruleIndex];
            this.formData = {
                ruleName: editedRule.name,
                adSetId: editedRule.rulePlatformDetails?.adSetId
            };
            this.toggleAccountRuleModal();
        },

        deleteRule(accountIndex, ruleIndex) {
            this.accountsList[accountIndex].rules[ruleIndex].status = this.RuleStatus.Deleted;
            this.accountsList[accountIndex].rules.sort(this.ruleCustomSort);
        },

        restoreRule(accountIndex, ruleIndex) {
            this.accountsList[accountIndex].rules[ruleIndex].status = this.RuleStatus.Active;
            if (this.accountsList[accountIndex].platformAccount.status !== this.AccountStatus.Active) {
                this.restoreDeletedAccount(accountIndex);
            }
        },

        editAccount(accountIndex) {
            this.accountAction = "edit";
            this.selectedAccount = accountIndex;
            const editedAccount = this.accountsList[accountIndex].platformAccount;
            this.formData = {
                accountName: editedAccount.name,
                accountType: editedAccount.platformDetails.type,
                ...editedAccount.platformDetails
            };
            this.toggleAddAccountModal();
        },

        restoreDeletedAccount(accountIndex) {
            this.accountsList[accountIndex].platformAccount.status = this.AccountStatus.Active;
            this.accountsList[accountIndex].platformAccount.order = this.newAccountOrder;
            this.accountsList.sort(this.customSort);
        },

        editSignal(accountIndex, ruleIndex, signalIndex) {
            this.signalAction = "edit";
            this.addSignalCounter += 1;
            this.editedRuleIndex = ruleIndex;
            this.editedAccountIndex = accountIndex;
            this.editedSignalIndex = signalIndex;
            const editedSignal = this.accountsList[accountIndex].rules[ruleIndex].signals[signalIndex];
            this.formData = {
                signalName: editedSignal.name,
                signalValue: editedSignal.value,
                shortDescription: editedSignal.shortDescription,
                longDescription: editedSignal.longDescription
            };
            this.toggleAccountSignalModal();
        },

        deleteSignal(accountIndex, ruleIndex, signalIndex) {
            this.accountsList[accountIndex].rules[ruleIndex].signals.splice(signalIndex, 1);
            this.$snackbar.info("Singal has been deleted");
        },

        removeDeletedGroups() {
            this.accountsList.forEach(account => {
                account.rules.forEach(rule => {
                    this.filterDeletedVariants(rule, "languages", "languages");
                    this.filterDeletedVariants(rule, "masterTemplateIds", "masterTemplates");
                });
            });
        },

        filterDeletedVariants(rule, filterKey, storeKey) {
            if (rule.variantsFilters && rule.variantsFilters[filterKey]) {
                const filterOptions = this.$store.state.deliverableLibrary.filterOptions[storeKey];
                const filteredArray = rule.variantsFilters[filterKey].filter(variantFilter =>
                    filterOptions.some(filterOption => filterOption.value === variantFilter)
                );
                Object.assign(rule.variantsFilters, { [filterKey]: filteredArray });
            }
        },

        hasActiveAccounts() {
            if (this.showDeletedAccounts) {
                return this.accountsList.length;
            }
            return !this.accountsList.every(account => account.platformAccount.status === this.AccountStatus.Deleted);
        },

        hasActiveElements(arr) {
            if (this.showDeletedAccounts) {
                return arr.length;
            }
            return !arr.every(obj => obj.status === this.AccountStatus.Deleted);
        },

        setEditedAccount(index) {
            this.editedAccountIndex = index;
        },

        updateAccountsOrder(val) {
            const removedElement = this.accountsList.splice(val.newIndex, 1)[0];
            this.accountsList.splice(val.newIndex, 0, removedElement);
            this.accountsList.forEach((obj, index) => {
                const updatedObj = { ...obj };
                updatedObj.platformAccount.order = index + 1;
                this.accountsList[index] = updatedObj;
            });
        },

        updateRulesOrder(val) {
            const editedRules = this.accountsList[this.editedAccountIndex].rules;

            const removedItem = editedRules.splice(val.oldIndex, 1)[0];
            editedRules.splice(val.newIndex, 0, removedItem);
            editedRules.forEach((item, index) => {
                const updatedItem = { ...item };
                updatedItem.order = index + 1;
                editedRules[index] = updatedItem;
            });
            this.accountsList[this.editedAccountIndex].rules = editedRules;
        },

        toggleSelectVariantsModal(accountIndex = 0, ruleIndex = 0) {
            this.selectedAccount = accountIndex;
            this.selectedRule = ruleIndex;
            this.isSelectVariantsModalVisible = !this.isSelectVariantsModalVisible;
        },

        selectedNumberOfVariants(accountIndex, ruleIndex) {
            const editableGroupsFilterOptions = this.$store.state.deliverableLibrary.filterOptions.editableGroups;
            const { variantsFilters } = this.accountsList[accountIndex].rules[ruleIndex];

            let result = 1;

            if (!variantsFilters?.editableGroupValueIds?.length) {
                return "Select variants";
            }

            if (!editableGroupsFilterOptions.length) {
                setTimeout(() => {
                    this.selectedNumberOfVariants(accountIndex, ruleIndex);
                }, 2500);
                return "Select variants";
            }

            if (variantsFilters && variantsFilters.languages) {
                result *= variantsFilters.languages.length;
            }

            if (variantsFilters.masterTemplateIds && variantsFilters.masterTemplateIds?.length > 1) {
                result *= variantsFilters.masterTemplateIds.length;
            }

            if (variantsFilters.editableGroupValueIds?.length > 1) {
                result *= editableGroupsFilterOptions.reduce((acc, group) => {
                    const count = group.options.filter(option =>
                        variantsFilters.editableGroupValueIds.includes(option.value)
                    ).length;
                    return acc * count;
                }, 1);
            }

            return `${result} variants selected`;
        },

        setMasterTemplateFilterOptions(masterTemplates) {
            /*
                We do this sort so that the same master templates get suffixed with
                the same number (if there are no changes to the master templates)
                by the generateLabel function if there are multiple master templates
                of the same size.
            */
            const masterTemplatesSortedById = [...masterTemplates].sort((a, b) => {
                if (a.created < b.created) {
                    return -1;
                }
                if (a.created > b.created) {
                    return 1;
                }
                return 0;
            });
            const generatedTemplateLabels = [];
            const masterTemplateOptions = [];
            masterTemplatesSortedById.forEach(masterTemplate => {
                const label = generateTemplateLabel(masterTemplate, generatedTemplateLabels);
                generatedTemplateLabels.push(label);
                masterTemplateOptions.push({
                    height: masterTemplate.width,
                    label,
                    value: masterTemplate._id,
                    width: masterTemplate.width
                });
            });
            /*
                We sort the options by label because it's nicer if the options
                are sorted by dimensions in the UI.
            */
            // eslint-disable-next-line complexity
            const masterTemplateOptionsSortedByDimensions = [...masterTemplateOptions].sort((a, b) => {
                if (a.width < b.width) {
                    return -1;
                }
                if (a.width > b.width) {
                    return 1;
                }
                if (a.height < b.height) {
                    return -1;
                }
                if (a.height > b.height) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            this.$store.dispatch(
                DeliverableLibraryAction.SetMasterTemplateFilterOptions,
                masterTemplateOptionsSortedByDimensions
            );
        },

        setEditableGroupFilterOptions(editableGroupValues) {
            const optionsByEditableGroup = editableGroupValues.reduce((acc, { _id, editableGroupName, value }) => {
                if (!acc[editableGroupName]) {
                    acc[editableGroupName] = {
                        label: formatGroupName(editableGroupName),
                        name: editableGroupName,
                        options: []
                    };
                }
                acc[editableGroupName].options.push({
                    label: value,
                    value: _id
                });
                return acc;
            }, {});
            const editableGroupsWithOptions = Object.values(optionsByEditableGroup);
            this.$store.dispatch(DeliverableLibraryAction.SetEditableGroupFilterOptions, editableGroupsWithOptions);
        }
    },

    apollo: {
        intelligentDeliveryApiInfo: {
            query: getIntelligentDeliveryApiInfoQuery,
            result({ data }) {
                this.apiInfo = data.intelligentDeliveryApiInfo;
                this.getCampaignInfo();
            }
        },

        computedDeliverableComponents: {
            query: computedDeliverableComponentsQuery,
            fetchPolicy: "network-only",
            loadingKey: "loadingLibraryFilterOptions",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingFilterOptions = true;
                    return;
                }
                this.hasErrorLoadingFilterOptions = false;
                const { editableGroupValues, languages, masterTemplates } = data.computedDeliverableComponents;
                this.$store.dispatch(DeliverableLibraryAction.SetLanguageFilterOptions, languages);
                this.setMasterTemplateFilterOptions(masterTemplates);
                this.setEditableGroupFilterOptions(editableGroupValues);
                this.removeDeletedGroups(this.accountsList);
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        const keysToRemove = ["publishStatus", "publishSummary"];

        const acountWithoutPublishDetails = JSON.parse(JSON.stringify(this.accountsList))?.map(item => {
            return {
                ...item,
                platformAccount: Object.fromEntries(
                    Object.entries(item?.platformAccount).filter(([key]) => !keysToRemove.includes(key))
                )
            };
        });

        if (JSON.stringify(this.savedAccountsList) !== JSON.stringify(acountWithoutPublishDetails)) {
            this.nextFunction = next;
            this.toggleDiscardChangesModal();
        } else {
            next();
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.intelligent-delivery {
    &__wrapper {
        padding: 60px 20px;
        .save-changes {
            display: flex;
        }
    }
    &-accounts__draggable-wrapper {
        display: flex;
        flex-direction: column;
    }
    &__account {
        margin-bottom: 20px;
        &-deleted {
            table {
                opacity: 0.8;
                background: rgba(255, 0, 0, 0.05);
            }
        }
        &-title {
            display: flex;
            margin-bottom: 10px;
        }
        &-name {
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            min-width: 80%;
            svg {
                max-height: 32px;
                margin-right: 10px;
            }
        }
        &-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            i {
                cursor: pointer;
            }
            .intelligent-delivery__section-drag-handle {
                margin-top: 0;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        &-rules {
            text-align: left;
            width: 100%;
            border-right: 0;
            border-bottom: 0;
            td {
                padding: 5px;
                border-bottom: 1px solid #e8eaec;
                border-right: 1px solid #e8eaec;
            }
            th {
                background-color: #efeff0;
                padding: 5px;
                font-weight: bold;
                border-bottom: 1px solid #e8eaec;
                border-right: 1px solid #e8eaec;
            }
        }
    }
    &__signal {
        display: flex;
        border-bottom: 1px solid #e8eaec;
        padding: 5px 0;
        margin-bottom: 5px;
        &:last-child {
            border-bottom: 0;
        }
        &-actions {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            i {
                opacity: 0.5;
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &:hover {
            .intelligent-delivery__signal-actions {
                opacity: 1;
            }
        }
        &-name {
            display: flex;
            align-items: baseline;
            .intelligent-delivery__signal-actions {
                opacity: 0;
            }
            &:hover {
                .intelligent-delivery__signal-actions {
                    opacity: 1;
                }
            }
        }
        &-short-desc {
            font-size: 12px;
            padding-right: 20px;
            color: #9b9b9b;
        }
        .intelligent-delivery__signal-name {
            flex-direction: column;
        }
    }
    &__rule {
        &-header,
        &-row {
            display: flex;
            justify-content: space-between;
            > div {
                padding: 10px;
            }
        }
        &-row {
            border: 1px solid #c1c7cd;
            border-top: 0;
        }
        &-disabled {
            opacity: 0.5;
        }
        &-header {
            background: #efeff0;
            font-weight: bold;
            border: 1px solid #c1c7cd;
        }
        &-number {
            display: inline-block;
            padding-left: 5px;
            font-size: 12px;
            line-height: 16px;
        }
        &-variants {
            color: #5e00b5;
            cursor: pointer;
        }
        &-name {
            width: 40%;
        }
        &-signals {
            width: calc(60% - 360px);
            a {
                display: inline-block;
                margin-top: 20px;
            }
        }
        &-status,
        &-actions {
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__no-data {
        border: 1px solid #c1c7cd;
        padding: 5px;
    }
    &__dashboard {
        position: relative;
        .loading-layer--theme-light {
            position: absolute;
        }
    }
    &__add-rule {
        width: 100%;
    }
}
.rule-edit-modal {
    &__platform {
        li {
            display: flex;
            align-items: center;
            svg {
                height: 32px;
                width: 32px;
                margin-right: 10px;
            }
        }
    }
}
</style>
