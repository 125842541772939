<template>
    <container>
        <hox-loading-layer v-if="isLoading" size="large" />
        <div v-else class="clients">
            <div class="clients__action-items">
                <div class="clients__tabs">
                    <section-nav />
                    <span>My Clients</span>
                </div>
                <sort-clients v-model="sortClientsBy" class="clients__action-item" />
                <Button
                    v-if="$auth.userCan($auth.Actions.CanAddClient)"
                    class="clients__action-item"
                    size="large"
                    type="primary"
                    data-testid="add-client_button"
                    @click="editClient"
                >
                    Add New Client
                </Button>
            </div>
            <div>
                <Row :gutter="15">
                    <Col v-for="client of clients" :key="client._id" :xs="24" :sm="12" :md="8" :lg="6">
                        <client-card
                            :id="client._id"
                            :logo="client.logoUrl"
                            :name="client.name"
                            :title="client.name"
                            @edit="editClient(client)"
                            @manage-team="showTeam(client._id)"
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                title="Client Details"
                :value="dialogFormVisible"
                data-testid="client-details__modal"
                @on-cancel="cancelAddClient"
            >
                <Form class="edit-client" :model="form" :label-width="formLabelWidth" @submit.native.prevent>
                    <FormItem label="Client name" required>
                        <Input
                            ref="clientName"
                            v-model="form.name"
                            auto-complete="off"
                            data-testid="edit-client__name"
                            @keydown.native.enter="confirmAddClient"
                        ></Input>
                    </FormItem>
                    <FormItem v-if="form.id && userCanUpdateFacebookConfig" label="Facebook">
                        <Input
                            v-model="form.fbBusinessId"
                            placeholder="Business ID"
                            auto-complete="off"
                            data-testid="edit-client__fb-business-id"
                            @keydown.native.enter="confirmAddClient"
                        ></Input>
                        <Input
                            v-model="form.fbAdAccountId"
                            placeholder="Ad Account ID"
                            auto-complete="off"
                            data-testid="edit-client__fb-account-id"
                            @keydown.native.enter="confirmAddClient"
                        ></Input>
                    </FormItem>
                    <FormItem v-if="form.id" label="Logo">
                        <image-upload
                            :value="form.logo"
                            :config="form.uploadConfig"
                            data-testid="edit-client__logo"
                            @imageUploading="disableConfirm = true"
                            @imageUploaded="
                                disableConfirm = false;
                                form.content = $event;
                            "
                            @imageUploadFailed="disableConfirm = false"
                        ></image-upload>
                    </FormItem>
                    <FormItem v-if="form.id && $auth.userCan($auth.Actions.CanEnableImageProcessing)">
                        <Checkbox
                            v-model="form.imageProcessingEnabled"
                            data-testid="edit-client__enable-image-processing"
                        >
                            Enable Generative AI features
                        </Checkbox>
                    </FormItem>
                </Form>
                <span slot="footer" class="dialog-footer">
                    <Button
                        v-if="form.id && $auth.userCan($auth.Actions.CanRemoveClient, { clientId: form.id })"
                        class="edit-client-button__delete"
                        icon="ios-trash-outline"
                        data-testid="edit-client-button__delete"
                        type="error"
                        ghost
                        @click="deleteClient(form)"
                    >
                        Delete Client
                    </Button>
                    <Button
                        class="edit-client-button__cancel"
                        data-testid="edit-client-button__cancel"
                        @click="cancelAddClient"
                    >
                        Cancel
                    </Button>
                    <Button
                        class="edit-client-button__confirm"
                        data-testid="edit-client-button__confirm"
                        type="primary"
                        :disabled="disableConfirm || lock || !dialogFormVisible"
                        @click="confirmAddClient"
                    >
                        Confirm
                    </Button>
                </span>
            </Modal>
            <team
                v-if="teamModalIsVisible"
                :client-id="teamClientId"
                entity-type="client"
                @close="toggleTeamModalIsVisible"
            />
        </div>
    </container>
</template>

<script>
import bus from "@/bus";
import { uploadConfigExpired } from "@/utils";
import ClientCard from "@/components/ClientCard";
import Container from "@/components/Container";
import Team from "@/components/Team";
import ImageUpload from "@/components/ImageUpload";
import SortClients, { defaultClientSortBy } from "@/components/SortClients";

import clientsQuery from "@/apollo/queries/Clients.gql";
import addClientMutation from "@/apollo/mutations/AddClient.gql";
import updateClientMutation from "@/apollo/mutations/UpdateClient.gql";
import removeClientMutation from "@/apollo/mutations/RemoveClient.gql";
import { AuthGetters } from "@/store/modules/auth";
import SectionNav from "../components/SectionNav";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Clients",
    components: {
        SortClients,
        ClientCard,
        Container,
        Team,
        ImageUpload,
        SectionNav
    },
    data() {
        return {
            form: this.resetForm(),
            formLabelWidth: 120,
            team: {
                email: "",
                access: [
                    {
                        value: "read",
                        label: "Read"
                    },
                    {
                        value: "write",
                        label: "Read + Write"
                    }
                ],
                value: "read"
            },
            dialogFormVisible: false,
            teamVisible: false,
            teamButtonVisible: false,
            lock: false,
            disableConfirm: false,
            isLoading: true,
            sortClientsBy: defaultClientSortBy,
            teamClientId: "",
            teamModalIsVisible: false
        };
    },

    computed: {
        userScope() {
            return this.$store.state.auth.me && this.$store.state.auth.me.scope;
        },

        showSandboxButton() {
            if (!this.clients || !this.clients.length) {
                return false;
            }

            return this.$auth.userCan(this.$auth.Actions.CanUseSandbox);
        },

        userCanUpdateFacebookConfig() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        }
    },

    watch: {
        // ref is undefined in mounted due to conditional display, need to watch for its visibility
        async dialogFormVisible(dialogFormVisible) {
            if (dialogFormVisible && this.$refs.clientName) {
                // have to wait for next tick otherwise element gets unfocussed again
                await this.$nextTick();
                this.$refs.clientName.focus();
            }
        },

        userScope: {
            handler() {
                if (
                    this.clients &&
                    this.clients.some(client => !this.$auth.hasScope({ clientId: client._id }, false))
                ) {
                    this.$apollo.queries.clients.refetch();
                }
            }
        }
    },

    created() {
        this.$apollo.queries.clients.refetch();
    },

    methods: {
        cancelAddClient() {
            this.dialogFormVisible = false;
        },

        confirmAddClient() {
            if (this.form.name.trim() !== "") {
                if (!this.form.id) {
                    this.storeClient();
                } else {
                    this.patchClient();
                }
            } else {
                this.$snackbar.fieldRequired("Client name is a required field");
            }
        },

        deleteClient(form) {
            this.$Modal.confirm({
                title: `Delete client ${form.name}?`,
                content: `Deleting the client "${form.name}" is permanent and can not be undeleted.`,
                okText: "Delete",
                onOk: async () => {
                    try {
                        await this.$apollo.mutate({
                            mutation: removeClientMutation,
                            variables: {
                                clientId: form.id
                            }
                        });

                        await this.$apollo.queries.clients.refetch();
                        bus.$emit("percentLoadEvent", 100);
                        this.$snackbar.success("Client deleted");
                        this.dialogFormVisible = false;
                        this.resetForm();
                    } catch (err) {
                        this.$snackbar.error(err.message);
                    }

                    this.lock = false;
                }
            });
        },

        storeClient() {
            if (!this.lock) {
                this.lock = true;
                this.$apollo
                    .mutate({
                        mutation: addClientMutation,
                        variables: {
                            name: this.form.name
                        },
                        refetchQueries: [
                            {
                                query: clientsQuery,
                                variables: {
                                    orderBy: this.sortClientsBy
                                }
                            }
                        ]
                    })
                    .then(() => {
                        this.dialogFormVisible = false;
                        this.$snackbar.success(`The client "${this.form.name}" has been added.`);
                        this.$apollo.queries.clients.refetch();
                    })
                    .catch(err => {
                        this.$snackbar.error(err.message);
                    })
                    .finally(() => {
                        this.lock = false;
                    });
            }
        },

        patchClient() {
            if (!this.lock) {
                this.lock = true;
                const fbAdsManagerConfig = this.form.fbBusinessId &&
                    this.form.fbAdAccountId && {
                        adAccountId: this.form.fbAdAccountId,
                        businessId: this.form.fbBusinessId
                    };
                this.$apollo
                    .mutate({
                        mutation: updateClientMutation,
                        variables: {
                            clientId: this.form._id,
                            content: this.form.content,
                            name: this.form.name,
                            imageProcessingEnabled: this.form.imageProcessingEnabled,
                            // if not provided it should unset facebook for this client
                            fbAdsManagerConfig: fbAdsManagerConfig || null
                        },
                        refetchQueries: [
                            {
                                query: clientsQuery,
                                variables: {
                                    orderBy: this.sortClientsBy
                                }
                            }
                        ]
                    })
                    .then(() => {
                        this.dialogFormVisible = false;
                        this.$snackbar.success(`The client "${this.form.name}" has been updated`);
                        this.$apollo.queries.clients.refetch();
                    })
                    .catch(err => {
                        this.$snackbar.unauthorized(err);
                    })
                    .finally(() => {
                        this.lock = false;
                    });
            }
        },

        async editClient(client) {
            this.dialogFormVisible = true;

            if (client && client._id) {
                // Check if upload config is still valid
                if (uploadConfigExpired(client.mastheadUploadConfig)) {
                    // If it isn't then refetch the clients query and update the passed in
                    //  client with the data from the new request
                    await this.$apollo.queries.clients.refetch();
                    const updatedClient = this.clients.find(c => c._id === client._id);
                    if (updatedClient) {
                        Object.assign(client, updatedClient);
                    }
                }

                this.form._id = client._id;
                this.form.name = client.name;
                this.form.fbBusinessId = client.fbAdsManagerConfig && client.fbAdsManagerConfig.businessId;
                this.form.fbAdAccountId = client.fbAdsManagerConfig && client.fbAdsManagerConfig.adAccountId;
                this.form.id = client.id;
                this.form.uploadConfig = client.mastheadUploadConfig;
                this.form.logo = client.logoUrl;
                this.form.content = "";
                this.form.imageProcessingEnabled = client.imageProcessingEnabled;
                this.teamButtonVisible = true;
            } else {
                this.form = this.resetForm();
                this.teamButtonVisible = false;
            }
        },

        resetForm() {
            return {
                _id: "",
                name: "",
                id: "",
                uploadConfig: {},
                logo: "",
                content: "",
                imageProcessingEnabled: undefined
            };
        },

        showTeam(clientId) {
            this.teamClientId = clientId;
            this.toggleTeamModalIsVisible();
        },

        toggleTeamModalIsVisible() {
            this.teamModalIsVisible = !this.teamModalIsVisible;
        }
    },

    apollo: {
        clients: {
            query: clientsQuery,
            variables() {
                return {
                    orderBy: this.sortClientsBy
                };
            },
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data }) {
                if (typeof data.clients === "undefined") {
                    this.$router.push({ name: "UserNotFound" });
                } else if (data.clients.length === 1) {
                    // if user can't do anything in the client screen lets redirect it to the campaign dashboard
                    if (
                        !this.$auth.userCan(this.$auth.Actions.CanAddClient) &&
                        !this.$auth.hasScope({ clientId: data.clients[0]._id })
                    ) {
                        this.$router.replace({
                            name: "CampaignsDashboard",
                            params: { clientId: data.clients[0]._id }
                        });
                    }
                }

                this.isLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";

.clients {
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }
}

.edit-client {
    &-button__delete {
        float: left;
    }
}
</style>
