<template>
    <hox-modal v-if="isVisible" @close="close">
        <template #header>
            {{ actionLabel }}
        </template>
        <div class="account-signal-modal__wrap">
            <Form
                ref="signalForm"
                class="account-signal__form"
                :rules="baseValidationRules"
                :model="localData"
                @submit.native.prevent="onSubmitAccountSignal"
            >
                <FormItem ref="signalName" label="Signal name" prop="signalName">
                    <Input
                        v-model="localData.signalName"
                        auto-complete="off"
                        data-testid="signal-modal__name"
                    ></Input>
                </FormItem>
                <FormItem ref="dtstart" label="Start date" prop="dtstart">
                    <DatePicker
                        v-model="localData.dtstart"
                        type="date"
                        placeholder="Select date"
                        data-testid="signal-modal__start-date"
                        @on-change="handleStartDateChange"
                    ></DatePicker>
                    <TimePicker
                        v-model="selectedTime.startTime"
                        format="HH:mm"
                        placeholder="Select time"
                        data-testid="signal-modal__start-time"
                        @on-change="handleTimeChange($event, 'startTime')"
                    ></TimePicker>
                </FormItem>
                <FormItem ref="until" label="End date" prop="until">
                    <DatePicker
                        v-model="localData.until"
                        type="date"
                        placeholder="Select date"
                        data-testid="signal-modal__end-date"
                        @on-change="handleEndDateChange"
                    ></DatePicker>
                    <TimePicker
                        v-model="selectedTime.endTime"
                        format="HH:mm"
                        placeholder="Select time"
                        data-testid="signal-modal__end-time"
                        @on-change="handleTimeChange($event, 'endTime')"
                    ></TimePicker>
                </FormItem>
                <FormItem ref="tzid" label="Timezone" prop="tzid">
                    <Select
                        ref="tzid"
                        v-model="localData.tzid"
                        label="Select Timezone"
                        prop="tzid"
                        data-testid="signal-modal__tzid"
                    >
                        <Option v-for="timezone in timezones" :key="timezone.label" :value="timezone.value">
                            {{ timezone.label }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem ref="weekDay" label="Week day" prop="weekDay">
                    <CheckboxGroup v-model="localData.byweekday" class="editable-group__values" :vertical="true">
                        <Checkbox v-for="weekDay in weekDays" :key="weekDay.value" :label="weekDay.value">
                            {{ weekDay.label }}
                        </Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem ref="generatedShortDescription" label="Short description" prop="generatedShortDescription">
                    <Input
                        v-model="generatedShortDescription"
                        type="textarea"
                        auto-complete="off"
                        data-testid="signal-modal__short-desc"
                    ></Input>
                </FormItem>
                <Button data-testid="new-platform-account__modal-button" @click="onGenerateShortDescription">
                    Generate rule description
                </Button>
                <FormItem ref="longDescription" label="Long description" prop="longDescription">
                    <Input
                        v-model="localData.longDescription"
                        type="textarea"
                        auto-complete="off"
                        data-testid="signal-modal__long-desc"
                    ></Input>
                </FormItem>
                <Button
                    type="primary"
                    data-testid="new-platform-account__modal-button"
                    @click="onSubmitAccountSignal"
                >
                    {{ actionLabel }}
                </Button>
            </Form>
        </div>
    </hox-modal>
</template>
<script>
import { checkEmptySpaces } from "@/utils";
import { RRule, rrulestr } from "rrule";
import { weekDays, months, timezones } from "@/enums/activate";

export default {
    name: "AccountSignalFormModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        signalAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        signalIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            weekDays,
            months,
            timezones,
            localData: {},
            selectedTime: {
                startDate: "",
                startTime: "00:00",
                endDate: "",
                endTime: "00:00"
            },
            generatedShortDescription: "",
            baseValidationRules: {
                signalName: [
                    {
                        required: true,
                        message: "Please enter an signal name",
                        trigger: "blur"
                    },
                    {
                        message: "Signal name must not start with space",
                        validator: (rule, value) => checkEmptySpaces(value),
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    computed: {
        actionLabel() {
            if (this.signalAction === "edit") {
                return "Edit signal";
            }
            return "Add signal";
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(newVal) {
                if (!newVal.signalValue) {
                    return;
                }
                const editedRule = rrulestr(newVal.signalValue);
                this.localData = {
                    ...this.localData,
                    ...newVal,
                    ...editedRule.options,
                    byweekday: editedRule.options.byweekday ?? [],
                    dtstart: this.updateDateToUtc(editedRule.options.dtstart, "decode")
                };

                if (this.localData.until) {
                    this.localData.until = this.updateDateToUtc(editedRule.options.until, "decode");
                    const endDateComponents = {
                        year: this.localData.until.getFullYear(),
                        month: String(this.localData.until.getMonth() + 1).padStart(2, "0"),
                        day: String(this.localData.until.getDate()).padStart(2, "0"),
                        hours: String(this.localData.until.getHours()).padStart(2, "0"),
                        minutes: String(this.localData.until.getMinutes()).padStart(2, "0")
                    };

                    this.selectedTime.endDate = `${endDateComponents.year}-${endDateComponents.month}-${endDateComponents.day}`;
                    this.selectedTime.endTime = `${endDateComponents.hours}:${endDateComponents.minutes}`;
                }

                const startDateComponents = {
                    year: this.localData.dtstart.getFullYear(),
                    month: String(this.localData.dtstart.getMonth() + 1).padStart(2, "0"),
                    day: String(this.localData.dtstart.getDate()).padStart(2, "0"),
                    hours: String(this.localData.dtstart.getHours()).padStart(2, "0"),
                    minutes: String(this.localData.dtstart.getMinutes()).padStart(2, "0")
                };

                this.selectedTime.startDate = `${startDateComponents.year}-${startDateComponents.month}-${startDateComponents.day}`;
                this.selectedTime.startTime = `${startDateComponents.hours}:${startDateComponents.minutes}`;

                this.generatedShortDescription = this.formData.shortDescription;
            },
            immediate: true
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },

        updateDateToUtc(date, type) {
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            if (type === "encode") {
                return new Date(date.getTime() - userTimezoneOffset);
            }
            return new Date(date.getTime() + userTimezoneOffset);
        },

        getTodayDate(type) {
            if (type === "date") {
                const today = new Date();

                const year = today.getFullYear();
                const month = (today.getMonth() + 1).toString().padStart(2, "0");
                const day = today.getDate().toString().padStart(2, "0");

                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate;
            }
            return new Date();
        },

        getStartDate() {
            let startDate;
            const { startTime, startDate: selectedDate } = this.selectedTime;

            if (startTime) {
                const date = selectedDate || this.getTodayDate("date");
                startDate = this.updateDateToUtc(new Date(`${date} ${startTime}`), "encode");
            } else {
                const date = selectedDate ? `${selectedDate} 00:00` : new Date();
                startDate = this.updateDateToUtc(new Date(date), "encode");
            }

            return startDate;
        },

        getEndDate() {
            const { endDate: selectedEndDate, endTime } = this.selectedTime;

            if (selectedEndDate) {
                const time = endTime || "00:00";
                const endDate = this.updateDateToUtc(new Date(`${selectedEndDate} ${time}`), "encode");
                return endDate;
            }

            return null;
        },

        generateRule() {
            const { localData } = this;
            const startDate = this.getStartDate();
            const endDate = this.getEndDate();

            const ruleOptions = {
                freq: 3,
                byweekday: localData.byweekday ?? [],
                tzid: localData.tzid,
                dtstart: startDate
            };

            if (endDate) {
                ruleOptions.until = endDate;
            }

            return new RRule(ruleOptions);
        },

        handleStartDateChange(date) {
            this.selectedTime.startDate = date;
        },

        handleEndDateChange(date) {
            this.selectedTime.endDate = date;
        },

        handleTimeChange(time, type) {
            if (type === "startTime") {
                this.selectedTime.startTime = time;
            } else {
                this.selectedTime.endTime = time;
            }
        },

        onGenerateShortDescription() {
            const rule = this.generateRule();
            this.generatedShortDescription = rule.toText();
            this.localData.shortDescription = this.generatedShortDescription;
        },

        onSubmitAccountSignal() {
            const rule = this.generateRule();
            this.localData.shortDescription = this.generatedShortDescription;
            this.localData.signalValue = rule.toString();

            this.$refs.signalForm.validate(valid => {
                if (!valid) {
                    this.$snackbar.error("Please correctly fill in the required fields");
                    return;
                }

                this.$snackbar.info(`Rule signal ${this.signalAction === "add" ? "added" : "edited"}`);
                this.$emit("accountSignalAction", {
                    formData: this.localData,
                    accountIndex: this.accountIndex,
                    ruleIndex: this.ruleIndex,
                    signalIndex: this.signalIndex,
                    signalAction: this.signalAction
                });
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.account-signal {
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
    }

    &__info {
        padding-left: 15px;
        display: flex;
        align-items: center;
    }

    .ivu-form-item {
        display: flex;
    }
}
</style>
