<template>
    <div
        class="banner-preview"
        :class="{
            'banner-preview--click-disabled': !contentClickable,
            'banner-preview--resizable': isResizable
        }"
    >
        <component
            :is="bannerComponent"
            :id="iframeId"
            :key="key"
            class="banner-preview__banner"
            :class="{ 'banner-preview__banner--resizable': isResizable }"
            :debug="debug"
            :width="banner.width"
            :height="banner.height"
            :preview-values="previewValues"
            :load-in-view="true"
            :scaling-factor="scalingFactor"
            :is-psd="isPsd"
            :is-resizable="isResizable"
            @BannerIFrameLoaded="onBannerLoaded"
        ></component>
        <preview-controls
            v-if="showControls"
            :banner="banner"
            :value="currentTime"
            :duration="duration"
            :is-playing="isPlaying"
            @play="play"
            @pause="pause"
            @change="setCurrentTime"
        />
    </div>
</template>

<script>
import throttle from "lodash.throttle";
import bus from "@/bus";
import { BannerActionEvents, BannerDefaultDuration, BannerEvents, BannerState } from "@/enums/banners";
import { BannersAction } from "@/store/modules/banners.store";
import Banner from "@/components/Banner";
import PreviewControls from "@/components/PreviewControls";
import LazyLoadBanner from "@/components/LazyLoadBanner";

export default {
    name: "BannerPreview",
    components: {
        LazyLoadBanner,
        PreviewControls,
        Banner
    },
    props: {
        previewValues: {
            type: String,
            required: true
        },

        banner: {
            type: Object,
            required: true
        },

        group: {
            type: String
        },

        iframePrefix: {
            type: String,
            default: ""
        },

        contentClickable: {
            type: Boolean,
            default: false
        },

        controls: {
            type: Boolean,
            default: false
        },

        autoplay: {
            type: Boolean,
            default: false
        },

        lazyLoad: {
            type: Boolean,
            default: false
        },
        debug: {
            type: Boolean,
            default: false
        },

        scalingFactor: {
            type: Number
        },

        isPsd: {
            default: false,
            type: Boolean
        },
        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isPlaybackCompleted: false,
            isPlaying: true,
            isLoading: true,
            duration: BannerDefaultDuration,
            currentTime: 0,
            iframe: null,
            key: 0,
            bannerComponent: Banner
        };
    },

    computed: {
        /* todo restore when we can reliably obtain timeline duration
        isPlaybackCompleted() {
            return this.currentTime === this.duration;
        }, */

        isBannerStatic() {
            return this.duration === 0;
        },

        showControls() {
            return !this.isLoading && this.controls;
        },

        iframeId() {
            return `${this.iframePrefix}${this.banner.id}`;
        }
    },

    created() {
        // this var needs to be declared this way to prevent vue's reactivity being added to the banner timeline object
        // as for some reason it breaks some ot the functions.
        this.timeline = null;

        if (this.lazyLoad) {
            this.bannerComponent = LazyLoadBanner;
        }
    },

    mounted() {
        // bacause bus uses different instance of vue we need to bind this in order to access local value
        bus.$on(BannerActionEvents.BannerPause, this.pauseThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerPlay, this.playThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerRefresh, this.refreshThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerReplay, this.replayThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerSeek, this.seekThisBanner.bind(this));
        bus.$on(BannerActionEvents.AllBannersPlay, this.play.bind(this));
        bus.$on(BannerActionEvents.AllBannersPause, this.pause.bind(this));
        bus.$on(BannerActionEvents.AllBannersReplay, this.replay.bind(this));
        bus.$on(BannerActionEvents.AllBannersSeek, this.seek.bind(this));
    },

    methods: {
        onBannerLoaded(evt) {
            this.iframe = evt.currentTarget.contentWindow;
            this.timeline = (this.iframe.Creative && this.iframe.Creative.tl) || this.iframe.TL;

            if (this.timeline) {
                // had to disable the duration as it turns out the way the templates are created we have no reliable
                // way to obtain the total duration.
                // this.duration = this.timeline.totalDuration();

                this.timeline.eventCallback(
                    "onUpdate",
                    throttle(() => {
                        this.updateCurrentTime();
                    }, 100)
                );

                this.timeline.eventCallback("onComplete", () => this.onComplete());

                if (this.autoplay) {
                    this.play();
                } else {
                    this.seek(0);
                }
            }

            this.$store.dispatch(BannersAction.Update, {
                instance: this.iframeId,
                duration: BannerDefaultDuration,
                hasTimeline: !!this.timeline,
                state: BannerState.Loaded
            });

            this.isLoading = false;
            // todo fix - we need to find the longest duration
            this.$emit(BannerEvents.BannerLoaded, this.duration);

            bus.$emit(BannerEvents.BannerLoaded, {
                bannerId: this.banner.id,
                duration: this.duration
            });
        },

        togglePlay() {
            if (this.isPlaying) {
                this.pause();
            } else {
                this.play();
            }
        },

        play(group) {
            if (!group || this.group === group) {
                if (!this.timeline) {
                    return;
                }

                if (this.isPlaybackCompleted) {
                    return;
                }

                this.timeline.play();
                this.isPlaying = true;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Play
                });
            }
        },

        replay(group) {
            if (!group || this.group === group) {
                this.isPlaybackCompleted = false;

                // If there is no timeline then refresh the banner component by incrementing the key
                if (!this.timeline) {
                    this.key += 1;
                    return;
                }

                this.timeline.restart();
                this.isPlaying = true;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Play
                });
            }
        },

        pause(group) {
            if (!group || this.group === group) {
                if (!this.timeline) {
                    return;
                }

                this.timeline.pause();
                this.isPlaying = false;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Pause
                });
            }
        },

        seek(time, group) {
            if (!group || this.group === group) {
                this.isPlaybackCompleted = false;

                if (this.isPlaying) {
                    this.pause();
                }

                this.setCurrentTime(time);

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Seek
                });
            }
        },

        setCurrentTime(event) {
            this.currentTime = event;

            if (this.timeline) {
                this.timeline.seek(this.currentTime, false);

                /* None of the components uses this value so disable at the moment
                this.$store.dispatch( BannersAction.SetCurrentTime, {
                    instance: this.iframeId,
                    currentTime: this.currentTime
                } ); */
            }
        },

        updateCurrentTime() {
            if (this.timeline) {
                const timelineTime = this.timeline.time();
                this.currentTime = timelineTime;
                this.$store.dispatch(BannersAction.SetCurrentTime, {
                    instance: this.iframeId,
                    currentTime: timelineTime
                });
            }
        },

        onComplete() {
            // on hsbc banners onCompete is fired twice
            if (this.timeline.progress() === 1) {
                this.isPlaybackCompleted = true;
                this.pause();

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Completed
                });
            }
        },

        pauseThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.pause(this.group);
            }
        },

        playThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.play(this.group);
            }
        },

        refreshThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.key += 1;
            }
        },

        replayThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.replay(this.group);
            }
        },

        seekThisBanner(bannerId, time) {
            if (bannerId === this.banner._id) {
                this.setCurrentTime(time, this.group);
            }
        }
    }
};
</script>

<style lang="scss">
.banner-preview--click-disabled {
    pointer-events: none;
}
.banner-preview--resizable,
.banner-preview__banner--resizable {
    width: 100%;
    height: 100%;
}
</style>
