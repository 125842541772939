<template>
    <hox-modal class="download-modal" @close="close">
        <template #header>Download</template>
        <template>
            <div class="download-modal__select-wrapper">
                Format
                <download-dropdown-button
                    v-model="platform"
                    :show-html-options="showHtmlOptions"
                    :show-psd-options="showPsdOptions"
                    data-testid="asset-download__format-select"
                    class="download-modal__select-platform"
                />
            </div>
            <hox-alert v-if="requiresProfileId" :type="alertType">
                <template #title>
                    <p v-if="hasProfileId">DC Campaign ProfileId</p>
                    <p v-else>Missing DC Campaign ProfileId</p>
                </template>
                <template #content>
                    <p v-if="!hasProfileId">
                        Before downloading your ads, set up your campaign feed in DoubleClick to get your Profile ID and
                        add it here:
                    </p>

                    <Input
                        v-model="dcProfileId"
                        class="download-modal__dc-profile-input"
                        :class="{ 'download-modal__dc-profile-input--error': hasProfileId && !isProfileIdValid }"
                        placeholder="Double Click Profile Id"
                    />
                    <span
                        v-show="hasProfileId && !isProfileIdValid"
                        class="download-modal__dc-profile-input-error-message"
                    >
                        ProfileId needs to be a number
                    </span>
                </template>
            </hox-alert>
            <hox-alert v-else type="info">
                <template #title>
                    <p>This could take a while</p>
                </template>
                <template #content>
                    <p>
                        You will be notified when they are ready to download and you will be able to find download links
                        in your notifications.
                    </p>
                </template>
            </hox-alert>
            <div class="download-modal__options-wrapper">
                <div v-if="isZipDownload">
                    <ul>
                        <template v-if="isFlashtalking">
                            <li class="download-modal__short-input">
                                <Checkbox v-model="ftClickTagCount" label="Click Tag Count">
                                    <h4>Click Tag Count</h4>
                                </Checkbox>
                                <p>Flashtalking Click tag count</p>

                                <div class="download-modal__short-input-value">
                                    <Input v-model="ftClickTagCountValue" @input="onClickTagCountChange" />
                                </div>
                            </li>
                            <li class="download-modal__long-input">
                                <Checkbox v-model="ftBrowserExclusions" label="Browser Exclusions">
                                    <h4>Browser Exclusions</h4>
                                </Checkbox>
                                <p>Comma separated list of browsers</p>
                                <div class="download-modal__long-input-value">
                                    <Input v-model="ftBrowserExclusionsValue" @input="onBrowserExclusionChange" />
                                </div>
                            </li>
                        </template>
                        <li>
                            <Checkbox v-model="optimiseTextFiles" label="Optimise Files">
                                <h4>Optimise Files</h4>
                            </Checkbox>
                            <p>Minify HTML, CSS and JS files used by the template</p>
                        </li>
                        <li>
                            <Checkbox v-model="optimiseFontFiles" label="Optimise Fonts">
                                <h4>Optimise Fonts</h4>
                            </Checkbox>
                            <p>Remove unused glyphs from font files used in the template</p>
                        </li>
                        <li>
                            <Checkbox v-model="optimiseImages" label="Optimise Images">
                                <h4>Optimise Images</h4>
                            </Checkbox>
                            <p>This may result in colour profile changes</p>
                        </li>
                    </ul>
                </div>
                <div v-else-if="isVideoDownload">
                    <ul>
                        <li class="download-modal__option-duration download-modal__short-input">
                            <Checkbox v-model="manualDuration" label="Capture Audio">
                                <h4>Video runtime</h4>
                            </Checkbox>
                            <p>You can choose to manually specify the video length</p>
                            <div class="download-modal__option-duration-value download-modal__short-input-value">
                                <Input v-model="manualDurationValue" placeholder="0.00" @input="onDurationChange">
                                    <template slot="append">
                                        <span>sec</span>
                                    </template>
                                </Input>
                            </div>
                        </li>
                        <li class="download-modal__option-fps">
                            <Checkbox v-model="manualFps" label="FPS settings">
                                <h4>Frame rate</h4>
                            </Checkbox>
                            <p>You can choose to manually specify the video framerate</p>
                            <div class="download-modal__short-input-value">
                                <Select v-model="manualFpsValue" size="small" @input="onFpsChange">
                                    <Option v-for="item in fpsOptions" :key="item" :value="item">
                                        {{ item }}
                                    </Option>
                                </Select>
                            </div>
                        </li>
                        <li>
                            <Checkbox v-model="captureAudio" label="Capture Audio">
                                <h4>Capture Audio</h4>
                            </Checkbox>
                            <p>Capture audio from the template and include it in the output video</p>
                        </li>
                        <li>
                            <Checkbox v-model="highQuality" label="High Quality">
                                <h4>Export as uncompressed</h4>
                            </Checkbox>
                            <p>You can then re-encode into another format manually</p>
                        </li>
                        <li>
                            <Checkbox v-model="droppedFramesOptimisation" label="Optimise Video Frames">
                                <h4>Optimise Video Frames</h4>
                            </Checkbox>
                            <p>
                                Useful when the banner contains a video with the same fps as the output. Note that it
                                will make the video generation slower.
                            </p>
                        </li>
                        <li>
                            <Checkbox v-model="useEdgeChromiumVersion" label="Use The Latest Chromium Version">
                                <h4>Use The Latest Chromium Version</h4>
                            </Checkbox>
                            <p>
                                Take advantage of the Chromium v122 features. Unselect this if you encounter issues
                                while generating the video and a more stable Chromium version (v112) will be used.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template #footer>
            <Button class="download-modal__button" data-testid="asset-download__button--cancel" @click="close">
                Cancel
            </Button>
            <Button
                class="download-modal__button"
                :disabled="isDownloadDisabled"
                type="primary"
                data-testid="asset-download__button--download"
                @click="processDownloads"
            >
                Download
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import DownloadDropdownButton from "@/components/Campaign/DownloadPlatformSelect";
import HoxAlert from "@/components/common/Alert";
import { NotificationTypes } from "@/enums/notifications";
import { BatchDownloads, Platform, ZipDownloads } from "@/enums/platforms";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import DeliverablesService from "@/services/Deliverables";
import { CampaignAction } from "@/store/modules/campaign";
import { JobsAction } from "@/store/modules/jobs";
import { JobStatusToNotificationStatus } from "@/enums/jobs";
import { NotificationsAction, NotificationsGetter } from "@/store/modules/notifications";

export default {
    components: {
        HoxAlert,
        HoxModal,
        DownloadDropdownButton
    },
    mixins: [editableMethodsMixin],
    props: {
        deliverables: {
            required: true,
            type: Array
        },
        deliverableIdentifiers: {
            type: Array
        },
        showHtmlOptions: {
            required: true,
            type: Boolean
        },
        showPsdOptions: {
            required: true,
            type: Boolean
        },
        libraryFiltersForQuery: {
            required: false,
            type: Object
        }
    },

    data() {
        return {
            captureAudio: true,
            highQuality: false,
            emailAddresses: [],
            firstDurationChange: true,
            manualDuration: false,
            manualDurationValue: "",
            ftClickTagCount: true,
            ftClickTagCountValue: 1,
            ftBrowserExclusions: true,
            ftBrowserExclusionsValue: "ie8, ie9, ie10, ie11, opera",
            fpsOptions: [],
            manualFps: false,
            manualFpsValue: 60,
            droppedFramesOptimisation: false,
            useEdgeChromiumVersion: false,
            optimiseFontFiles: true,
            optimiseImages: true,
            optimiseTextFiles: true,
            platform: "",
            previewUrlsByIdHash: {}
        };
    },
    computed: {
        alertType() {
            return this.isProfileIdValid ? "success" : "warning";
        },

        canDownloadDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanDownloadDeliverable, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        dcProfileId: {
            get() {
                return this.$store.state.campaign.dcProfileId ? this.$store.state.campaign.dcProfileId.toString() : "";
            },
            set(dcProfileId) {
                this.$store.dispatch(CampaignAction.SetDcProfileId, dcProfileId);
            }
        },

        hasProfileId() {
            return !!this.dcProfileId;
        },

        isDownloadDisabled() {
            return (this.requiresProfileId && !this.isProfileIdValid) || this.platform === "";
        },

        isFlashtalking() {
            return this.platform === Platform.Flashtalking;
        },

        isProfileIdValid() {
            return this.hasProfileId && this.dcProfileId.match(/^\d+$/);
        },

        isVideoDownload() {
            return this.platform === BatchDownloads.Video;
        },

        isZipDownload() {
            return Object.values(ZipDownloads).includes(this.platform);
        },

        platformOpts() {
            return {
                ...(this.isZipDownload && {
                    optimiseFontFiles: this.optimiseFontFiles,
                    optimiseImages: this.optimiseImages,
                    optimiseTextFiles: this.optimiseTextFiles,
                    ...(this.isFlashtalking && this.ftPlatformOpts)
                }),
                ...(this.isVideoDownload && {
                    captureAudio: this.captureAudio,
                    highQuality: this.highQuality,
                    droppedFramesOptimisation: this.droppedFramesOptimisation,
                    useEdgeChromiumVersion: this.useEdgeChromiumVersion,
                    ...(this.manualDuration && { duration: Number.parseFloat(this.manualDurationValue) }),
                    ...(this.manualFps && { fps: Number.parseFloat(this.manualFpsValue) })
                }),
                ...(this.requiresProfileId && { dcProfileId: parseInt(this.dcProfileId, 10) })
            };
        },

        ftPlatformOpts() {
            return {
                ...(this.ftClickTagCount && { clickTagCount: parseInt(this.ftClickTagCountValue, 10) }),
                ...(this.ftBrowserExclusions && {
                    browserExclusion: this.ftBrowserExclusionsValue.split(",").map(s => s.trim())
                })
            };
        },

        requiresProfileId() {
            return this.platform === Platform.DoubleClickSV;
        }
    },
    watch: {
        canDownloadDeliverable() {
            if (!this.canDownloadDeliverable) {
                this.$snackbar.warning("You do not have access to download deliverables");
                this.close();
            }
        },

        platform(newVal) {
            if (newVal === Platform.Flashtalking) {
                this.optimiseFontFiles = false;
                this.optimiseImages = false;
                this.optimiseTextFiles = false;
            } else {
                this.optimiseFontFiles = true;
                this.optimiseImages = true;
                this.optimiseTextFiles = true;
            }
        }
    },
    created() {
        this.deliverablesService = new DeliverablesService(this.$apollo);
        this.fpsOptions = [24, 25, 30, 60];
    },
    methods: {
        addDeliverableDownloadFailureToNotification(notificationId, deliverable) {
            const notification = this.$store.getters[NotificationsGetter.GetItemById](notificationId);
            this.$store.dispatch(NotificationsAction.Update, {
                ...notification,
                deliverablesThatFailedToDownload: [...notification.deliverablesThatFailedToDownload, deliverable]
            });
        },
        addDeliverableWithDownloadLinkToNotification(notificationId, deliverable) {
            const notification = this.$store.getters[NotificationsGetter.GetItemById](notificationId);
            this.$store.dispatch(NotificationsAction.Update, {
                ...notification,
                deliverablesWithDownloadLink: [...notification.deliverablesWithDownloadLink, deliverable]
            });
        },
        close() {
            this.$emit("close");
        },

        onDurationChange() {
            if (this.firstDurationChange && this.manualDurationValue && !this.manualDuration) {
                this.manualDuration = true;
                this.firstDurationChange = false;
            }
        },

        onBrowserExclusionChange() {
            if (!this.ftBrowserExclusions) {
                this.ftBrowserExclusions = true;
            }
        },

        onClickTagCountChange() {
            if (!this.ftClickTagCount) {
                this.ftClickTagCount = true;
            }
        },

        onFpsChange() {
            if (!this.manualDuration) {
                this.manualFps = true;
            }
        },

        async processDownloads() {
            this.close();
            try {
                const job = await this.deliverablesService.downloadJob(
                    this.campaignId,
                    this.platform,
                    this.deliverables,
                    this.deliverableIdentifiers,
                    this.libraryFiltersForQuery,
                    this.platformOpts
                );
                const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                    message: job.message,
                    status: JobStatusToNotificationStatus[job.status],
                    type: NotificationTypes.DownloadJob,
                    job
                });
                this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                    jobId: job._id,
                    notificationId
                });
                this.$snackbar.info(
                    "Your ads are being prepared for download. Take a look at your notifications to check on progress"
                );
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error while preparing your ads for download. Please try again in a few moments"
                );
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.download-modal__button {
    margin: 0 0 0 $spacing-small;
}

.download-modal__select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 $spacing;
}

.download-modal__select-platform {
    flex: 1 0;
    width: 100%;
    margin-left: 20px;
}

.download-modal__dc-profile-input {
    margin-bottom: $spacing-small;

    &--error {
        border: 1px solid $error-color;

        .ivu-input:focus,
        .ivu-input:hover {
            border-color: $error-color;
        }
    }

    &-error-message {
        color: $error-color;
    }
}

.download-modal__options-wrapper {
    h4 {
        display: inline-block;
    }

    ul {
        list-style: none;

        li {
            margin-bottom: $spacing-semi-small;
        }
    }

    .ivu-checkbox {
        margin-right: $spacing-small;
    }

    p {
        font-size: $font-size-smaller;
        margin-left: 26px;
    }

    .download-modal__short-input {
        &-value {
            width: 78px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }

    .download-modal__long-input {
        &-value {
            width: 220px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }
}
</style>
