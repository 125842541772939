/*
    This width should be large enough to ensure that the size of the banner
    wrapper does not shrink to a point which would cause the buttons to wrap or
    make it so that the title text is not visible.
*/

const minBannerWidthPixels = 120;
/*
 * Requires this.masterTemplate to point at the correct object containing template dimensions
 */

export default {
    props: {
        maxHeight: {
            type: Number
        },

        maxWidth: {
            type: Number
        },

        scalingFactor: {
            type: [Number, String]
        },

        showResizeHeader: {
            type: Boolean,
            default: true
        },

        resizable: {
            type: Boolean,
            default: false
        },

        inputWidth: {
            type: Number,
            default: 0
        },

        inputHeight: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            scalingIsEnabled: true,
            internalContainerStyle: null,
            isResizable: false,
            wasScalingEnabled: false
        };
    },

    computed: {
        bannerContainerStyle() {
            if (this.isResizable) {
                return {
                    minHeight: "0",
                    minWidth: "0",
                    width: "100%",
                    height: "100%"
                };
            }

            return {
                minHeight: `${this.masterTemplate.height * this.internalScalingFactor}px`,
                minWidth: `${this.masterTemplate.width * this.internalScalingFactor}px`
            };
        },

        containerStyle() {
            if (this.isResizable) {
                return {
                    minWidth: "0",
                    maxWidth: "4096px",
                    ...(this.inputWidth && { width: `${this.inputWidth}px` }),
                    ...(this.inputHeight && { height: `${this.inputHeight}px` })
                };
            }
            return {
                minWidth: `${this.containerWidthPixels}px`,
                maxWidth: `${Math.max(this.masterTemplate.width * this.internalScalingFactor, minBannerWidthPixels)}px`
            };
        },

        containerWidthPixels() {
            return Math.min(this.masterTemplate.width * this.internalScalingFactor, minBannerWidthPixels);
        },

        hasResizeHeader() {
            return (!this.isResizable && this.showScalingControls) || (this.isResizable && this.showResizeHeader);
        },

        internalScalingFactor() {
            if (this.scalingIsEnabled) {
                if (this.scalingFactor === "scale") {
                    /*
                        To ensure that the banner is displayed fully in the viewport, as opposed to
                        being clipped slightly, we use `heightOffsetPixels` to account for the height
                        of any padding and the height of the UI elements wrapping the banner.
                    */
                    const heightOffsetPixels = 130;
                    /*
                        At the moment we only scale down, not up, so we never want
                        to have a value greater than 1.
                    */
                    return Math.min(
                        1,
                        (this.maxHeight - heightOffsetPixels) / this.masterTemplate.height,
                        this.maxWidth / this.masterTemplate.width
                    );
                }
                return this.scalingFactor;
            }
            return 1;
        },

        showScalingControls() {
            return !this.scalingIsEnabled || this.internalScalingFactor !== 1;
        }
    },

    watch: {
        containerStyle: {
            immediate: true,
            handler(style) {
                this.internalContainerStyle = style;
            }
        },
        resizable: {
            immediate: true,
            handler(val) {
                this.isResizable = val;
            }
        }
    },

    methods: {
        onResized(sizes) {
            this.$emit("resized", sizes);
            // height reported by campaign banner includes the height of the banner footer that we need to subtract here
            this.resizeDimensions = `(${sizes.width} x ${sizes.height - 40})`;
        },

        toggleScalingIsEnabled() {
            this.scalingIsEnabled = !this.scalingIsEnabled;
            this.$emit("scaling-is-enabled", this.scalingIsEnabled);
        },

        async toggleResizeMode() {
            this.isResizable = !this.isResizable;
            this.$emit("resized", this.isResizable);

            if (this.isResizable) {
                this.wasScalingEnabled = this.internalScalingFactor !== 1;
                this.scalingIsEnabled = false;
                await this.$nextTick();
                this.internalContainerStyle = {
                    ...this.containerStyle,
                    width: `${this.masterTemplate.width}px`,
                    height: `${this.masterTemplate.height + 80}px`
                };
            } else {
                this.scalingIsEnabled = !this.wasScalingEnabled;
            }
        }
    }
};
