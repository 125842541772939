import { NotificationStatus } from "@/enums/notifications";

const storeNamespace = "notifications";

export const NotificationsAction = {
    Add: `${storeNamespace}/addItem`,
    AddDownloadLinkToNotification: `${storeNamespace}/addDownloadLinkToNotification`,
    ClearItemsNotPendingOrInProgress: `${storeNamespace}/clearItemsNotPendingOrInProgress`,
    RemoveItem: `${storeNamespace}/removeItem`,
    SetDrawerIsVisible: `${storeNamespace}/setDrawerIsVisible`,
    Update: `${storeNamespace}/updateItem`
};

export const NotificationsGetter = {
    GetItemById: `${storeNamespace}/getItemById`
};

export const NotificationsMutation = {
    Add: "addItem",
    AddDownloadLinkToNotification: "addDownloadLinkToNotification",
    ClearItemsNotPendingOrInProgress: "clearItemsNotPendingOrInProgress",
    IncrementIdCounter: "incrementIdCounter",
    RemoveItem: "removeItem",
    SetDrawerIsVisible: "setDrawerIsVisible",
    Update: "updateItem"
};

export default {
    namespaced: true,

    state: {
        drawerIsVisible: false,
        idCounter: 0,
        items: []
    },

    getters: {
        getItemById(state) {
            return itemId => state.items.find(item => item.id === itemId);
        }
    },

    mutations: {
        addItem: (state, payload) => {
            state.items.unshift(payload);
        },

        clearItemsNotPendingOrInProgress(state) {
            state.items = state.items.filter(
                item => item.status === NotificationStatus.Pending || item.status === NotificationStatus.InProgress
            );
        },

        incrementIdCounter(state) {
            state.idCounter += 1;
        },

        removeItem(state, itemId) {
            state.items = state.items.filter(item => item.id !== itemId);
        },

        setDrawerIsVisible(state, drawerIsVisible) {
            state.drawerIsVisible = drawerIsVisible;
        },

        updateItem(state, updatedNotification) {
            state.items = state.items.map(item => {
                if (item.id === updatedNotification.id) {
                    return {
                        ...item,
                        ...updatedNotification
                    };
                }
                return item;
            });
        }
    },

    actions: {
        addDownloadLinkToNotification({ commit }, downloadLink) {
            commit(NotificationsMutation.AddDownloadLinkToNotification, downloadLink);
        },

        addItem({ commit, state }, notification) {
            commit(NotificationsMutation.IncrementIdCounter);
            commit(NotificationsMutation.Add, {
                ...notification,
                id: state.idCounter
            });
            return state.idCounter;
        },

        clearItemsNotPendingOrInProgress({ commit }) {
            commit(NotificationsMutation.ClearItemsNotPendingOrInProgress);
        },

        removeItem({ commit }, itemId) {
            commit(NotificationsMutation.RemoveItem, itemId);
        },

        setDrawerIsVisible({ commit }, drawerIsVisible) {
            commit(NotificationsMutation.SetDrawerIsVisible, drawerIsVisible);
        },

        updateItem({ commit }, updatedNotification) {
            commit(NotificationsMutation.Update, updatedNotification);
        }
    }
};
