import assert from "assert";
import { isObject, uniqBy } from "lodash";
import jwtDecode from "jwt-decode";
import stripNonPrintableUnicodeCharacters from "./utils/stripNonPrintableUnicodeCharacters";
import slugify from "./utils/slugify";
import slugifyFileName from "./utils/slugifyFileName";
import slugifyFilePath from "./utils/slugifyFilePath";
import getTime from "./utils/getTime";
import getDirPath from "./utils/getDirPath";
import isMediaType from "./utils/isMediaType";
import parseJson from "./utils/parseJson";
import sleep from "./utils/sleep";
import validateEmail from "./utils/validateEmail";
import metadataToInputMetadata from "./utils/metadataToInputMetadata";
import generateErrorResponse from "./utils/generateErrorResponse";
import handleErrors from "./utils/handleErrors";
import generateKeyValueObject from "./utils/generateKeyValueObject";
import { InvalidArgumentError, MissingArgumentError } from "./errors";
import nl2br from "./utils/nl2br";
import fsCanAccess from "./utils/fsCanAccess";
import chunkArray from "./utils/chunkArray";
import parseHoxtonManifest from "./utils/parseHoxtonManifest";
import isAbsoluteUrl from "./utils/isAbsoluteUrl";
import fixDecimalPlaces from "./utils/fixDecimalPlaces";
import sum from "./utils/sum";
import median from "./utils/median";
import { unique } from "./utils/unique";

const getTimeMs = () => Date.now();

const RegionTypeEnum = {
    iframe: "iframe",
    video: "video",
    image: "image",
    text: "text",
    folder: "folder",
    boolean: "boolean",
    subtitles: "subtitles",
    array: "array",
    css: "css"
};

/**
 * Returns normalized region type ( based on region.type only )
 * @param region
 * @returns {String}
 */
const getNormalizedRegionType = regionType => {
    if (Object.keys(RegionTypeEnum).includes(regionType)) {
        return regionType;
    } else if (regionType === "url") {
        return RegionTypeEnum.image;
    } else if (["string", "copy", "html", "cta", "textarea"].includes(regionType)) {
        return RegionTypeEnum.text;
    }
    return "unknown";
};

const getSizmekSmartItems = (hoxtonManifestOrRegions, includeGroup = false) => {
    // eslint-disable-next-line complexity
    const buildSmartItemObject = (key, region) => {
        if (!key || !region || !region.type) {
            return null;
        }
        const normalizedRegionType = getNormalizedRegionType(region.type);
        const svObject = {
            svKey: key,
            svType: "string",
            value: region.value,
            ...(region.settings && { settings: region.settings }),
            ...(includeGroup && { groupName: region.groupName, groupValue: region.groupValue })
        };
        switch (normalizedRegionType) {
            case RegionTypeEnum.text:
            case RegionTypeEnum.css:
            case RegionTypeEnum.folder:
            case RegionTypeEnum.boolean:
            case RegionTypeEnum.iframe:
            case RegionTypeEnum.subtitles:
                return svObject;
            case RegionTypeEnum.image:
                return { ...svObject, svType: "image" };
            case RegionTypeEnum.video:
                return { ...svObject, svType: "video" };
            case RegionTypeEnum.array: {
                return { ...svObject, value: region.value.find(x => x.selected).value };
            }
            default:
                return null;
        }
    };
    let regions;
    if (Array.isArray(hoxtonManifestOrRegions)) {
        regions = hoxtonManifestOrRegions.filter(x => x.name && x.type && (x.value || x.value === "")); // keep only valid regions
    }
    if (!regions) {
        regions = Object.keys(hoxtonManifestOrRegions).reduce((acc, key) => {
            const hoxtonObject = hoxtonManifestOrRegions[key];
            // keep only valid regions
            if (key && hoxtonObject.type && (hoxtonObject.value || hoxtonObject.value === "")) {
                acc.push({
                    name: key,
                    type: hoxtonObject.type,
                    value: hoxtonObject.value,
                    settings: hoxtonObject.settings
                });
            }
            return acc;
        }, []);
    }
    assert(
        regions && regions.length,
        new InvalidArgumentError("Cannot get the sizmek smart items. Invalid hoxtonManifestOrRegions provided")
    );
    const smartItemObjects = regions.reduce((accumulator, manifestObject) => {
        if (!isObject(manifestObject) && manifestObject.type) {
            return accumulator;
        }
        const smartItemObject = buildSmartItemObject(manifestObject.name, manifestObject);
        if (smartItemObject == null) {
            return accumulator;
        }
        accumulator.push(smartItemObject);
        return accumulator;
    }, []);
    return smartItemObjects;
};

const buildSizmekConfigFile = hoxtonManifest => {
    const smartItemObjects = getSizmekSmartItems(hoxtonManifest);
    const result = `define({"SV":{"svData":${JSON.stringify(smartItemObjects)}}});`;
    return result;
};

const uniqMongoIds = mongoIds => uniqBy(mongoIds, mongoId => mongoId.toString());

const buildPreviewUrl = templatePath => `/${templatePath}`;

const rewriteHogarthDomain = email => {
    // replace only the emails ending with @hogarthww.com to handle the .com.au kind of situations
    return email.replace(/@hogarthww.com$/, "@hogarth.com");
};

const handshakeAuthorization = (event = {}) => {
    const { headers = {}, requestContext = {} } = event;
    if (requestContext.authorizer || !headers.Authorization) {
        if (requestContext?.authorizer?.claims?.email) {
            requestContext.authorizer.claims.email = rewriteHogarthDomain(requestContext.authorizer.claims.email);
        }
        return event;
    }

    try {
        const decoded = jwtDecode(headers.Authorization);
        if (decoded.email) {
            decoded.email = rewriteHogarthDomain(decoded.email);
        }
        Object.assign(event.requestContext, { authorizer: { claims: decoded } });
    } catch (err) {
        console.error(err);
    }

    return event;
};

const apiErrorResponse = (err, statusCode = 200) => {
    const response = generateErrorResponse(err);

    response.headers = response.headers || {};
    response.headers["content-type"] = "application/json";
    response.headers["Access-Control-Allow-Origin"] = "*";
    if (parseInt(statusCode, 10)) {
        response.statusCode = statusCode;
    }

    return response;
};

/**
 * Ensures required properties are set on the even to stop crashes
 *
 * @param {LambdaEvent} event the event that caused the lambda to be invoked
 */
const safeEvent = (event, context) => {
    const newEvent = { ...event };
    if (!newEvent.headers) {
        newEvent.headers = {
            // after apollo-server-lambda upgrade, content-type should be provided with lower cases
            "content-type": "application/json"
        };
    }
    if (!newEvent.requestContext) {
        // after apollo-server-lambda upgrade, the requestContext is required
        newEvent.requestContext = context;
    }
    if (!newEvent.path) {
        // after apollo-server-lambda upgrade, the path is required
        newEvent.path = "/api";
    }
    return newEvent;
};

const logError = err => {
    console.error(err);
};

const getSort = (args, defaultOrder = "ASC") => {
    if (!args.orderBy) {
        return null;
    }

    const parts = args.orderBy.split("_");
    const order = parts.length > 1 ? parts.pop() : defaultOrder;
    const key = parts.join("_");

    return { [key]: order === "ASC" ? 1 : -1 };
};

/**
 * Used to set consistent properties when documents are created, modified or removed
 *
 * @param {Object} user - the user making the change, must have an id
 * @param {Int} time - when the change is made
 * @param {String} mode - what is being done, can be: create, remove, modify
 */
const getAuditProperties = ({ id, displayingName } = {}, mode = "modify", time = getTimeMs()) => {
    assert(id, new MissingArgumentError("user.id"));
    const subject = {
        id,
        name: displayingName
    };
    const audit = {};

    switch (mode) {
        case "remove":
            return {
                removed: time,
                removedBy: subject
            };
        case "create":
            audit.created = time;
            audit.createdBy = subject;
        // falls through - create will always set modified as well
        default:
            audit.modified = time;
            audit.modifiedBy = subject;
            return audit;
    }
};

/**
 * Compares two arrays for equality
 * @param {Array} arr1
 * @param {Array} arr2
 * @param {Boolean} inOrder - if true then will only consider the arrays equal if they are in the same order
 */
const arrayEqual = (arr1, arr2) => {
    if (arr1 === arr2) {
        return true;
    }
    if (!arr1 || !arr2) {
        return false;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }
    const compare1 = arr1.concat().sort();
    const compare2 = arr2.concat().sort();

    return compare1.every((item, index) => compare2[index] === item);
};

/**
 * Used to log requests for Hoxton MicroServices (HMS)
 * We use logs to keep Hoxton GraphQL API decoupled from the HMS Architecture
 *
 * @param {Object} payload The payload for the request
 */
const hmsRequest = payload => {
    assert(payload.service, new MissingArgumentError("payload.service"));
    assert(payload.jobId, new MissingArgumentError("payload.jobId"));
    console.log(
        JSON.stringify({
            type: "HMS_REQUEST",
            stack: process.env.CLIENT_NAME,
            ...payload
        })
    );
};

export {
    getTime,
    getTimeMs,
    RegionTypeEnum,
    getNormalizedRegionType,
    getSizmekSmartItems,
    buildSizmekConfigFile,
    stripNonPrintableUnicodeCharacters,
    slugify,
    slugifyFileName,
    slugifyFilePath,
    getDirPath,
    uniqMongoIds,
    buildPreviewUrl,
    handshakeAuthorization,
    apiErrorResponse,
    logError,
    isMediaType,
    getSort,
    isAbsoluteUrl,
    parseJson,
    validateEmail,
    getAuditProperties,
    arrayEqual,
    hmsRequest,
    sleep,
    metadataToInputMetadata,
    generateErrorResponse,
    handleErrors,
    generateKeyValueObject,
    nl2br,
    fsCanAccess,
    chunkArray,
    parseHoxtonManifest,
    safeEvent,
    fixDecimalPlaces,
    sum,
    median,
    unique,
    rewriteHogarthDomain
};
