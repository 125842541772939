<!-- eslint-disable vue/no-mutating-props -->
<template>
    <hox-modal v-if="isVisible" @close="close">
        <template #header>
            {{ actionLabel }}
        </template>
        <div class="account-rule-modal__wrap">
            <Form
                ref="addAccountForm"
                class="account-rule__form"
                :rules="baseValidationRules"
                :model="formData"
                @submit.native.prevent="onSubmitAccountRule"
            >
                <FormItem ref="ruleName" label="Rule name" prop="ruleName">
                    <Input
                        v-model="formData.ruleName"
                        auto-complete="off"
                        data-testid="add-platform-account__name"
                    ></Input>
                </FormItem>
                <FormItem ref="adSetId" label="Adset Id" prop="adSetId">
                    <Input
                        v-model="formData.adSetId"
                        auto-complete="off"
                        data-testid="add-platform-account__adset-id"
                    ></Input>
                </FormItem>
                <Button type="primary" data-testid="new-platform-account__modal-button" @click="onSubmitAccountRule">
                    {{ actionLabel }}
                </Button>
            </Form>
        </div>
    </hox-modal>
</template>
<script>
import { checkEmptySpaces, checkOnlyNumbers } from "@/utils";

export default {
    name: "AccountRuleFormModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        ruleAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            baseValidationRules: {
                ruleName: [
                    {
                        required: true,
                        message: "Please enter an rule name",
                        trigger: "blur"
                    },
                    {
                        message: "Rule name must not start with space",
                        validator: (rule, value) => checkEmptySpaces(value),
                        trigger: "blur"
                    }
                ],
                adSetId: [
                    {
                        required: true,
                        message: "Please enter an Adset Id.",
                        trigger: "blur"
                    },
                    {
                        message: "Adset Id must contain only numbers",
                        validator: (rule, value) => checkOnlyNumbers(value),
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    computed: {
        actionLabel() {
            if (this.ruleAction === "edit") {
                return "Edit rule";
            }
            return "Add rule";
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        onSubmitAccountRule() {
            this.$refs.addAccountForm.validate(valid => {
                if (!valid) {
                    this.$snackbar.error("Please correctly fill in the required fields");
                    return;
                }

                this.$snackbar.info(`Account rule ${this.ruleAction === "add" ? "added" : "edited"}`);
                this.$emit("accountRuleAction", {
                    formData: this.formData,
                    accountIndex: this.accountIndex,
                    ruleIndex: this.ruleIndex,
                    ruleAction: this.ruleAction
                });
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.account-rule {
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
    }

    &__info {
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
}
</style>
