<template>
    <div class="campaign-light__content-header library-header">
        <section-nav />
        <div class="campaign-light__content-header-items">
            <div class="library-header__select-all">
                <Checkbox
                    :disabled="paginatedDeliverables.length === 0 || selectAllDisabled"
                    :indeterminate="isSelectionIndeterminate"
                    :value="allPageDeliverablesAreSelected"
                    data-testid="library-header__select-all-checkbox"
                    @click.prevent.native="toggleDeliverableSelection"
                >
                    Select all
                </Checkbox>

                <span class="library-header__selected-count">{{ numberOfSelectedDeliverables }} Selected</span>
                <span v-if="canDownloadOrPublish" class="library-header__selected-count">
                    <Button icon="ios-cloud-upload-outline" size="small" @click="selectViaFile" />
                </span>
            </div>
            <div class="library-header__buttons">
                <div class="campaign-light__content-header-group">
                    <div
                        v-if="$auth.userCan($auth.Actions.CanPublishDeliverableToAdServer, { clientId, campaignId })"
                        class="campaign-light__content-header-item"
                    >
                        <publish-dropdown-button
                            :deliverables="selectedDeliverables"
                            :library-filters-for-query="computedLibraryFiltersForQuery"
                            :item-to-change-number="numberOfSelectedDeliverables"
                            :is-disabled="selectedDeliverables.length === 0 || showPsdOptions"
                            @publishedStatusHasBeenSet="emitBulkPublishedStatusHasBeenSet"
                            @showPublishModal="emitShowPublishModal"
                        />
                    </div>
                    <div
                        v-if="$auth.userCan($auth.Actions.CanChangeDeliverableVisibility, { clientId, campaignId })"
                        class="campaign-light__content-header-item"
                    >
                        <set-deliverables-visible-to-dropdown
                            :deliverables="selectedDeliverables"
                            :library-filters-for-query="computedLibraryFiltersForQuery"
                            :item-to-change-number="numberOfSelectedDeliverables"
                        />
                    </div>
                    <div
                        v-if="$auth.userCan($auth.Actions.CanChangeDeliverableQAStatus, { clientId, campaignId })"
                        class="campaign-light__content-header-item"
                    >
                        <set-deliverables-status-dropdown
                            :deliverables="selectedDeliverables"
                            :library-filters-for-query="computedLibraryFiltersForQuery"
                            :item-to-change-number="numberOfSelectedDeliverables"
                            @statusHasBeenSet="emitBulkQaStatusHasBeenSet"
                        />
                    </div>
                </div>

                <div class="campaign-light__content-header-group">
                    <div class="campaign-light__content-header-item">
                        <Button
                            v-if="$auth.userCan($auth.Actions.CanShareDeliverable, { clientId, campaignId })"
                            :disabled="!hasDeliverablesSelected"
                            icon="ios-link"
                            data-testid="library-header__share-button"
                            @click="showSharePreviewModal"
                        >
                            Share
                        </Button>
                    </div>

                    <div class="campaign-light__content-header-item">
                        <Button
                            :type="bulkApprovaButtonType"
                            :disabled="!hasDeliverablesSelected"
                            icon="ios-text-outline"
                            data-testid="library-header__review-button"
                            @click="showBulkApproval"
                        >
                            Review
                        </Button>
                    </div>
                    <div
                        v-if="$auth.userCan($auth.Actions.CanDownloadDeliverable, { clientId, campaignId })"
                        class="campaign-light__content-header-item"
                    >
                        <Button
                            :disabled="!hasDeliverablesSelected"
                            icon="md-download"
                            data-testid="library-header__download-button"
                            @click="showDownloadModal"
                        >
                            Download
                        </Button>
                    </div>
                </div>
                <div v-if="isTableView" class="campaign-light__content-header-item">
                    <customise-table-columns-dropdown />
                </div>
                <div class="campaign-light__content-header-item">
                    <RadioGroup :value="viewMode" type="button" @on-change="setViewMode">
                        <Radio :label="ViewMode.Grid">
                            <Icon type="md-apps" />
                        </Radio>
                        <Radio :label="ViewMode.Table">
                            <Icon type="md-list" />
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomiseTableColumnsDropdown from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableCustomiseColumnsDropdown";
import PublishDropdownButton from "@/components/Campaign/Publish/PublishDropdownButton";
import SetDeliverablesStatusDropdown from "@/components/Campaign/SetDeliverablesStatusDropdown";
import SetDeliverablesVisibleToDropdown from "@/components/Campaign/SetDeliverablesVisibleToDropdown";
import SectionNav from "@/components/SectionNav";
import { ViewMode } from "@/store/modules/deliverableLibrary";

export default {
    name: "LibraryHeader",
    components: {
        CustomiseTableColumnsDropdown,
        PublishDropdownButton,
        SetDeliverablesStatusDropdown,
        SetDeliverablesVisibleToDropdown,
        SectionNav
    },

    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        paginatedDeliverables: {
            required: true,
            type: Array
        },
        selectAllDisabled: {
            type: Boolean
        },
        selectedDeliverables: {
            required: true,
            type: Array
        },
        viewMode: {
            required: true,
            type: String
        },
        libraryFiltersForQuery: {
            required: true,
            type: Object
        },
        totalItems: {
            required: true,
            type: Number
        },
        showPsdOptions: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            customiseColumnsDropdownIsVisible: false
        };
    },
    computed: {
        allPageDeliverablesAreSelected() {
            return (
                this.hasDeliverablesSelected && this.paginatedDeliverables.length === this.selectedDeliverables.length
            );
        },

        bulkApprovaButtonType() {
            return this.$auth.userCan(this.$auth.Actions.CanChangeDeliverableQAStatus, {
                clientId: this.clientId,
                campaignId: this.campaignId
            })
                ? "default"
                : "primary";
        },

        canDownloadOrPublish() {
            return (
                this.$auth.userCan(this.$auth.Actions.CanDownloadDeliverable, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                }) ||
                this.$auth.userCan(this.$auth.Actions.CanPublishDeliverableToAdServer, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        computedLibraryFiltersForQuery() {
            return (this.allSelectedMode && this.libraryFiltersForQuery) || {};
        },

        hasDeliverablesSelected() {
            return this.selectedDeliverables.length > 0;
        },

        isSelectionIndeterminate() {
            return this.hasDeliverablesSelected && !this.allSelectedMode && !this.allPageDeliverablesAreSelected;
        },

        isTableView() {
            return this.viewMode === ViewMode.Table;
        },

        numberOfSelectedDeliverables() {
            if (this.allSelectedMode) {
                return this.totalItems;
            }

            return this.selectedDeliverables.length;
        }
    },

    created() {
        this.ViewMode = ViewMode;
    },

    methods: {
        emitBulkPublishedStatusHasBeenSet() {
            this.$emit("bulkPublishedStatusHasBeenSet");
        },

        emitBulkQaStatusHasBeenSet() {
            this.$emit("bulkQaStatusHasBeenSet");
        },

        emitShowPublishModal(platformAction) {
            this.$emit("showPublishModal", platformAction);
        },

        setViewMode(viewMode) {
            this.$emit("viewModeSet", viewMode);
        },

        showBulkApproval() {
            this.$emit("showBulkApproval");
        },

        showSharePreviewModal() {
            this.$emit("showSharePreviewModal");
        },

        toggleDeliverableSelection() {
            if (!this.selectAllDisabled) {
                if (this.allSelectedMode) {
                    this.$emit("selectedDeliverablesUpdated", []);
                } else if (!this.hasDeliverablesSelected || this.isSelectionIndeterminate) {
                    this.$emit(
                        "selectedDeliverablesUpdated",
                        this.paginatedDeliverables.map(({ idHash }) => idHash)
                    );
                } else {
                    this.$emit("selectedDeliverablesUpdated", []);
                }
            }
        },

        showDownloadModal() {
            this.$emit("showDownloadModal");
        },

        selectViaFile() {
            this.$emit("selectViaFile");
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.library-header__buttons {
    align-items: center;
    display: flex;
    height: 100%;
}

.library-header__group {
    align-items: center;
    display: flex;
    border-right: 1px solid $grey3;
    height: 100%;
    margin-right: 16px;
    padding-right: 20px;
}

.library-header__select-all {
    white-space: nowrap;
    margin-right: $spacing;
}

.library-header__selected-count {
    border-left: 1px solid $grey3;
    font-size: $font-size-base;
    padding: 0 0 0 $spacing-small;
}
</style>
