<template>
    <div>
        <Poptip trigger="hover">
            <Icon type="md-information-circle" />
            <template #content>
                <slot name="poptipContent">
                    <ul>
                        <li
                            v-for="(infoSummary, index) in platformAccount.publishSummary"
                            :key="index"
                            class="publish-status__info"
                        >
                            <span class="publish-status__info-name">
                                {{ infoSummary.key }}
                            </span>
                            <span>{{ infoSummary.value }}</span>
                        </li>
                    </ul>

                    <Button
                        v-if="platformAccount.publishStatus !== PublishStatus.DONE"
                        class="publish-status__detailed-button"
                        :disabled="isDetailedPublishingStatusUpdating"
                        @click="toggleDetailedPublishStatus"
                    >
                        <hox-loading-spinner v-if="isDetailedPublishingStatusUpdating" :size="30" />
                        Show more details
                    </Button>
                </slot>
            </template>
        </Poptip>
        <publishing-details-modal
            :is-visible="isDetailedPublishedStatusModalVisible"
            :publish-details="detailedPublishDetails"
            @close="closeDetailedPublishStatusModal"
        />
    </div>
</template>

<script>
import PublishingDetailsModal from "@/components/IntelligentDelivery/PublishingDetailsModal";
import getIntelligentDeliveryApiInfoQuery from "@/apollo/queries/v2/GetIntelligentDeliveryApiInfo.gql";
import { fetchRequest } from "@/components/Reporting/data/utils";
import { PublishStatus } from "@/enums/activate";

export default {
    components: {
        PublishingDetailsModal
    },
    props: {
        platformAccount: {
            required: true,
            type: Object
        },
        campaignId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            PublishStatus,
            detailedPublishDetails: "",
            isDetailedPublishingStatusUpdating: false,
            isDetailedPublishedStatusModalVisible: false
        };
    },
    methods: {
        async toggleDetailedPublishStatus() {
            this.isDetailedPublishingStatusUpdating = true;
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/publish-rules/campaigns/${this.campaignId}/accounts/${this.platformAccount.id}/updates/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    this.isLoading = false;
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data[0].error.message) {
                        this.isDetailedPublishedStatusModalVisible = true;
                        this.isDetailedPublishingStatusUpdating = false;
                        this.detailedPublishDetails = responseValidateJson.data[0].error.message;
                    }
                } else {
                    const responseValidateJson = await response.json();
                    const responseMessage = responseValidateJson.message
                        ? responseValidateJson.message
                        : "There was an error please try again.";
                    this.$snackbar.error(responseMessage);
                    this.isLoading = false;
                }
            } catch (e) {
                this.isDetailedPublishingStatusUpdating = false;
                console.log(e);
            }
        },

        closeDetailedPublishStatusModal() {
            this.isDetailedPublishedStatusModalVisible = false;
        }
    },

    apollo: {
        intelligentDeliveryApiInfo: {
            query: getIntelligentDeliveryApiInfoQuery,
            result({ data }) {
                this.apiInfo = data.intelligentDeliveryApiInfo;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.intelligent-delivery {
    &__account {
        &-status {
            display: flex;
            padding: 0 10px;
            font-weight: 400;
            font-size: 16px;
            td {
                padding: 0 5px;
            }
        }
    }
    &__dashboard {
        .publish-status {
            &__info {
                font-weight: 300;
                display: flex;
                margin-left: 10px;
                span {
                    display: block;
                }
                &-name {
                    margin-right: auto;
                    padding-right: 20px;
                }
            }
            &__detailed-button {
                margin-top: 10px;
                height: 40px;
                > span {
                    display: flex;
                    align-items: center;
                }
                .loading-spinner {
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>
