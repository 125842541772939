<template>
    <hox-modal v-if="!isPermissionGranted">
        <hox-alert margin-top="base" type="danger" @close="redirectToClients">
            <template #content>
                <p>It looks like you do not have the right permissions to see the library for this campaign.</p>
                <p>Ask your admin to grant you access and try again.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="redirectToClients">Got it</Button>
            </template>
        </hox-alert>
    </hox-modal>
    <div v-else class="campaign-library__wrapper">
        <deliverable-drawer
            v-if="drawerIsVisible && !isLoading"
            :all-selected-mode="areAllDeliverablesSelected"
            :deliverables="drawerDeliverables"
            :library-filters-for-query="libraryFiltersForQuery"
            @closed="onDrawerClosed"
            @statusHasBeenUpdated="onDeliverableStatusUpdate"
            @userStatusHasBeenUpdated="onDeliverableUserStatusUpdate"
            @annotationsHasBeenUpdated="onDeliverableCommentsUpdate"
        />

        <share-preview-modal
            v-if="sharePreviewModalIsVisible"
            :campaign-id="campaignId"
            :deliverables="selectedDeliverables"
            @close="toggleSharePreviewModal"
        />

        <download-modal
            v-if="downloadModalIsVisible"
            :deliverables="selectedDeliverables"
            :deliverable-identifiers="selectedDeliverableIdentifiers"
            :library-filters-for-query="(areAllDeliverablesSelected && libraryFiltersForQuery) || {}"
            :show-html-options="htmlTemplateSelected"
            :show-psd-options="psdTemplateSelected"
            @close="toggleDownloadModal"
        />
        <publish-modal
            v-if="publishModalIsVisible"
            :deliverables="selectedDeliverables"
            :library-filters-for-query="(areAllDeliverablesSelected && libraryFiltersForQuery) || {}"
            :action="publishModalData.action"
            :platform="publishModalData.platform"
            @close="togglePublishModal"
        />

        <import-export-library-modal
            v-if="importExportModalVisible"
            :visible.sync="importExportModalVisible"
            :selected-deliverable-ids="selectedDeliverableIds"
            :selected-deliverables="selectedDeliverables"
            @exportTemplate="onExportTemplate"
            @selectedDeliverablesChanged="setSelectedDeliverableIdsFromFile"
            @close="toggleImportExportModalVisible"
        />

        <div class="campaign-library">
            <hox-loading-layer v-if="isLoading" is-opaque :left-offset-pixels="60" size="large" />
            <div v-if="hasErrorLoadingRequiredData" class="campaign-library__loading-error-message-wrapper">
                <hox-alert margin-bottom="large" type="danger">
                    <template #title>Uh oh!</template>
                    <template #content>
                        <p>
                            There was an unexpected issue while loading library data. Hopefully it was a temporary issue
                            and should be fixed if you try again in a few moments.
                        </p>
                    </template>
                    <template #actionItems>
                        <Button type="primary" @click="getRequiredLibraryData">Retry</Button>
                    </template>
                </hox-alert>
            </div>
            <div v-else-if="!hasFilterOptionsForEachType">
                <hox-empty-message v-if="userCanAccessEditor">
                    <template #title>This campaign doesn't have any ads</template>
                    <template #content>
                        <p>Head over to the editor to get started</p>
                    </template>
                </hox-empty-message>
                <hox-empty-message v-else>
                    <template #title>There are no ads in the library</template>
                </hox-empty-message>
            </div>
            <template v-else>
                <library-header
                    :all-selected-mode="areAllDeliverablesSelected"
                    :paginated-deliverables="paginatedDeliverables"
                    :total-items="pagination.totalItems"
                    :select-all-disabled="isLoadingDeliverables"
                    :selected-deliverables="selectedDeliverables"
                    :view-mode="viewMode"
                    :library-filters-for-query="libraryFiltersForQuery"
                    :show-psd-options="psdTemplateSelected"
                    @showSharePreviewModal="toggleSharePreviewModal"
                    @showBulkApproval="showBulkApproval"
                    @bulkQaStatusHasBeenSet="refetchDeliverablesIfFilteringByQaStatus"
                    @bulkPublishedStatusHasBeenSet="refetchDeliverablesIfFilteringByPublishedStatus"
                    @selectedDeliverablesUpdated="setSelectedDeliverableIds"
                    @viewModeSet="setViewMode"
                    @showDownloadModal="toggleDownloadModal"
                    @showPublishModal="togglePublishModal"
                    @selectViaFile="toggleImportExportModalVisible"
                />
                <div v-if="isPaginationNoticeShown" class="campaign-library__select-all-notice-wrapper">
                    <select-all-notice
                        :all-selected-mode="areAllDeliverablesSelected"
                        :selected="selectedDeliverableIds.length"
                        :total-count="pagination.totalItems"
                        @dismiss="isPaginationNoticeShown = false"
                        @selectAll="onSelectAll"
                    />
                </div>
                <div ref="body" :class="bodyClasses">
                    <campaign-locked-notice :class="{ 'campaign-locked-notice--table-view': !isGridViewMode }" />
                    <div v-if="!allFiltersAreActive" class="campaign-library__empty-message-wrapper">
                        <hox-empty-message>
                            <template #title>No deliverables to show</template>
                            <template #content>
                                <!-- prettier-ignore -->
                                <p>You need to select at least one <em>language</em>, <em>size</em> and <em>group value for every group filter</em> in the sidebar on the left.</p>
                            </template>
                        </hox-empty-message>
                    </div>
                    <hox-alert v-else-if="hasErrorLoadingDeliverables" margin-bottom="large" type="danger">
                        <template #title>Uh oh!</template>
                        <template #content>
                            <p>
                                There was an unexpected issue while getting the deliverables. Hopefully it was a
                                temporary issue and should be fixed if you try again in a few moments.
                            </p>
                        </template>
                        <template #actionItems>
                            <Button type="primary" @click="getPaginatedDeliverables">
                                Retry getting deliverables
                            </Button>
                        </template>
                    </hox-alert>
                    <template v-else-if="!isGridViewMode">
                        <library-deliverable-table
                            :all-selected-mode="areAllDeliverablesSelected"
                            :deliverables="paginatedDeliverables"
                            :is-loading-deliverables="isLoadingDeliverables"
                            :selected-deliverables="selectedDeliverables"
                            :total-items="pagination.totalItems"
                            :library-filters-for-query="libraryFiltersForQuery"
                            :selected-deliverable-ids="selectedDeliverableIds"
                            @selectedDeliverablesChanged="setSelectedDeliverableIds"
                            @reportingLabelUpdatedOnDeliverable="updateReportingLabelOnDeliverable"
                            @metadataValueUpdatedOnDeliverables="
                                ({ idHashes, metadataValue: updatedMetadataValue }) => {
                                    updateMetadataValueOnDeliverables(idHashes, updatedMetadataValue);
                                }
                            "
                        >
                            <template #emptyMessage>
                                <library-empty-message
                                    :is-filtering-by-metadata-search="isFilteringByMetadataSearch"
                                    :is-filtering-by-status="isFilteringByStatus"
                                    :is-filtering-by-user-status="isFilteringByUserStatus"
                                    :is-filtering-by-commented-on-by="isFilteringByCommentedOnBy"
                                    :is-filtering-by-published-status="isFilteringByPublishedStatus"
                                    :is-filtering-by-visible-to="isFilteringByVisibleTo"
                                    :published-status-filter-label="publishedStatusFilterLabel"
                                    :status-filter-label="statusFilterLabel"
                                    :user-status-filter-label="qaUserStatusFilterLabel"
                                    :commented-on-by-filter-label="commentedOnByFilterLabel"
                                />
                            </template>
                        </library-deliverable-table>
                    </template>
                    <div
                        v-else-if="!paginatedDeliverables.length && !isLoadingDeliverables"
                        class="campaign-library__empty-message-wrapper"
                    >
                        <library-empty-message
                            :is-filtering-by-metadata-search="isFilteringByMetadataSearch"
                            :is-filtering-by-status="isFilteringByStatus"
                            :is-filtering-by-user-status="isFilteringByUserStatus"
                            :is-filtering-by-commented-on-by="isFilteringByCommentedOnBy"
                            :is-filtering-by-published-status="isFilteringByPublishedStatus"
                            :is-filtering-by-visible-to="isFilteringByVisibleTo"
                            :published-status-filter-label="publishedStatusFilterLabel"
                            :status-filter-label="statusFilterLabel"
                            :user-status-filter-label="qaUserStatusFilterLabel"
                            :commented-on-by-filter-label="commentedOnByFilterLabel"
                        />
                    </div>
                    <template v-else>
                        <CheckboxGroup
                            v-if="isGridViewMode"
                            :value="selectedDeliverableIds"
                            @input="setSelectedDeliverableIds"
                        >
                            <div
                                v-for="size in Object.keys(deliverableSizeGroups)"
                                :key="size"
                                :class="{
                                    'campaign-edit__deliverables-size-group--single-child':
                                        deliverableSizeGroups[size].length === 1,
                                    'campaign-edit__deliverables-size-group--resizable':
                                        deliverableSizeGroups[size].length === 1 &&
                                        resizedBanners.includes(deliverableSizeGroups[size][0]._id)
                                }"
                                class="campaign-edit__deliverables-size-group"
                            >
                                <div
                                    v-for="deliverable in deliverableSizeGroups[size]"
                                    :key="deliverable.idHash"
                                    class="campaign-edit__deliverable"
                                    :class="{
                                        'campaign-edit__deliverable--resizable': resizedBanners.includes(
                                            deliverable._id
                                        )
                                    }"
                                >
                                    <library-banner
                                        :deliverable="deliverable"
                                        :max-height="bodyDimensionsPixels.height"
                                        :max-width="bodyDimensionsPixels.width"
                                        :scaling-factor="scalingFactor"
                                        @statusHasBeenUpdated="refetchDeliverablesIfFilteringByQaStatus"
                                        @BannerLoaded="onBannerLoaded(deliverable)"
                                        @resized="onResize(deliverable, $event)"
                                    />
                                </div>
                            </div>
                        </CheckboxGroup>
                        <hox-loading-layer v-if="isLoadingDeliverables" :is-full-screen="false" />
                    </template>
                </div>
                <div
                    v-if="paginatedDeliverables.length || hasErrorLoadingDeliverables || isLoadingDeliverables"
                    :key="pagination.totalPages"
                    class="campaign-library__footer"
                >
                    <div class="campaign-library__footer-items">
                        <div v-if="isGridViewMode" class="campaign-library__scale-options-wrapper">
                            <p>Ad scaling:</p>
                            <Select v-model="scalingFactor">
                                <Option value="scale">Auto</Option>
                                <Option :value="0.25">25%</Option>
                                <Option :value="0.5">50%</Option>
                                <Option :value="0.75">75%</Option>
                                <Option :value="1">100%</Option>
                            </Select>
                        </div>
                        <banner-scrubbar
                            v-if="isGridViewMode"
                            class="campaign-library__footer-item"
                            :value="bannerPlaybackTime"
                            @change="setBannerPlaybackTime"
                        ></banner-scrubbar>
                        <campaign-banner-play-all
                            v-if="isGridViewMode"
                            class="campaign-library__footer-item"
                        ></campaign-banner-play-all>
                        <div v-if="!isGridViewMode" class="campaign-library__footer-spacer"></div>
                        <Page
                            class="campaign-library__footer-item campaign-library__page-select"
                            :current="pagination.currentPage"
                            :page-size="pagination.pageSize"
                            :total="pagination.totalItems"
                            @on-change="setCurrentPage"
                        />
                        <div
                            v-if="!isGridViewMode"
                            class="campaign-library__footer-item campaign-library__page-size-select-wrapper"
                        >
                            <Select :value="pagination.pageSize" :disabled="isGridViewMode" @on-change="setPageSize">
                                <Option v-for="option in pageSizeOptions" :key="option.value" :value="option.value">
                                    {{ option.label }}
                                </Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </template>
            <sidebar :scroll="false">
                <div v-if="hasFilterOptionsForEachType" class="campaign-library__sidebar campaign-light__sidebar">
                    <nav class="campaign-library__sidebar-nav campaign-light__sidebar-nav">
                        <navigation-tabs class="campaign-library-sidebar-nav">
                            <navigation-tabs-item
                                :is-active="filtersTab === 'creatives'"
                                data-testid="library-tabs__creatives"
                                @click="filtersTab = 'creatives'"
                            >
                                Creatives
                            </navigation-tabs-item>
                            <navigation-tabs-item
                                :is-active="filtersTab === 'workflow'"
                                data-testid="library-tabs__workflow"
                                @click="filtersTab = 'workflow'"
                            >
                                Workflow
                            </navigation-tabs-item>
                        </navigation-tabs>
                    </nav>

                    <div class="campaign-library__variations">
                        Showing
                        <hox-loading-spinner v-if="isLoadingDeliverables" />
                        <template v-else>
                            {{ totalGeneratedVariations }}
                        </template>
                        of {{ totalVariations }} variations
                    </div>
                    <div v-show="filtersTab === 'creatives'">
                        <library-language-filter></library-language-filter>
                        <library-size-filter></library-size-filter>
                        <editable-group-checkbox-list></editable-group-checkbox-list>
                    </div>
                    <div v-show="filtersTab === 'workflow'">
                        <library-qa-commented-on-filter />
                        <library-qa-status-filter />
                        <library-published-status-filter />
                        <library-qa-user-status-filter />
                        <library-visible-to-filter
                            v-if="$auth.userCan($auth.Actions.CanChangeDeliverableVisibility, { clientId, campaignId })"
                        />
                        <library-misc-filter />
                    </div>
                </div>
            </sidebar>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import { formatGroupName, generateTemplateLabel, numberWithCommas } from "@/utils";
import { QueryParamPrefix } from "@/utils/urls";
import { CampaignAction } from "@/store/modules/campaign";
import {
    DeliverableLibraryAction,
    DeliverableLibraryGetters,
    UrlFilterParams,
    ViewMode,
    ViewModeDefaultPageSize
} from "@/store/modules/deliverableLibrary";

import Deliverable from "@/services/Deliverables";
import { getMetadataFieldsOfCampaign } from "@/services/Metadata";
import URLManager from "@/services/URLManager";

import campaignLibraryQuery from "@/apollo/queries/v2/CampaignLibrary.gql";
import computedDeliverableComponentsQuery from "@/apollo/queries/v2/ComputedDeliverableComponents.gql";

import SharePreviewModal from "@/components/SharePreviewModal";
import DownloadModal from "@/components/DownloadModal";
import PublishModal from "@/components/Campaign/Publish/PublishModal";
import Sidebar from "@/components/Sidebar";
import EditableGroupCheckboxList from "@/components/Campaign/EditableGroupCheckboxList";
import LibraryBanner from "@/components/Campaign/LibraryBanner";
import LibraryDeliverableTable from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTable";
import LibraryPublishedStatusFilter from "@/components/Campaign/LibraryPublishedStatusFilter";
import LibraryQaStatusFilter from "@/components/Campaign/LibraryQaStatusFilter";
import LibrarySizeFilter from "@/components/Campaign/LibrarySizeFilter";
import LibraryLanguageFilter from "@/components/Campaign/LibraryLanguageFilter";
import LibraryHeader from "@/components/Campaign/LibraryHeader";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";

import {
    BannerQaCommentedOnByFilter,
    BannerQaCommentedOnByLabel,
    BannerQaStatusFilter,
    BannerQaStatusLabel,
    BannerQaUserStatusFilter,
    BannerQaUserStatusLabel,
    BannerQaUserStatusLabelFilterMap
} from "@/enums/banners";
import {
    PlatformPublishedStatusFilter,
    PlatformPublishedStatusFilterLabel,
    PublishedStatusFilterPlatform
} from "@/enums/platforms";

import { JobNames } from "@/enums/jobs";
import { UiAction } from "@/store/modules/ui";
import DeliverableDrawer from "@/components/Campaign/DeliverableDrawer";
import BannerScrubbar from "@/components/BannerScrubbar";
import CampaignBannerPlayAll from "@/components/Campaign/CampaignBannerPlayAll";
import SelectAllNotice from "@/components/Campaign/SelectAllNotice";
import bannerTimelineMixin from "@/mixins/bannerTimelineMixin";
import LibraryEmptyMessage from "@/components/Campaign/LibraryEmptyMessage";
import LibraryVisibleToFilter from "@/components/Campaign/LibraryVisibleToFilter";
import LibraryQaUserStatusFilter from "@/components/Campaign/LibraryQaUserStatusFilter";
import LibraryQaCommentedOnFilter from "@/components/Campaign/LibraryQaCommentedOnFilter";
import scalingBannerWrapperMixin from "@/mixins/scalingBannerWrapperMixin";
import ImportExportLibraryModal from "@/components/Campaign/ImportExportLibraryModal";
import ImportExport from "@/services/ImportExport";
import LibraryMiscFilter from "@/components/Campaign/LibraryMiscFilter";
import CampaignLockedNotice from "@/views/Campaign/CampaignLockedNotice";

export default {
    name: "CampaignLibrary",
    components: {
        CampaignLockedNotice,
        LibraryMiscFilter,
        ImportExportLibraryModal,
        LibraryQaCommentedOnFilter,
        LibraryQaUserStatusFilter,
        LibraryEmptyMessage,
        LibraryVisibleToFilter,
        SelectAllNotice,
        CampaignBannerPlayAll,
        BannerScrubbar,
        LibraryBanner,
        LibraryDeliverableTable,
        DeliverableDrawer,
        LibraryHeader,
        LibraryLanguageFilter,
        LibraryPublishedStatusFilter,
        LibraryQaStatusFilter,
        LibrarySizeFilter,
        EditableGroupCheckboxList,
        NavigationTabs,
        NavigationTabsItem,
        SharePreviewModal,
        DownloadModal,
        PublishModal,
        Sidebar
    },

    mixins: [bannerTimelineMixin, scalingBannerWrapperMixin],

    data() {
        return {
            areAllDeliverablesSelected: false,
            downloadModalIsVisible: false,
            drawerDeliverablesHadCommentsUpdated: false,
            drawerDeliverablesHadStatusUpdated: false,
            drawerDeliverablesHadUserStatusUpdated: false,
            filtersTab: "creatives",
            getPaginatedDeliverablesTimeout: undefined,
            hasErrorLoadingCampaign: false,
            hasErrorLoadingDeliverables: false,
            hasErrorLoadingFilterOptions: false,
            hasErrorLoadingMetadataFields: false,
            importExportModalVisible: false,
            isBeingDestroyed: false,
            isLoadingCampaign: 0,
            isLoadingDeliverables: false,
            isLoadingLibraryFilterOptions: 0,
            isLoadingMetadataFields: false,
            isPaginationNoticeShown: false,
            mounted: true,
            multiviewInstanceName: "library",
            numberOfDeliverablesThatUserCanView: 0,
            pageSizeOptions: [
                {
                    value: 10,
                    label: "10/page"
                },
                {
                    value: 25,
                    label: "25/page"
                },
                {
                    value: 50,
                    label: "50/page"
                },
                {
                    value: 75,
                    label: "75/page"
                },
                {
                    value: 100,
                    label: "100/page"
                }
            ],
            paginatedDeliverables: [],
            paginatedDeliverablesRequestCounter: 0,
            publishModalData: {},
            publishModalIsVisible: false,
            resizedBanners: [],
            scrubbarSource: null,
            selectedDeliverableIds: [],
            selectedDeliverableIdentifiers: [],
            sharePreviewModalIsVisible: false
        };
    },

    computed: {
        allFiltersAreActive() {
            return (
                this.allCreativeFiltersHaveSelection && this.languageFilters.length > 0 && this.sizeFilters.length > 0
            );
        },

        allCreativeFiltersHaveSelection() {
            const editableGroupsFilterOptions = this.$store.state.deliverableLibrary.filterOptions.editableGroups;

            return !editableGroupsFilterOptions.some(groupFilterOption => {
                const groupSelection = this.$store.getters[DeliverableLibraryGetters.groupValueFilters](
                    groupFilterOption.name
                );

                return groupSelection.length === 0;
            });
        },

        bannerIframeId() {
            if (this.scrubbarSource) {
                return `iframe-${this.scrubbarSource.idHash}`;
            }

            return null;
        },

        bodyClasses() {
            const classes = ["campaign-library__body"];
            if (!this.isGridViewMode && !this.hasErrorLoadingDeliverables) {
                classes.push("campaign-library__body--no-padding");
            }
            return classes;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        canPreviewDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanPreviewDeliverable, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        commentedOnByFilter() {
            return this.$store.getters[DeliverableLibraryGetters.commentedOnByFilter];
        },

        commentedOnByFilterLabel() {
            return BannerQaCommentedOnByLabel[this.commentedOnByFilter];
        },

        completedJobs() {
            return this.$store.state.jobs.completedJobs;
        },

        deliverablesByIdHash() {
            return this.paginatedDeliverables.reduce((acc, deliverable) => {
                acc[deliverable.idHash] = {
                    ...deliverable
                };
                return acc;
            }, {});
        },

        deliverableSizeGroups() {
            return this.paginatedDeliverables.reduce((acc, deliverable) => {
                const size = `${deliverable.masterTemplate.width}x${deliverable.masterTemplate.height}`;
                acc[size] = (acc[size] || []).concat(deliverable);

                return acc;
            }, {});
        },

        drawerDeliverables: {
            get() {
                return this.$store.state.deliverableLibrary.drawer.deliverables;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, [value]);
            }
        },

        drawerIsVisible: {
            get() {
                return this.$store.state.deliverableLibrary.drawer.isVisible;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, value);
            }
        },

        editableGroupIds() {
            return this.$store.state.campaign.normalized.editableGroupIds;
        },

        editableGroups() {
            return this.$store.state.campaign.normalized.editableGroups;
        },

        filterParams() {
            return this.$store.getters[DeliverableLibraryGetters.urlFilterParams];
        },

        groupValueFilters() {
            return this.$store.getters[DeliverableLibraryGetters.groupValueFilters]();
        },

        hasErrorLoadingRequiredData() {
            return (
                this.hasErrorLoadingCampaign || this.hasErrorLoadingFilterOptions || this.hasErrorLoadingMetadataFields
            );
        },

        hasFilterOptionsForEachType() {
            const { filterOptions } = this.$store.state.deliverableLibrary;

            return (
                filterOptions.editableGroups.length > 0 &&
                filterOptions.languages.length > 0 &&
                filterOptions.masterTemplates.length > 0
            );
        },

        htmlTemplateSelected() {
            return this.selectedDeliverables.some(({ masterTemplate }) => masterTemplate.adType !== TemplateType.PSD);
        },

        isFilteringByCommentedOnBy() {
            return this.commentedOnByFilter !== BannerQaCommentedOnByFilter.All;
        },

        isFilteringByMetadataSearch() {
            return Object.keys(this.metadataSearchFilters).length > 0;
        },

        isFilteringByPublishedStatus() {
            return this.publishedStatusFilter !== PlatformPublishedStatusFilter.All;
        },

        isFilteringByStatus() {
            return this.statusFilter !== BannerQaStatusFilter.All;
        },

        isFilteringByUserStatus() {
            return this.userStatusFilter !== BannerQaUserStatusFilter.All;
        },

        isFilteringByVisibleTo() {
            return this.visibleToFilters && this.visibleToFilters.length > 0;
        },

        isGridViewMode() {
            return this.viewMode === ViewMode.Grid;
        },

        isInitialisedForCampaign() {
            return this.libraryCampaignId && this.libraryCampaignId === this.campaignId;
        },

        isLoading() {
            return this.isLoadingCampaign || this.isLoadingLibraryFilterOptions || this.isLoadingMetadataFields;
        },

        isPermissionGranted() {
            return this.userHasScope && this.canPreviewDeliverable;
        },

        languageFilters() {
            return this.$store.getters[DeliverableLibraryGetters.languageFilters];
        },

        libraryCampaignId: {
            get() {
                return this.$store.state.deliverableLibrary.campaignId;
            },
            set(campaignId) {
                this.$store.dispatch(DeliverableLibraryAction.SetCampaignId, campaignId);
            }
        },

        libraryFiltersForQuery() {
            const groupValueFilters = this.getGroupValuesForQuery();
            const qaStatusFilters = this.getStatusFilterForQuery();
            const qaUserStatusFilters = this.getQaUserStatusFilterForQuery();
            const qaCommentedOnByFilters = this.getCommentedOnByFilterForQuery();
            const publishedStatusFilters = this.getPublishedStatusFilterForQuery();
            const miscFilters = this.getMiscFiltersForQuery();
            const searchFilters = this.getSearchFilterForQuery();

            return {
                groupValueFilters,
                qaStatusFilters,
                qaUserStatusFilters,
                qaCommentedOnByFilters,
                publishedStatusFilters,
                searchFilters,
                ...miscFilters,
                sizeFilters: this.sizeFilters,
                languageFilters: this.languageFilters,
                visibleToFilters: this.visibleToFilters
            };
        },

        metadataFieldsByFieldId() {
            return this.$store.state.deliverableLibrary.metadataFieldsByFieldId;
        },

        metadataSearchFilters() {
            return this.$store.state.deliverableLibrary.metadataSearchFiltersByFieldId;
        },

        pagination: {
            get() {
                return this.$store.state.deliverableLibrary.pagination;
            },
            set(newPagination) {
                this.$store.dispatch(DeliverableLibraryAction.SetPagination, newPagination);
            }
        },

        psdTemplateSelected() {
            return this.selectedDeliverables.some(({ masterTemplate }) => masterTemplate.adType === TemplateType.PSD);
        },

        publishedStatusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.publishedStatusFilter];
        },

        publishedStatusFilterLabel() {
            return PlatformPublishedStatusFilterLabel[this.publishedStatusFilter];
        },

        qaUserStatusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.userStatusFilter];
        },

        qaUserStatusFilterLabel() {
            return BannerQaUserStatusLabel[this.$store.getters[DeliverableLibraryGetters.userStatusFilter]];
        },

        selectedDeliverables() {
            return this.paginatedDeliverables.filter(deliverable =>
                this.selectedDeliverableIds.includes(deliverable.idHash)
            );
        },

        sizeFilters() {
            return this.$store.getters[DeliverableLibraryGetters.sizeFilters];
        },

        statusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.statusFilter];
        },

        statusFilterLabel() {
            return BannerQaStatusLabel[this.statusFilter];
        },

        totalGeneratedVariations() {
            if (this.allFiltersAreActive) {
                return numberWithCommas(this.pagination.totalItems);
            }
            return 0;
        },

        totalVariations() {
            return numberWithCommas(this.numberOfDeliverablesThatUserCanView);
        },

        userCanAccessEditor() {
            return this.$auth.userCan(this.$auth.Actions.CanManageOverwrites, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userCanChangeDeliverableVisibility() {
            return this.$auth.userCan(this.$auth.Actions.CanChangeDeliverableVisibility, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userHasScope() {
            return this.$auth.hasScope({
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userStatusFilter() {
            return this.$store.getters[DeliverableLibraryGetters.userStatusFilter];
        },

        viewMode: {
            get() {
                return this.$store.state.deliverableLibrary.viewMode;
            },
            set(newViewMode) {
                this.$store.dispatch(DeliverableLibraryAction.SetViewMode, newViewMode);
            }
        },

        visibleToFilters() {
            return this.$store.getters[DeliverableLibraryGetters.VisibleToFilter];
        }
    },

    watch: {
        bannerState(state) {
            if (state && state.currentTime) {
                this.setBannerPlaybackTimeFromBanner(state.currentTime);
            }
        },

        completedJobs(jobs) {
            const updatingJobs = jobs.filter(({ name }) =>
                [
                    JobNames.PublishXmlFeed,
                    JobNames.UnpublishXmlFeed,
                    JobNames.AddMetadataValue,
                    JobNames.RemoveMetadataValue
                ].includes(name)
            );
            if (updatingJobs.length) {
                this.getPaginatedDeliverables();
            }
        },

        drawerIsVisible() {
            this.urlManager.setURLParams(
                [
                    {
                        key: UrlFilterParams.DeliverableDrawerIsVisible,
                        value: this.drawerIsVisible ? [1] : []
                    }
                ],
                true
            );
        },

        filterParams: {
            deep: true,
            // eslint-disable-next-line complexity
            handler(newFilters, previousFilters) {
                if (this.hasFilterOptionsForEachType) {
                    const filterParams = [
                        ...newFilters,
                        ...previousFilters.reduce((filtersToClear, previousFilter) => {
                            /*
                                We do this to clear previous query parameters that no
                                longer have any values, but that had previously been set.
                            */
                            const hasNewFilter =
                                newFilters.findIndex(newFilter => newFilter.key === previousFilter.key) > -1;
                            if (!hasNewFilter) {
                                return [
                                    ...filtersToClear,
                                    {
                                        key: previousFilter.key,
                                        value: []
                                    }
                                ];
                            }
                            return filtersToClear;
                        }, [])
                    ];
                    this.urlManager.setURLParams(filterParams, this.mounted);
                    /*
                        As this is triggered on initial load of the page by setting
                        filters from URL params it could overwrite the page set by
                        URL params, as a result we guard it with a flag.
                    */
                    if (this.hasInitialisedFilterParams) {
                        this.setCurrentPage(1);
                        /*
                            If we change filters then we don't know how many pages we have
                            so we'll default to 1 while the data is loading.
                        */
                        this.pagination = {
                            ...this.pagination,
                            totalItems: this.pagination.pageSize
                        };
                    }
                    if (this.sizeFilters.length > 0) {
                        /*
                            The LibrarySizeFilter component currently sets the initial
                            state for the template/size filters, so we're not going
                            to toggle the initialised flag to true until we have size
                            filters.
                        */
                        this.hasInitialisedFilterParams = true;
                    }

                    this.mounted = false;
                    this.getPaginatedDeliverables();
                }
            }
        },

        isGridViewMode() {
            this.paginatedDeliverables = [];
            if (this.isGridViewMode) {
                this.$store.dispatch(DeliverableLibraryAction.ClearMetadataSearchFiltersByFieldId);
            }
        },

        async isLoading() {
            if (!this.isLoading) {
                if (this.hasErrorLoadingRequiredData || !this.hasFilterOptionsForEachType) {
                    this.$store.dispatch(UiAction.CloseSidebar);
                    return;
                }
                this.$store.dispatch(UiAction.OpenSidebar);
                if (!this.isInitialisedForCampaign) {
                    this.$store.dispatch(DeliverableLibraryAction.SetInitialState, this.$route.query);
                } else {
                    /*
                        If we get here then we can assume that the library previously
                        been initialised for the campaign and we just need to update the
                        URL so that it reflects the state we have.
                    */
                    this.urlManager.setURLParams([...this.filterParams], this.mounted);
                    this.setCurrentPageQueryParam();
                    this.setPageSizeQueryParam();
                    this.setViewModeQueryParam();
                }
                this.getPaginatedDeliverables();
            }
            await this.$nextTick();
            this.setBodyDimensionsPixels();
        },

        isPermissionGranted() {
            this.getMetadataFields();
        },

        metadataSearchFilters() {
            this.setCurrentPage(1);
            this.pagination = {
                ...this.pagination,
                totalItems: this.pagination.pageSize
            };
            this.getPaginatedDeliverables();
            const queryParams = Object.keys(this.metadataFieldsByFieldId).map(fieldId => {
                const searchString = this.metadataSearchFilters[fieldId];
                if (searchString) {
                    return {
                        key: `${UrlFilterParams.MetadataSearch}:${fieldId}`,
                        value: [searchString]
                    };
                }
                return {
                    key: `${UrlFilterParams.MetadataSearch}:${fieldId}`,
                    value: []
                };
            });
            this.urlManager.setURLParams(queryParams, true);
        }
    },

    created() {
        window.addEventListener("resize", this.setBodyDimensionsPixels, true);
        this.deliverableService = new Deliverable(this.$apollo, this.$store);
        this.importExportService = new ImportExport(this.$apollo, this.$store);
        this.$store.dispatch(UiAction.SetSidebarWidthOpen, 300);

        this.urlManager = new URLManager(this.$router, {
            tracked: [
                ...Object.values(UrlFilterParams),
                ...Object.values(UrlFilterParams).map(
                    urlFilterParam => `${QueryParamPrefix.NoValues}-${urlFilterParam}`
                ),
                ...Object.values(UrlFilterParams).map(
                    urlFilterParam => `${QueryParamPrefix.InactiveValues}-${urlFilterParam}`
                )
            ]
        });
        if (!this.isInitialisedForCampaign) {
            this.libraryCampaignId = null;
        }
        this.getMetadataFields();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.setBodyDimensionsPixels, true);
        this.isBeingDestroyed = true;
        this.onDrawerClosed();
        this.$store.dispatch(DeliverableLibraryAction.UnsetDeliverableStatusByIdHash, this.paginatedDeliverables);
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        hasMoreToSelect(selectedDeliverableIds) {
            return (
                selectedDeliverableIds.length &&
                selectedDeliverableIds.length === this.paginatedDeliverables.length &&
                selectedDeliverableIds.length !== this.pagination.totalItems
            );
        },

        async getMetadataFields() {
            this.hasErrorLoadingMetadataFields = false;
            if (this.isPermissionGranted && !this.isLoadingMetadataFields) {
                this.isLoadingMetadataFields = true;
                try {
                    const metadataFields = await getMetadataFieldsOfCampaign(this.campaignId);
                    this.$store.dispatch(DeliverableLibraryAction.ClearMetadataFieldsByFieldId);
                    metadataFields.forEach(metadataField => {
                        this.$store.dispatch(DeliverableLibraryAction.SetMetadataFieldsByFieldId, metadataField);
                    });
                } catch (err) {
                    this.hasErrorLoadingMetadataFields = true;
                }
                this.isLoadingMetadataFields = false;
            }
        },

        async getPaginatedDeliverables() {
            // eslint-disable-next-line complexity
            const getPaginatedDeliverables = async () => {
                this.hasErrorLoadingDeliverables = false;
                /*
                    While it may change in the future, we want to clear the selected deliverables
                    any time we attempt to fetch new deliverables.
                */
                this.setSelectedDeliverableIds([]);
                /*
                    Because we may have long running requests that may complete after shorter requests
                    we are going to check against a component level counter to ensure that the request
                    is the most recent before setting any state as a result of the query.
                */
                const isMostRecentRequest = requestCount =>
                    this.paginatedDeliverablesRequestCounter === requestCount &&
                    this.getPaginatedDeliverablesTimeout === undefined;
                this.paginatedDeliverablesRequestCounter += 1;
                /*
                    So that `deliverableStatusByIdHash` is not unbounded in
                    how large it can grow we clear out the existing deliverables.
                */
                this.$store.dispatch(
                    DeliverableLibraryAction.UnsetDeliverableStatusByIdHash,
                    this.paginatedDeliverables
                );
                /*
                    It's possible for this function to be called before the page has finished
                    loading things such as editables and masterTemplates, which we require to
                    process the deliverables, so we don't want to allow fetching until they've been
                    loaded.

                    There's also no point in attempting to query without having all filters as the
                    response would contain no deliverables.
                */
                if (!this.isLoading && this.allFiltersAreActive) {
                    const requestCount = this.paginatedDeliverablesRequestCounter;
                    this.isLoadingDeliverables = true;
                    /*
                        We're going to clear the deliverables when we start to fetch new deliverables,
                        partly because it looks cleaner, but mainly because it allows us to avoid issues
                        relating to lazy loaded banners not being correctly initialised.

                        We don't do this for the table view as we want to keep the deliverables so that
                        the table columns do not instantly resize/shift out of view when filtering using
                        the metadata column search inputs.
                    */
                    if (this.isGridViewMode) {
                        this.paginatedDeliverables = [];
                    }
                    this.scrubbarSource = null;
                    this.bannerPlaybackTime = 0;
                    try {
                        const { deliverables, pagination } = await this.deliverableService.getPaginatedDeliverables(
                            this.campaignId,
                            this.libraryFiltersForQuery,
                            this.pagination.currentPage,
                            this.pagination.pageSize
                        );
                        /*
                            If we have a pending request, don't bother proceeding
                            any further as it will be overwritten shortly.
                        */
                        if (!isMostRecentRequest(requestCount)) {
                            return;
                        }
                        /*
                            If somehow the user has managed to get to a page that is beyond the max page
                            we want to bump them down the last possible page to in order correct things.
                        */
                        if (this.pagination.currentPage > pagination.maxPage && pagination.totalCount > 0) {
                            this.setCurrentPage(pagination.maxPage);
                            this.pagination = {
                                ...this.pagination,
                                totalPages: pagination.maxPage,
                                totalItems: pagination.totalCount
                            };
                            return;
                        }
                        this.pagination = {
                            ...this.pagination,
                            totalPages: pagination.maxPage,
                            totalItems: pagination.totalCount
                        };
                        this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                        this.paginatedDeliverables = deliverables;
                    } catch (err) {
                        if (isMostRecentRequest(requestCount)) {
                            this.hasErrorLoadingDeliverables = true;
                        }
                    } finally {
                        if (isMostRecentRequest(requestCount)) {
                            this.isLoadingDeliverables = false;
                        }
                    }
                } else {
                    this.isLoadingDeliverables = false;
                    this.paginatedDeliverables = [];
                }
            };
            if (this.isLoadingDeliverables) {
                clearTimeout(this.getPaginatedDeliverablesTimeout);
                this.getPaginatedDeliverablesTimeout = setTimeout(() => {
                    this.getPaginatedDeliverablesTimeout = undefined;
                    getPaginatedDeliverables();
                }, 500);
            } else {
                getPaginatedDeliverables();
            }
        },

        getGroupValuesForQuery() {
            return this.groupValueFilters.map(groupValueFilter => ({
                name: groupValueFilter.groupName,
                valueIds: groupValueFilter.groupValues
            }));
        },

        getMiscFiltersForQuery() {
            const filters = this.$store.getters[DeliverableLibraryGetters.miscFilters];

            return filters.reduce((acc, curr) => {
                acc[curr] = true;

                return acc;
            }, {});
        },

        // eslint-disable-next-line complexity
        getPublishedStatusFilterForQuery() {
            if (this.publishedStatusFilter === PlatformPublishedStatusFilter.All) {
                return null;
            }
            const publishedStatusFilterForQuery = {
                published: [
                    PlatformPublishedStatusFilter.Published,
                    PlatformPublishedStatusFilter.PublishedToDoubleClick,
                    PlatformPublishedStatusFilter.PublishedToOpenDC,
                    PlatformPublishedStatusFilter.PublishedToSizmek
                ].includes(this.publishedStatusFilter)
            };
            if (
                this.publishedStatusFilter === PlatformPublishedStatusFilter.PublishedToDoubleClick ||
                this.publishedStatusFilter === PlatformPublishedStatusFilter.NotPublishedToDoubleClick
            ) {
                publishedStatusFilterForQuery.platform = PublishedStatusFilterPlatform.DoubleClick;
                return publishedStatusFilterForQuery;
            }

            if (
                this.publishedStatusFilter === PlatformPublishedStatusFilter.PublishedToSizmek ||
                this.publishedStatusFilter === PlatformPublishedStatusFilter.NotPublishedToSizmek
            ) {
                publishedStatusFilterForQuery.platform = PublishedStatusFilterPlatform.Sizmek;
                return publishedStatusFilterForQuery;
            }
            if (
                this.publishedStatusFilter === PlatformPublishedStatusFilter.PublishedToOpenDC ||
                this.publishedStatusFilter === PlatformPublishedStatusFilter.NotPublishedToOpenDC
            ) {
                publishedStatusFilterForQuery.platform = PublishedStatusFilterPlatform.OpenDC;
                return publishedStatusFilterForQuery;
            }

            publishedStatusFilterForQuery.platform = PublishedStatusFilterPlatform.All;
            return publishedStatusFilterForQuery;
        },

        getRequiredLibraryData() {
            this.getMetadataFields();
            this.$apollo.queries.campaign.refetch();
            this.$apollo.queries.computedDeliverableComponents.refetch();
        },

        getSearchFilterForQuery() {
            return {
                metadata: Object.keys(this.metadataSearchFilters).map(fieldId => {
                    return {
                        fieldId,
                        value: this.metadataSearchFilters[fieldId]
                    };
                })
            };
        },

        getCommentedOnByFilterForQuery() {
            return this.commentedOnByFilter === BannerQaCommentedOnByFilter.All ? null : this.commentedOnByFilter;
        },

        getStatusFilterForQuery() {
            return this.statusFilter === BannerQaStatusFilter.All ? null : this.statusFilter;
        },

        getQaUserStatusFilterForQuery() {
            if (
                this.qaUserStatusFilter === BannerQaStatusFilter.All ||
                this.qaUserStatusFilter[0] === BannerQaStatusFilter.All
            ) {
                return null;
            }

            return this.qaUserStatusFilter.map(filter => BannerQaUserStatusLabelFilterMap[filter]);
        },

        onBannerLoaded(deliverable) {
            if (!this.scrubbarSource) {
                this.scrubbarSource = deliverable;
            }
        },

        onDeliverableStatusUpdate() {
            this.drawerDeliverablesHadStatusUpdated = true;
        },

        onDeliverableUserStatusUpdate() {
            this.drawerDeliverablesHadUserStatusUpdated = true;
        },

        onDeliverableCommentsUpdate() {
            this.drawerDeliverablesHadCommentsUpdated = true;
        },

        // eslint-disable-next-line complexity
        onDrawerClosed() {
            /*
                So that we don't display a banner that has been approved in the QA
                drawer in the library when filtering by QA status = IN_REVIEW we
                refetch the deliverables that we are showing in the library if
                we have updated the QA status of a deliverable in the QA drawer.

                We could try to be more intelligent about this by tracking which
                statuses have been changed (from and to) and for which banners,
                but the logic overhead/complexity didn't seem worth it.

                We check if the component is being destroyed as we do not need
                to update the deliverables if it is being destroyed.
            */
            if (!this.isBeingDestroyed) {
                let shouldRefetchDeliverables = false;
                if (this.drawerDeliverablesHadStatusUpdated && this.isFilteringByStatus) {
                    this.drawerDeliverablesHadStatusUpdated = false;
                    shouldRefetchDeliverables = true;
                }

                if (this.drawerDeliverablesHadUserStatusUpdated && this.isFilteringByUserStatus) {
                    this.drawerDeliverablesHadUserStatusUpdated = false;
                    shouldRefetchDeliverables = true;
                }

                if (this.drawerDeliverablesHadCommentsUpdated && this.isFilteringByCommentedOnBy) {
                    this.drawerDeliverablesHadCommentsUpdated = false;
                    shouldRefetchDeliverables = true;
                }

                if (shouldRefetchDeliverables) {
                    this.getPaginatedDeliverables();
                }
            }

            this.drawerIsVisible = false;
            this.drawerDeliverables = null;
            this.$store.dispatch(DeliverableLibraryAction.SetPreviewDrawerActiveTab, null);
        },

        onResize(deliverable, isResized) {
            if (isResized) {
                this.resizedBanners.push(deliverable._id);
            } else {
                this.resizedBanners = this.resizedBanners.filter(id => deliverable._id !== id);
            }
        },

        onSelectAll() {
            this.setSelectedDeliverableIds(this.paginatedDeliverables.map(({ idHash }) => idHash));
            this.isPaginationNoticeShown = false;
            this.areAllDeliverablesSelected = true;
        },

        onExportTemplate() {
            this.importExportService.exportLibrarySelectTemplate();
        },

        redirectToClients() {
            this.$router.push({ name: "Home" });
        },

        refetchDeliverablesIfFilteringByPublishedStatus() {
            if (this.isFilteringByPublishedStatus) {
                this.getPaginatedDeliverables();
            }
        },

        refetchDeliverablesIfFilteringByQaStatus() {
            if (this.isFilteringByStatus) {
                this.getPaginatedDeliverables();
            }
        },

        setCurrentPage(newPage) {
            if (newPage !== this.pagination.currentPage) {
                this.pagination = {
                    ...this.pagination,
                    currentPage: newPage
                };
                this.setCurrentPageQueryParam();
                this.getPaginatedDeliverables();
            }
        },

        setCurrentPageQueryParam() {
            this.urlManager.setURLParams(
                [
                    {
                        key: UrlFilterParams.Page,
                        value: this.pagination.currentPage === 1 ? [] : [this.pagination.currentPage]
                    }
                ],
                this.mounted
            );
        },

        setEditableGroupFilterOptions(editableGroupValues) {
            const optionsByEditableGroup = editableGroupValues.reduce((acc, { _id, editableGroupName, value }) => {
                if (!acc[editableGroupName]) {
                    acc[editableGroupName] = {
                        label: formatGroupName(editableGroupName),
                        name: editableGroupName,
                        options: []
                    };
                }
                acc[editableGroupName].options.push({
                    label: value,
                    value: _id
                });
                return acc;
            }, {});
            const editableGroupsWithOptions = Object.values(optionsByEditableGroup);
            this.$store.dispatch(DeliverableLibraryAction.SetEditableGroupFilterOptions, editableGroupsWithOptions);
        },

        setMasterTemplateFilterOptions(masterTemplates) {
            /*
                We do this sort so that the same master templates get suffixed with
                the same number (if there are no changes to the master templates)
                by the generateLabel function if there are multiple master templates
                of the same size.
            */
            const masterTemplatesSortedById = [...masterTemplates].sort((a, b) => {
                if (a.created < b.created) {
                    return -1;
                }
                if (a.created > b.created) {
                    return 1;
                }
                return 0;
            });
            const generatedTemplateLabels = [];
            const masterTemplateOptions = [];
            masterTemplatesSortedById.forEach(masterTemplate => {
                const label = generateTemplateLabel(masterTemplate, generatedTemplateLabels);
                generatedTemplateLabels.push(label);
                masterTemplateOptions.push({
                    height: masterTemplate.width,
                    label,
                    value: masterTemplate._id,
                    width: masterTemplate.width
                });
            });
            /*
                We sort the options by label because it's nicer if the options
                are sorted by dimensions in the UI.
            */
            // eslint-disable-next-line complexity
            const masterTemplateOptionsSortedByDimensions = [...masterTemplateOptions].sort((a, b) => {
                if (a.width < b.width) {
                    return -1;
                }
                if (a.width > b.width) {
                    return 1;
                }
                if (a.height < b.height) {
                    return -1;
                }
                if (a.height > b.height) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            this.$store.dispatch(
                DeliverableLibraryAction.SetMasterTemplateFilterOptions,
                masterTemplateOptionsSortedByDimensions
            );
        },

        setPageSize(newPageSize) {
            if (newPageSize !== this.pagination.pageSize) {
                this.pagination = {
                    ...this.pagination,
                    pageSize: newPageSize,
                    /*
                        If we change the page size then we don't know how many pages we have
                        so we'll default to 1 while the data is loading. We do this by setting
                        the total number of items to the new page size.
                    */
                    totalItems: newPageSize
                };
                this.setPageSizeQueryParam();
                this.setCurrentPage(1);
                this.getPaginatedDeliverables();
            }
        },

        setPageSizeQueryParam() {
            const shouldSetQueryParam =
                (this.viewMode === ViewMode.Grid &&
                    this.pagination.pageSize !== ViewModeDefaultPageSize[ViewMode.Grid]) ||
                (this.viewMode === ViewMode.Table &&
                    this.pagination.pageSize !== ViewModeDefaultPageSize[ViewMode.Table]);
            this.urlManager.setURLParams(
                [
                    {
                        key: UrlFilterParams.PageSize,
                        value: shouldSetQueryParam ? [this.pagination.pageSize] : []
                    }
                ],
                this.mounted
            );
        },

        setSelectedDeliverableIdsFromFile(selectedDeliverableIdentifiers) {
            this.selectedDeliverableIds = []; //
            this.selectedDeliverableIdentifiers = selectedDeliverableIdentifiers;
            this.toggleDownloadModal();
        },

        setSelectedDeliverableIds(selectedDeliverableIds) {
            if (selectedDeliverableIds.length !== this.paginatedDeliverables.length) {
                this.areAllDeliverablesSelected = false;
            }

            if (this.hasMoreToSelect(selectedDeliverableIds)) {
                this.isPaginationNoticeShown = true;
            }

            if (!selectedDeliverableIds.length && this.isPaginationNoticeShown) {
                this.isPaginationNoticeShown = false;
            }

            this.selectedDeliverableIds = selectedDeliverableIds;
        },

        setViewMode(viewMode) {
            if (viewMode !== this.viewMode) {
                this.viewMode = viewMode;
                /*
                    The following logic was originally planned to be triggered by a
                    watcher, however that caused some complicated sequence and side effect
                    related issues where pageSize and currentPage were being set, overwriting
                    values that had been set from URL query params.

                    This also applies to setPageSize().
                */
                if (this.viewMode === ViewMode.Grid) {
                    this.setPageSize(ViewModeDefaultPageSize[ViewMode.Grid]);
                } else {
                    this.setPageSize(ViewModeDefaultPageSize[ViewMode.Table]);
                }
                this.setCurrentPage(1);
                this.setViewModeQueryParam();
                this.setSelectedDeliverableIds([]);
                this.getPaginatedDeliverables();
            }
        },

        setViewModeQueryParam() {
            this.urlManager.setURLParams(
                [
                    {
                        key: UrlFilterParams.ViewMode,
                        value: this.viewMode === ViewMode.Grid ? [] : [this.viewMode]
                    }
                ],
                this.mounted
            );
        },

        showBulkApproval() {
            if (this.allSelectedMode) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, null);
            } else {
                this.$store.dispatch(
                    DeliverableLibraryAction.SetDrawerDeliverables,
                    this.selectedDeliverableIds.map(idHash => this.deliverablesByIdHash[idHash])
                );
            }

            this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, true);
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerActiveTab, "qa");
        },

        toggleSharePreviewModal() {
            this.sharePreviewModalIsVisible = !this.sharePreviewModalIsVisible;
        },

        toggleDownloadModal() {
            this.downloadModalIsVisible = !this.downloadModalIsVisible;
            if (this.importExportModalVisible) {
                this.importExportModalVisible = false;
            }
        },

        toggleImportExportModalVisible() {
            this.importExportModalVisible = !this.importExportModalVisible;
            if (!this.importExportModalVisible) {
                this.selectedDeliverableIdentifiers = [];
            }
        },

        togglePublishModal(platformAction) {
            if (this.publishModalIsVisible) {
                this.publishModalData = {};
            } else {
                this.publishModalData = platformAction;
            }
            this.publishModalIsVisible = !this.publishModalIsVisible;
        },

        updateMetadataValueOnDeliverables(idHashes, updatedMetadataValue) {
            this.paginatedDeliverables = this.paginatedDeliverables.map(existingDeliverable => {
                if (idHashes.includes(existingDeliverable.idHash)) {
                    const updatedMetadataByFieldId = {};
                    // store a ref to each current metadataValue indexed by fieldId
                    existingDeliverable.metadata.forEach(metadata => {
                        updatedMetadataByFieldId[metadata.field._id] = metadata;
                    });
                    // if the value coming through is falsey then it is being removed
                    if (!updatedMetadataValue.value) {
                        delete updatedMetadataByFieldId[updatedMetadataValue.field._id];
                    } else {
                        // otherwsise overwrite/create the new value for the fieldId
                        updatedMetadataByFieldId[updatedMetadataValue.field._id] = updatedMetadataValue;
                    }

                    return {
                        ...existingDeliverable,
                        metadata: Object.values(updatedMetadataByFieldId)
                    };
                }
                return existingDeliverable;
            });
        },

        updateReportingLabelOnDeliverable(ev) {
            const { idHash, reportingLabel } = ev;
            this.paginatedDeliverables = this.paginatedDeliverables.map(existingDeliverable => {
                if (idHash === existingDeliverable.idHash) {
                    return {
                        ...existingDeliverable,
                        reportingLabel
                    };
                }
                return existingDeliverable;
            });
        }
    },

    apollo: {
        campaign: {
            query: campaignLibraryQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingCampaign",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            async result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingCampaign = true;
                    return;
                }
                this.hasErrorLoadingCampaign = false;
                this.$store.dispatch(CampaignAction.SetCampaign, data.campaign);
                this.$store.dispatch(CampaignAction.SetMasterTemplates, data.masterTemplatesByCampaign);
                this.$store.dispatch(CampaignAction.SetGroups, data.editableGroupsByCampaign);
                // make sure that the changes made in editor are reflected in filters
                this.$store.dispatch(DeliverableLibraryAction.SyncFilters);
            }
        },
        computedDeliverableComponents: {
            query: computedDeliverableComponentsQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingLibraryFilterOptions",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingFilterOptions = true;
                    return;
                }
                this.hasErrorLoadingFilterOptions = false;
                const { deliverableCount, editableGroupValues, languages, masterTemplates } =
                    data.computedDeliverableComponents;
                this.numberOfDeliverablesThatUserCanView = deliverableCount;
                this.setEditableGroupFilterOptions(editableGroupValues);
                this.$store.dispatch(DeliverableLibraryAction.SetLanguageFilterOptions, languages);
                this.setMasterTemplateFilterOptions(masterTemplates);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.campaign-library__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.campaign-library {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__body {
        flex: 1;
        overflow-y: auto;
        padding: $spacing-large 20px $spacing;
        position: relative;

        &--no-padding {
            padding: 0;
        }

        &--no-scroll {
            overflow-y: hidden;
        }
    }

    &__empty-message-wrapper {
        padding: $spacing-large 0 0;
    }

    &__loading-error-message-wrapper {
        padding: 0 $spacing-large;
    }

    &__footer {
        padding: $spacing 20px;
        border-top: 1px solid $grey3;

        &-spacer {
            flex: 1 1 100%;
            width: 100%;
        }

        &-item {
            flex: 0 0 auto;
            max-width: 100%;
            padding: 0 0 0 $spacing-smaller;
            width: auto;
        }

        &-items {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;
        }
    }

    .banner-scrubbar {
        flex: 1 0 100px;
        min-width: 100px;

        .ivu-slider-wrap {
            margin: 0 9px;
        }
    }

    .play-pause-all {
        flex: 0 0;
        margin-left: $spacing-large;
    }

    &__page-select {
        flex: 0 0;
        white-space: nowrap;
    }

    &__page-size-select-wrapper {
        flex: 0 0;
        padding: 0 0 0 $spacing;
    }

    &__scale-options-wrapper {
        align-items: center;
        display: flex;
        margin: 0 $spacing-small 0 0;
        white-space: nowrap;

        p {
            margin: 0 $spacing-smaller 0 0;
        }
    }

    &__select-all-notice-wrapper {
        border-bottom: 1px solid $grey3;
        padding: $spacing 20px 0;
    }

    &__variations {
        color: $cmp-light-active;
        font-weight: bold;
        height: $campaign-nav-height;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $grey3;
    }
}

.campaign-edit__deliverables-size-group {
    width: 100%;

    &--single-child {
        width: auto;
        display: inline-block;
    }
}
</style>
