<template>
    <div class="editable-groups">
        <template v-if="editableGroupIds.length">
            <editable-group
                v-for="groupName in editableGroupIdsWithVisibleEditables"
                :key="groupName"
                :group-name="groupName"
                :sandbox-mode="sandboxMode"
                v-on="listeners"
            />

            <div v-if="userIsSuperAdmin">
                <div
                    v-for="publisherPlatformGroupName in editableGroupsOfPublisherPlatform"
                    :key="publisherPlatformGroupName"
                >
                    <editable-group
                        v-for="groupName in getEditableGroupIdsForPublisherPlatform(publisherPlatformGroupName)"
                        :key="groupName"
                        :group-name="groupName"
                        :sandbox-mode="sandboxMode"
                        v-on="listeners"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import EditableGroup from "@/components/Campaign/EditableGroup";
import passThroughMixin from "@/mixins/passThroughMixin";
import { AuthGetters } from "@/store/modules/auth";

export default {
    name: "EditableGroupList",
    components: {
        EditableGroup
    },
    mixins: [passThroughMixin],

    props: {
        sandboxMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            checkAllGroup: []
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        editableGroupIds() {
            return [...this.$store.state.campaign.normalized.editableGroupIds];
        },

        userIsSuperAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        },

        editableGroupsOfPublisherPlatform() {
            const editableGroups = new Set();
            this.editableGroupIds.forEach(editableGroupId => {
                this.$store.state.campaign.normalized.editableGroups[editableGroupId].editables.forEach(editableId => {
                    const editableGroup = this.$store.state.campaign.normalized.editables[editableId];
                    if (editableGroup.publisherPlatform) {
                        editableGroups.add(editableGroup.publisherPlatform);
                    }
                });
            });

            return [...editableGroups];
        },

        editableGroupIdsWithVisibleEditables() {
            if (
                this.$auth.userCan(this.$auth.Actions.CanManageRestrictedEditables, {
                    campaignId: this.campaignId,
                    clientId: this.clientId
                })
            ) {
                const showEditableGroupByCondition = editable => !editable.publisherPlatform;
                return this.displayEditableGroup(showEditableGroupByCondition);
            }

            const showEditableGroupByCondition = editable => !editable.restricted && !editable.publisherPlatform;
            return this.displayEditableGroup(showEditableGroupByCondition);
        }
    },

    methods: {
        /**
         * Show editable groups that meet the condition passed as parameter
         * For reusability the function parameter allows a function
         * @param {Function(<editable>)} showEditableGroupByCondition
         */
        displayEditableGroup(showEditableGroupByCondition) {
            // filter all editable groups that is not related to any publisher platform
            return this.editableGroupIds.filter(editableGroupId => {
                return this.$store.state.campaign.normalized.editableGroups[editableGroupId].editables.some(
                    editableId => {
                        const editable = this.$store.state.campaign.normalized.editables[editableId];
                        return showEditableGroupByCondition(editable);
                    }
                );
            });
        },

        /**
         * Get the editable groups ids related to a specific publisher platform based on name
         * @param {String} platformName
         */
        getEditableGroupIdsForPublisherPlatform(platformName) {
            const showEditableGroupByCondition = editable => editable.publisherPlatform === platformName;
            return this.displayEditableGroup(showEditableGroupByCondition);
        }
    }
};
</script>
