<template>
    <div class="preview-controls">
        <div class="preview-controls__play-pause">
            <Button
                v-if="isPlaying"
                class="preview-controls__play-pause--button"
                shape="circle"
                :disabled="isBannerStatic"
                icon="ios-pause"
                @click="togglePlay"
            ></Button>
            <Button
                v-else
                class="preview-controls__play-pause--button-play"
                shape="circle"
                :disabled="isBannerStatic"
                icon="ios-play"
                @click="togglePlay"
            ></Button>
        </div>
        <div class="preview-controls__scrubbar">
            <div v-if="canShowAnnotations" class="preview-controls__scrubbarAnnotations">
                <annotation-scrubbar
                    :annotations="banner.templateSegmentInfo.annotations"
                    :duration="duration"
                ></annotation-scrubbar>
            </div>
            <div class="preview-controls__timeline">
                <Slider
                    class="timeline-slider"
                    :min="0"
                    :max="duration"
                    :step="0.01"
                    :value="value"
                    :disabled="isBannerStatic"
                    @on-input="onChange"
                ></Slider>
            </div>
        </div>
    </div>
</template>

<script>
import AnnotationScrubbar from "@/components/Qa/AnnotationScrubbar";

export default {
    name: "PreviewControls",
    components: { AnnotationScrubbar },
    props: {
        banner: {
            type: Object
        },
        value: {
            type: Number
        },
        duration: {
            type: Number,
            required: true
        },

        showAnnotations: {
            type: Boolean,
            default: true
        },

        isPlaying: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentTime: 0
        };
    },

    computed: {
        isBannerStatic() {
            return this.duration === 0;
        },

        canShowAnnotations() {
            return (
                this.showAnnotations &&
                this.banner &&
                this.banner.templateSegmentInfo &&
                this.banner.templateSegmentInfo.annotations
            );
        }
    },

    methods: {
        togglePlay() {
            if (this.isPlaying) {
                this.$emit("pause");
            } else {
                this.$emit("play");
            }
        },

        onChange(newValue) {
            this.$emit("change", newValue);
        }
    }
};
</script>

<style lang="scss">
.preview-controls {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;

    &__play-pause {
        flex: 0 0 28px;
        width: 28px;
        margin: 0 10px;
        display: flex;
        align-items: center;

        &--button-play {
            .ivu-icon {
                padding-left: 3px;
            }
        }
    }

    &__timeline {
        width: 100%;
    }

    &__scrubbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 0;
        margin-right: 10px;

        &Annotations {
            width: 100%;
        }
    }
}
</style>
