<template>
    <hox-modal v-if="isVisible" :wrapper-class="addAccountModalClass" @close="close">
        <template #header>
            {{ actionLabel }}
        </template>
        <div class="add-account-modal__wrap">
            <div class="add-account-modal__form">
                <component
                    :is="formComponent"
                    :form-data="formData"
                    :action-label="actionLabel"
                    @submitFormData="onSubmitFormData"
                    @changeAccountType="onChangeAccountType"
                />
            </div>
            <div v-if="isFacebookAccount" class="add-account-modal__info">
                <Alert banner type="warning">
                    <template #desc>
                        <strong>Facebook Account Id</strong>
                        ,
                        <strong>Page Id</strong>
                        and
                        <strong>Instagram Account Id</strong>
                        need be added as a partner to Hogarth's Business Account
                        <span class="link-type">
                            3704782469638109&nbsp;
                            <Icon type="md-copy"></Icon>
                        </span>
                        <br />
                        <br />
                        <strong>Origin Ad Set Id is</strong>
                        configured in your Facebook ad account and can be used to set audience targeting.
                    </template>
                </Alert>
            </div>
            <div v-if="isGoogleAccount" class="add-account-modal__info">
                <Alert banner type="warning">
                    <template #desc>
                        <strong>DC Cmapaign Profile ID</strong>
                        configured in your Google ad account and can be used to set audience targeting.
                    </template>
                </Alert>
            </div>
        </div>
    </hox-modal>
</template>
<script>
import { AccountTypesList } from "@/enums/activate";
import BasicAccountForm from "@/components/IntelligentDelivery/BasicAccountForm";
import FacebookAccountForm from "@/components/IntelligentDelivery/FacebookAccountForm";
import GoogleAccountForm from "@/components/IntelligentDelivery/GoogleAccountForm";

export default {
    name: "PlatformAccountFormModal",
    components: {
        BasicAccountForm,
        FacebookAccountForm,
        GoogleAccountForm
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        accountAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            AccountTypesList,
            currentAccountType: this.formData.accountType
        };
    },
    computed: {
        addAccountModalClass() {
            if (
                this.currentAccountType === this.AccountTypesList.Facebook.id ||
                this.currentAccountType === this.AccountTypesList.Google.id
            ) {
                return "add-account-modal--extended";
            }
            return "add-account-modal";
        },
        actionLabel() {
            if (this.accountAction === "edit") {
                return "Edit account";
            }
            return "Add account";
        },
        formComponent() {
            switch (this.currentAccountType) {
                case this.AccountTypesList.Facebook.id:
                    return "FacebookAccountForm";
                case this.AccountTypesList.Google.id:
                    return "GoogleAccountForm";
                default:
                    return "BasicAccountForm";
            }
        },
        isFacebookAccount() {
            return this.currentAccountType === this.AccountTypesList.Facebook.id;
        },
        isGoogleAccount() {
            return this.currentAccountType === this.AccountTypesList.Google.id;
        }
    },
    watch: {
        formData(data) {
            this.currentAccountType = data.accountType;
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },
        onChangeAccountType(type) {
            this.currentAccountType = type;
        },

        onSubmitFormData() {
            this.$snackbar.info(`Platform account ${this.accountAction === "add" ? "added" : "edited"}`);
            this.$emit("addPlatformAccount", {
                formData: this.formData,
                accountIndex: this.accountIndex,
                accountAction: this.accountAction
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.add-account-modal {
    &--extended {
        .hox-modal {
            width: 900px;
        }
        &__form {
            width: auto;
            padding-right: 10px;
        }
    }
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
    }

    &__info {
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
}
</style>
