export const AccountTypesList = {
    Google: {
        id: "GOOGLE_STUDIO",
        name: "Google Studio Feed",
        icon: "iconGoogleDoubleClick"
    },
    Facebook: {
        id: "FACEBOOK",
        name: "Facebook",
        icon: "iconFacebook"
    }
};
export const AccountStatus = {
    Deleted: "deleted",
    Active: "active"
};
export const RuleStatus = {
    Deleted: "deleted",
    Inactive: "inactive",
    Active: "active"
};
export const PublishStatus = {
    DONE: "Done",
    IN_PROGRESS: "In progress",
    DONE_WITH_ERRORS: "Done with errors"
};
export const weekDays = [
    {
        label: "Monday",
        value: 0
    },
    {
        label: "Tuesday",
        value: 1
    },
    {
        label: "Wednesday",
        value: 2
    },
    {
        label: "Thursday",
        value: 3
    },
    {
        label: "Friday",
        value: 4
    },
    {
        label: "Saturday",
        value: 5
    },
    {
        label: "Sunday",
        value: 6
    }
];
export const months = [
    {
        label: "January",
        value: 1
    },
    {
        label: "February",
        value: 2
    },
    {
        label: "March",
        value: 3
    },
    {
        label: "April",
        value: 4
    },
    {
        label: "May",
        value: 5
    },
    {
        label: "June",
        value: 6
    },
    {
        label: "July",
        value: 7
    },
    {
        label: "August",
        value: 8
    },
    {
        label: "September",
        value: 9
    },
    {
        label: "October",
        value: 10
    },
    {
        label: "November",
        value: 11
    },
    {
        label: "December",
        value: 12
    }
];
export const timezones = [
    { label: "none (UTC)", value: "" },
    { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
    { label: "(GMT-10:00) Hawaii-Aleutian", value: "America/Adak" },
    { label: "(GMT-10:00) Hawaii", value: "Etc/GMT+10" },
    { label: "(GMT-09:30) Marquesas Islands", value: "Pacific/Marquesas" },
    { label: "(GMT-09:00) Gambier Islands", value: "Pacific/Gambier" },
    { label: "(GMT-09:00) Alaska", value: "America/Anchorage" },
    { label: "(GMT-08:00) Tijuana, Baja California", value: "America/Ensenada" },
    { label: "(GMT-08:00) Pitcairn Islands", value: "Etc/GMT+8" },
    { label: "(GMT-08:00) Pacific Time (US & Canada)", value: "America/Los_Angeles" },
    { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "America/Denver" },
    { label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "America/Chihuahua" },
    { label: "(GMT-07:00) Arizona", value: "America/Dawson_Creek" },
    { label: "(GMT-06:00) Saskatchewan, Central America", value: "America/Belize" },
    { label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey", value: "America/Cancun" },
    { label: "(GMT-06:00) Easter Island", value: "Chile/EasterIsland" },
    { label: "(GMT-06:00) Central Time (US & Canada)", value: "America/Chicago" },
    { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "America/New_York" },
    { label: "(GMT-05:00) Cuba", value: "America/Havana" },
    { label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: "America/Bogota" },
    { label: "(GMT-04:30) Caracas", value: "America/Caracas" },
    { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
    { label: "(GMT-04:00) La Paz", value: "America/La_Paz" },
    { label: "(GMT-04:00) Falkland Islands", value: "Atlantic/Stanley" },
    { label: "(GMT-04:00) Brazil", value: "America/Campo_Grande" },
    { label: "(GMT-04:00) Atlantic Time (Goose Bay)", value: "America/Goose_Bay" },
    { label: "(GMT-04:00) Atlantic Time (Canada)", value: "America/Glace_Bay" },
    { label: "(GMT-03:30) Newfoundland", value: "America/St_Johns" },
    { label: "(GMT-03:00) UTC-3", value: "America/Araguaina" },
    { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
    { label: "(GMT-03:00) Miquelon, St. Pierre", value: "America/Miquelon" },
    { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
    { label: "(GMT-03:00) Buenos Aires", value: "America/Argentina/Buenos_Aires" },
    { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
    { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
    { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
    { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
    { label: "(GMT) Greenwich Mean Time : Belfast", value: "Europe/Belfast" },
    { label: "(GMT) Greenwich Mean Time : Dublin", value: "Europe/Dublin" },
    { label: "(GMT) Greenwich Mean Time : Lisbon", value: "Europe/Lisbon" },
    { label: "(GMT) Greenwich Mean Time : London", value: "Europe/London" },
    { label: "(GMT) Monrovia, Reykjavik", value: "Africa/Abidjan" },
    { label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", value: "Europe/Amsterdam" },
    { label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", value: "Europe/Belgrade" },
    { label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: "Europe/Brussels" },
    { label: "(GMT+01:00) West Central Africa", value: "Africa/Algiers" },
    { label: "(GMT+01:00) Windhoek", value: "Africa/Windhoek" },
    { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
    { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
    { label: "(GMT+02:00) Gaza", value: "Asia/Gaza" },
    { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Blantyre" },
    { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
    { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
    { label: "(GMT+02:00) Syria", value: "Asia/Damascus" },
    { label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: "Europe/Moscow" },
    { label: "(GMT+03:00) Nairobi", value: "Africa/Addis_Ababa" },
    { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
    { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Dubai" },
    { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
    { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
    { label: "(GMT+05:00) Ekaterinburg", value: "Asia/Yekaterinburg" },
    { label: "(GMT+05:00) Tashkent", value: "Asia/Tashkent" },
    { label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "Asia/Kolkata" },
    { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
    { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
    { label: "(GMT+06:00) Novosibirsk", value: "Asia/Novosibirsk" },
    { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
    { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
    { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
    { label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: "Asia/Hong_Kong" },
    { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
    { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
    { label: "(GMT+08:45) Eucla", value: "Australia/Eucla" },
    { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
    { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
    { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
    { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
    { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
    { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
    { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
    { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
    { label: "(GMT+10:30) Lord Howe Island", value: "Australia/Lord_Howe" },
    { label: "(GMT+11:00) Solomon Is., New Caledonia", value: "Etc/GMT-11" },
    { label: "(GMT+11:00) Magadan", value: "Asia/Magadan" },
    { label: "(GMT+11:30) Norfolk Island", value: "Pacific/Norfolk" },
    { label: "(GMT+12:00) Anadyr, Kamchatka", value: "Asia/Anadyr" },
    { label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
    { label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Etc/GMT-12" },
    { label: "(GMT+12:45) Chatham Islands", value: "Pacific/Chatham" },
    { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
    { label: "(GMT+14:00) Kiritimati", value: "Pacific/Kiritimati" }
];
