import addMasterTemplateMutation from "@/apollo/mutations/v2/AddMasterTemplate.gql";
import associateMasterTemplateMutation from "@/apollo/mutations/v2/AssociateMasterTemplateWithCampaign.gql";
import disassociateMasterTemplateMutation from "@/apollo/mutations/v2/UnassociateMasterTemplateFromCampaign.gql";
import removeMasterTemplateMutation from "@/apollo/mutations/v2/RemoveMasterTemplate.gql";

import clientMasterTemplatesQuery from "@/apollo/queries/v2/ClientMasterTemplates.gql";
import updateTemplateMutation from "@/apollo/mutations/v2/UpdateMasterTemplate.gql";
import updateResizeTemplateMutation from "@/apollo/mutations/v2/UpdateResizedMasterTemplate.gql";

export default class MasterTemplates {
    /**
     * Creates new instance of MasterTemplate
     * @param {VueApollo} apollo
     * @param {string} clientId
     * @param {ApolloQuery} refreshQuery
     */
    constructor(apollo, clientId, refreshQuery = null) {
        this.$apollo = apollo;
        this.refreshQuery = refreshQuery;

        this.defaults = {
            variables: {
                clientId
            }
        };

        if (refreshQuery) {
            this.defaults.refetchQueries = [this.refreshQuery];
        } else {
            this.defaults.refetchQueries = [
                {
                    query: clientMasterTemplatesQuery,
                    variables: {
                        clientId: this.defaults.variables.clientId
                    }
                }
            ];
        }
    }

    /**
     * Uploads new master template
     * @param zipUrl
     * @returns {Promise<Promise<FetchResult<any>> | * | never | void | Promise<any>>}
     */
    async add(zipUrl, type) {
        return this.$apollo.mutate({
            mutation: addMasterTemplateMutation,
            variables: {
                zipUrl,
                type,
                clientId: this.defaults.variables.clientId
            },
            refetchQueries: this.defaults.refetchQueries
        });
    }

    /**
     * Updates zip
     * @param masterTemplateId
     * @param zipUrl
     * @returns {Promise<void>}
     */
    async update(masterTemplateId, zipUrl, type, force = false) {
        return this.$apollo.mutate({
            mutation: updateTemplateMutation,
            variables: {
                masterTemplateId,
                zipUrl,
                clientId: this.defaults.variables.clientId,
                type,
                force
            },
            ...(force && {
                awaitRefetchQueries: true, // wait for the refetch before resolving the promise
                refetchQueries: this.defaults.refetchQueries
            })
        });
    }

    async updateResize(masterTemplateId, parentId) {
        return this.$apollo.mutate({
            mutation: updateResizeTemplateMutation,
            variables: {
                masterTemplateId,
                parentId
            }
        });
    }

    async delete(masterTemplateId) {
        return this.$apollo.mutate({
            mutation: removeMasterTemplateMutation,
            variables: {
                masterTemplateId
            },
            refetchQueries: this.defaults.refetchQueries
        });
    }

    async associate(masterTemplateId, campaignId) {
        return this.$apollo.mutate({
            mutation: associateMasterTemplateMutation,
            variables: {
                masterTemplateId,
                campaignId
            }
        });
    }

    async disassociate(masterTemplateId, campaignId) {
        return this.$apollo.mutate({
            mutation: disassociateMasterTemplateMutation,
            variables: {
                masterTemplateId,
                campaignId
            }
        });
    }
}
