<template>
    <div class="master-templates-table-wrapper">
        <hox-table ref="table" class="master-templates-table" :has-border="false" :is-loading="isLoading" is-scrollable>
            <template #header>
                <hox-table-header :is-sticky="!isIe">
                    <hox-table-header-column
                        v-if="isSelectable"
                        :rowspan="primaryHeaderRowspan"
                        :is-sticky-left="!isIe"
                        :width-pixels="checkboxColumnWidth"
                    />
                    <hox-table-header-column
                        v-for="column in headers.primary"
                        :key="column.key"
                        :colspan="column.colspan"
                        :has-strong-border-left="column.hasStrongBorderLeft"
                        :is-group-header="column.isGroupHeader"
                        :min-width-pixels="column.minWidthPixels"
                        :max-width-pixels="column.maxWidthPixels"
                        :rowspan="column.rowspan"
                    >
                        <span :title="column.title">{{ column.title }}</span>
                    </hox-table-header-column>
                    <hox-table-header-column
                        :rowspan="primaryHeaderRowspan"
                        :is-sticky-right="!isIe"
                        :width-pixels="actionItemsColumnWidth"
                    ></hox-table-header-column>
                </hox-table-header>
            </template>
            <template #body>
                <hox-table-row v-for="row in rows" :key="row.masterTemplateId">
                    <hox-table-row-column v-if="isSelectable" :is-sticky-left="!isIe" :sticky-offset-pixels="0">
                        <table-checkbox-cell
                            :is-selected="value.includes(row.masterTemplateId)"
                            @selectionChanged="toggleSelection(row.masterTemplateId)"
                        />
                    </hox-table-row-column>

                    <hox-table-row-column
                        v-for="rowColumn in row.columns"
                        :key="`${row.masterTemplateId}:${rowColumn.key}`"
                        :has-strong-border-left="rowColumn.hasStrongBorderLeft"
                        :max-width-pixels="rowColumn.maxWidthPixels"
                    >
                        {{ rowColumn.value }}
                    </hox-table-row-column>
                    <hox-table-row-column :is-sticky-right="!isIe" :width-pixels="actionItemsColumnWidth">
                        <table-action-items-cell @showPreview="showDetails([row.masterTemplateId])">
                            <template #tooltip>
                                <template v-if="canManageMasterTemplates">
                                    <Poptip
                                        :value="optionsVisible[row.masterTemplateId] || false"
                                        placement="bottom"
                                        class="hide-title"
                                        popper-class="campaign-edit__poptip campaign-edit__poptip--transferred"
                                        :transfer="true"
                                        @input="onOptionsVisibilityChange(row.masterTemplateId, $event)"
                                    >
                                        <icon type="ios-more" />
                                        <master-template-banner-options
                                            slot="content"
                                            :master-template="masterTemplates[row.masterTemplateId]"
                                            :is-psd="true"
                                            :is-resizable="false"
                                            @hide="onOptionsVisibilityChange(row.masterTemplateId, false)"
                                            @update="onUpdate(row.masterTemplateId)"
                                            @familyUpdated="onFamilyUpdate"
                                            @remove="deleteTemplatePrompt(row.masterTemplateId)"
                                        ></master-template-banner-options>
                                    </Poptip>
                                </template>
                            </template>
                        </table-action-items-cell>
                    </hox-table-row-column>
                </hox-table-row>
            </template>
            <template #emptyMessage>
                <slot name="emptyMessage" />
            </template>
        </hox-table>
    </div>
</template>

<script>
import { EditableType } from "@/enums/editables";

import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import { camelToUpperCase, isIe } from "@/utils";
import paginationMixin from "@/mixins/paginationMixin";
import TableCheckboxCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableCheckboxCell";
import TableActionItemsCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableActionItemsCell";
import MasterTemplateBannerOptions from "@/components/Campaign/MasterTemplates/MasterTemplateBannerOptions";

const ColumnType = {
    GroupValue: "groupValue",
    Metadata: "metadata",
    PublishedStatus: "publishedStatus",
    Tag: "tag"
};

export default {
    name: "MasterTemplateTable",
    components: {
        MasterTemplateBannerOptions,
        TableActionItemsCell,
        TableCheckboxCell
    },

    mixins: [paginationMixin],

    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },
        isLoadingDeliverables: {
            type: Boolean
        },

        items: {
            type: Array
        },

        value: {
            type: Array
        },

        isSelectable: {
            type: Boolean
        }
    },

    data() {
        return {
            actionItemsColumnWidth: 50,
            checkboxColumnWidth: 40,
            defaultColumnWidth: 203,
            isActionable: true,
            isIe: isIe(),
            isLoading: false,
            optionsVisible: {},
            visibleMetadataInput: {
                idHash: undefined,
                fieldId: undefined
            }
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        masterTemplateIds() {
            return this.$store.state.masterTemplateLibrary.masterTemplateIds;
        },

        masterTemplates() {
            return this.$store.state.masterTemplateLibrary.masterTemplates;
        },

        headerFields() {
            return ["name", "parentId", "width", "height", "modified"];
        },

        headers() {
            return this.headerFields.reduce(
                (headerColumns, key) => {
                    headerColumns.primary.push({
                        key,
                        maxWidthPixels: this.getColumnWidth(key),
                        rowspan: 2,
                        title: this.getColumnTitle(key)
                    });
                    return headerColumns;
                },
                {
                    primary: [],
                    secondary: []
                }
            );
        },

        primaryHeaderRowspan() {
            return this.headers.secondary.length > 0 ? 2 : 1;
        },

        rows() {
            return this.items.map(masterTemplateId => {
                return this.headerFields.reduce(
                    (row, key) => {
                        row.columns.push({
                            ...this.getCellData(masterTemplateId, key),
                            key,
                            maxWidthPixels: this.getColumnWidth(key)
                        });

                        return row;
                    },
                    {
                        columns: [],
                        masterTemplateId
                    }
                );
            });
        },

        visibleColumnKeys: {
            get() {
                return this.$store.state.deliverableLibrary.table.visibleColumnKeys;
            },
            set(visibleColumnKeys) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableVisibleColumnKeys, visibleColumnKeys);
            }
        }
    },

    created() {
        this.EditableType = EditableType;
        this.ColumnType = ColumnType;
    },

    methods: {
        deleteTemplatePrompt(masterTemplateId) {
            this.$Modal.confirm({
                title: "Are you sure?",
                content:
                    "This template may be used in other campaigns. All ads using this template will be deleted. This is permanent and can not be undone.",
                okText: "Delete",
                onOk: () => {
                    this.$emit("remove", masterTemplateId);
                },
                cancelText: "Cancel",
                width: 300
            });
        },

        filterSubKeys(subKeys) {
            if (!this.elementsFilter.length) {
                return subKeys;
            }

            return subKeys.filter(sub => this.elementsFilter.some(elFilter => sub.includes(elFilter)));
        },

        getCellData(masterTemplateId, key) {
            if (!masterTemplateId || !key) {
                return {};
            }

            const value = this.masterTemplates[masterTemplateId][key];

            if (key === "modified") {
                return {
                    value: new Date(value * 1000).toISOString().split("T")[0]
                };
            }

            if (key === "parentId") {
                return {
                    value: value ? "Yes" : "No"
                };
            }

            return {
                value
            };
        },

        getColumnTitle(key, prefix = "") {
            let title = key;
            if (prefix) {
                title = key.replace(`${prefix}:`, "");
            }

            if (key === "parentId") {
                return "Is resize";
            }

            return camelToUpperCase(title);
        },

        getColumnWidth() {
            return 120;
        },

        hideReportingLabelInputs() {
            this.visibleReportingLabelInput = null;
        },

        keyIsForMetadata() {
            return false;
        },

        reportingLabelInputIsVisible(idHash) {
            return idHash === this.visibleReportingLabelInput;
        },

        showDetails(reportingLabels) {
            this.$emit("creativesSelected", reportingLabels);
        },

        onFamilyUpdate(masterTemplateIds) {
            masterTemplateIds.forEach(masterTemplateId => this.$emit("update", masterTemplateId));
        },

        onOptionsVisibilityChange(masterTemplateId, value) {
            this.optionsVisible[masterTemplateId] = value;
        },

        toggleReportingLabelInputIsVisible(idHash) {
            if (this.reportingLabelInputIsVisible(idHash)) {
                this.hideReportingLabelInputs();
            } else {
                this.visibleReportingLabelInput = idHash;
            }
        },

        toggleSelection(value) {
            const filtered = this.value.filter(label => label !== value);

            if (filtered.length === this.value.length) {
                this.$emit("input", [...this.value, value]);
            } else {
                this.$emit("input", filtered);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.master-templates-table {
    height: calc(100% - 75px);
    flex: 1;
    margin-top: $spacing-large;
    padding: 0 $spacing-semi-large $spacing $spacing-semi-large;

    &-paging {
        flex: 0 0 30px;
        text-align: right;
        padding: 0 $spacing-small $spacing-smallest $spacing-small;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .reporting-deviation-arrow {
        display: inline-block;
    }

    &__empty-string {
        color: $grey5;
    }

    .table-header-column--group-header {
        font-weight: bold;
    }
}
</style>
